import {
  BlockAttributes,
  GuestyBlock,
  GuestyReservation,
  ReservationAttributes,
  ReservationResponse,
  ReservationsResponse,
} from "types/reservation.types";

import {
  ReservationFilters,
  SortDirection,
} from "components/@reservations/Reservations/types";

import RestApiService from "./";

export default {
  getReservations(
    this: RestApiService,
    pageNumber: number = 1,
    limit: number = 10,
    sortBy?: string,
    sortDirection?: SortDirection,
    filters?: ReservationFilters,
    search?: string,
  ) {
    return this.api.get<ReservationsResponse>("/v1/reservations", {
      params: {
        page_number: pageNumber,
        limit,
        sort: sortBy,
        order: sortDirection,
        filter: filters,
        search,
      },
    });
  },

  getReservation(
    this: RestApiService,
    listingId: string,
    reservationId: string,
  ) {
    return this.api.get<GuestyReservation>(
      `/v1/listings/${listingId}/reservations/${reservationId}`,
    );
  },

  getBlock(this: RestApiService, listingId: string, blockageId: string) {
    return this.api.get<GuestyBlock>(
      `/v1/listings/${listingId}/blockages/${blockageId}`,
    );
  },

  updateReservation(
    this: RestApiService,
    listingId: string,
    reservationId: string,
    attributes: ReservationAttributes,
  ) {
    return this.api.patch(
      `/v1/listings/${listingId}/reservations/${reservationId}`,
      { data: { type: "reservations", attributes } },
    );
  },

  updateBlock(
    this: RestApiService,
    listingId: string,
    blockageGuestyId: string,
    attributes: BlockAttributes,
  ) {
    return this.api.patch(
      `/v1/listings/${listingId}/blockages/${blockageGuestyId}`,
      {
        data: { type: "blockages", attributes },
      },
    );
  },

  requestCancelReservation(
    this: RestApiService,
    listingId: string,
    reservationId: string,
    note: string,
  ) {
    return this.api.put(
      `/v1/listings/${listingId}/reservations/${reservationId}/request-cancellation`,
      {
        data: {
          type: "reservations",
          attributes: {
            note,
          },
        },
      },
    );
  },

  deleteReservation(
    this: RestApiService,
    listingId: string,
    reservationId: string,
  ) {
    return this.api.delete(
      `/v1/listings/${listingId}/reservations/${reservationId}`,
    );
  },

  createReservation(
    this: RestApiService,
    listingId: string,
    attributes: ReservationAttributes,
  ) {
    return this.api.post<ReservationResponse>(
      `/v1/listings/${listingId}/reservations`,
      {
        data: {
          type: "reservations",
          attributes,
        },
      },
    );
  },
};
