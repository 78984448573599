import { IonIcon, IonRadioGroup, IonText } from "@ionic/react";
import { warning } from "ionicons/icons";
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

import { CardType, Option } from "../types";
import PropertyCard from "./PropertyCard";

type Props = Pick<UseControllerProps, "rules" | "name"> & {
  options: Array<Option>;
  type: CardType;
  helperText?: string;
  multiple?: boolean;
};

const FormRadioGroupCard = ({
  rules,
  name,
  options,
  helperText,
  type,
  multiple = false,
  ...rest
}: Props) => {
  const { control } = useFormContext();

  const getHelperText = (error?: FieldError) => {
    if (helperText && !error?.message) {
      return helperText;
    }
    if (error?.message) return error.message;
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({
        field: { onChange, value = multiple ? [] : "" },
        fieldState: { error },
      }) => (
        <div>
          <IonRadioGroup
            value={value}
            onIonChange={(e) => onChange(e.detail.value)}
            {...rest}
          >
            <div className="property-type-grid ion-no-padding">
              <div className="property-type-row">
                {options.map((option, i) => (
                  <PropertyCard
                    key={`${option.value}-${i}`}
                    option={option}
                    setSelection={() => {
                      if (multiple) {
                        const newValue = value.includes(option.value)
                          ? value.filter((val: unknown) => val !== option.value)
                          : [...value, option.value];
                        onChange(newValue);
                      } else {
                        onChange(option.value);
                      }
                    }}
                    type={type}
                  />
                ))}
              </div>
            </div>
          </IonRadioGroup>

          {getHelperText(error) && (
            <div className="helper-text-container">
              <IonIcon color="danger" icon={warning} />
              <IonText color="danger">
                <p data-testid="helper-text" className="helper-text margin-0">
                  {getHelperText(error)}
                </p>
              </IonText>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default FormRadioGroupCard;
