import "./BedTypes.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { addOutline } from "ionicons/icons";
import { useEffect, useRef } from "react";
import { useFieldArray } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { bedTypeKeys } from "constants/listing.constants";
import { BedType } from "types/listing.types";

import FormSelect from "components/@form/FormSelect";

interface Props {
  name: string;
  nestIndex: number;
}

const BedTypes = ({ name, nestIndex }: Props) => {
  const intl = useIntl();
  const { fields, append, remove } = useFieldArray({ name });
  const hasAppendedBeds = useRef(false);

  useEffect(() => {
    if (fields.length === 0 && !hasAppendedBeds.current) {
      append({});
    }
    hasAppendedBeds.current = true;
  }, [fields, append]);

  const addBedType = () => {
    append({});
  };

  const onClearItem = (index: number) => {
    remove(index);
  };

  return (
    <div className="bed-types-container">
      <div className="bed-types-grid">
        {fields.map((field, index) => (
          <div key={field.id} className="bed-type-select">
            <FormSelect
              isSearchable={false}
              name={`sleepingArrangements[${nestIndex}].bedTypes[${index}]`}
              options={Object.values(BedType).map((value) => ({
                label: intl.formatMessage({ id: bedTypeKeys[value] }),
                value,
              }))}
              isClearable
              onClearItem={() => onClearItem(index)}
              placeholder={intl.formatMessage({
                id: "select_bed_type.placeholder",
              })}
            />
          </div>
        ))}
        <div className="add-bed-type-container">
          <IonButton
            className="add-bed-type-button"
            shape="round"
            onClick={addBedType}
          >
            <IonIcon
              slot="start"
              className="add-bed-type-icon"
              icon={addOutline}
            ></IonIcon>
            <FormattedMessage id="add.bed_type" />
          </IonButton>
        </div>
      </div>
    </div>
  );
};

export default BedTypes;
