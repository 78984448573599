import { FormattedMessage, useIntl } from "react-intl";

export const useFormatAmenities = () => {
  const intl = useIntl();

  const formatAmenities = (amenities: Array<string>, prefix: I18nKey) => {
    if (amenities.length === 0) return "";
    const firstAmenity = intl.formatMessage({ id: amenities[0] as I18nKey });
    const remainingCount = amenities.length - 1;
    const suffix =
      remainingCount > 0 ? `${firstAmenity} + ${remainingCount}` : firstAmenity;

    return (
      <p className="margin-0 extra-small">
        <FormattedMessage id={prefix} values={{ suffix: <b>{suffix}</b> }} />
      </p>
    );
  };

  return formatAmenities;
};
