import { useMutation } from "@tanstack/react-query";
import { resetPassword, ResetPasswordInput } from "aws-amplify/auth";

import { getTopLevelDomainFromCurrentLocation } from "utils/location.utils";

import { useIntlStore } from "store";

export const useForgotPassword = () => {
  const locale = useIntlStore((state) => state.locale);
  const {
    mutate: forgotPassword,
    isPending,
    error,
  } = useMutation({
    mutationFn: ({ username }: ResetPasswordInput) =>
      resetPassword({
        username,
        options: {
          clientMetadata: {
            locale,
            tld: getTopLevelDomainFromCurrentLocation(),
          },
        },
      }),
  });

  return {
    forgotPassword,
    isLoading: isPending,
    error,
  };
};
