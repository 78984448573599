import { createStore, useStore } from "zustand";

import config from "config";

import { Middlewares, middlewares } from "./middleware";

interface IntlState {
  locale: string;
  setLocale: (locale: string) => void;
}
export const intlStore = createStore<IntlState, Middlewares<IntlState>>(
  middlewares(
    (set) => ({
      locale: config.DEFAULT_LOCALE,
      setLocale: (locale) => set({ locale }),
    }),
    "intlStore",
  ),
);
export const useIntlStore = <T>(selector: (state: IntlState) => T) =>
  useStore(intlStore, selector);
