import "./RootBoundary.scss";

import * as Sentry from "@sentry/react";
import React from "react";

import useCopyToClipboard from "hooks/useCopyToClipboard";

interface Props {
  error: Error;
  componentStack: string | null;
  eventId: string | null;
  fullscreen?: boolean;
  resetError(): void;
}

const RootBoundary = ({ children }: { children: React.ReactNode }) => (
  <Sentry.ErrorBoundary
    beforeCapture={(scope) => scope.setTag("boundary", "root")}
    fallback={(props) => <Fallback {...props} error={props.error as Error} />}
  >
    {children}
  </Sentry.ErrorBoundary>
);

const Fallback = ({ error, componentStack }: Props) => {
  const message = error?.message;

  const { copy, isCopied } = useCopyToClipboard();

  const handleRefresh = () => {
    window.location.reload();
  };

  const handleCopy = () => {
    copy(`${message}\n${componentStack}`);
  };

  return (
    <div className="error-container">
      <div className="messageContainer">
        <div>
          <h1 className="title">Something went wrong on our end</h1>
          <p className="description">
            Help us out by sending the error message to our IT team and
            elaborate what you were doing so we can avoid these errors in the
            future.
          </p>
        </div>
        <div className="buttonsContainer">
          <button onClick={handleRefresh} className="button buttonSecondary">
            <p className="buttonText">Refresh the page</p>
          </button>

          <button onClick={handleCopy} className="button buttonSecondary">
            <p className="buttonText">
              {isCopied ? "Copied" : "Copy error message to clipboard"}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RootBoundary;
