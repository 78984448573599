import "./ChartLegend.scss";

import { IonIcon } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { formatCurrency } from "../chart.utils";

interface Props {
  revenueYtd?: number;
  revenueUpcomingBookings?: number;
  revenueTotal?: number;
  year: number;
}

const ChartLegend = ({
  revenueTotal,
  revenueUpcomingBookings,
  revenueYtd,
  year,
}: Props) => {
  const placeholderValue = "€ 2300";

  return (
    <div className="chart-legend">
      <div className="legend-item pink">
        <div className="title-wrapper">
          <IonIcon color="secondary" src="assets/icons/icon-ellipse.svg" />
          <p className="legend-item-title">
            <FormattedMessage id="chart.legend.revenue_ytd" />
          </p>
        </div>
        <p
          className={`legend-item-amount ${revenueYtd === undefined ? "placeholder-blur" : ""}`}
        >
          {revenueYtd !== undefined
            ? formatCurrency(revenueYtd)
            : placeholderValue}
        </p>

        <IonIcon className="add-icon" src="assets/icons/icon-legend-add.svg" />
      </div>

      <div className="legend-item middle">
        <div className="title-wrapper">
          <IonIcon
            className="ellipse-icon-middle"
            src="assets/icons/icon-ellipse.svg"
          />
          <p className="legend-item-title">
            <FormattedMessage id="chart.legend.upcoming_bookings" />
          </p>
        </div>
        <p
          className={`legend-item-amount ${revenueUpcomingBookings === undefined ? "placeholder-blur" : ""}`}
        >
          {revenueUpcomingBookings !== undefined
            ? formatCurrency(revenueUpcomingBookings)
            : placeholderValue}
        </p>

        <IonIcon
          className="equals-icon"
          src="assets/icons/icon-legend-equals.svg"
        />
      </div>

      <div className="legend-item end">
        <div className="title-wrapper">
          <p className="legend-item-title">
            <FormattedMessage
              id="chart.legend.expected_revenue"
              values={{ year }}
            />
          </p>
        </div>
        <p
          className={`legend-item-amount ${revenueTotal === undefined ? "placeholder-blur" : ""}`}
        >
          {revenueTotal !== undefined
            ? formatCurrency(revenueTotal)
            : placeholderValue}
        </p>
      </div>
    </div>
  );
};

export default ChartLegend;
