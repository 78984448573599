import "./FileSelector.scss";

import { IonIcon } from "@ionic/react";
import { addOutline, cameraOutline } from "ionicons/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { Context } from "types/upload.types";

import { DOCUMENTS_ALLOWED_TYPES } from "components/@complete-listing/Compliance/constants";

import { FILES_MAX_SIZE_MB, PHOTOS_ALLOWED_TYPES } from "./constants";
import FileUpload from "./FileUpload";
import UploadErrorCards from "./UploadErrorCards";

interface Props {
  listingId: string;
  context: Context;
  fileUrls: Array<string>;
  type: "documents" | "photos";
  maxFiles?: number;
  setIsUploading: (isUploading: boolean) => void;
  updateFileUrls: (urls: Array<string>) => void;
}

type InternalFileValue = Array<{
  fileUrl: string;
  file?: File;
  uploaded: boolean;
  uuid: string;
}>;

const fileUrlsToInternalState = (fileUrls: Array<string>): InternalFileValue =>
  fileUrls.map((fileUrl) => ({
    fileUrl,
    uploaded: true,
    uuid: uuidv4(),
  }));

const FileSelector = ({
  listingId,
  context,
  fileUrls,
  type,
  maxFiles,
  setIsUploading,
  updateFileUrls,
}: Props) => {
  const [addedFiles, setAddedFiles] = useState<InternalFileValue>(
    fileUrlsToInternalState(fileUrls),
  );
  const addedFilesRef = useRef(addedFiles);
  const [isDragOver, setIsDragOver] = useState(false);
  const [lengthError, setLengthError] = useState(false);
  const [typeError, setTypeError] = useState("");
  const [sizeError, setSizeError] = useState("");
  const id = useRef("id" + uuidv4());

  useEffect(() => {
    setIsUploading(addedFiles.some(({ uploaded }) => !uploaded));
  }, [setIsUploading, addedFiles]);

  useEffect(() => {
    if (
      fileUrls.every((url) => addedFiles.some((file) => file.fileUrl === url))
    ) {
      // don't do anything if already up to date
      return;
    }

    const files = fileUrlsToInternalState(fileUrls);
    setAddedFiles(files);
    addedFilesRef.current = files;
  }, [addedFiles, fileUrls]);

  const isPhotos = type === "photos";

  const handleFileChange = (files: FileList | null) => {
    setTypeError("");
    setSizeError("");
    setLengthError(false);

    if (files) {
      const newFiles = Array.from(files).filter((file) => {
        const isValidType = (
          isPhotos ? PHOTOS_ALLOWED_TYPES : DOCUMENTS_ALLOWED_TYPES
        ).includes(file.type);
        const isValidSize = file.size / (1024 * 1024) <= FILES_MAX_SIZE_MB;

        if (!isValidType) {
          setTypeError(file.name);
        }

        if (!isValidSize) {
          setSizeError(file.name);
        }

        return isValidType && isValidSize;
      });

      if (maxFiles && addedFiles.length + newFiles.length > maxFiles) {
        setLengthError(true);
      } else {
        const files = [
          ...addedFiles,
          ...newFiles.map((file) => {
            const blob = file.slice(0, file.size, "image/png");
            // Image will not load correctly if there are multiple files with the same name
            const fileName = file.name.startsWith("image")
              ? "image" +
                Math.random().toString(36).substring(7) +
                "." +
                file.name.split(".").pop()
              : file.name;
            const fileToUpload = new File([blob], fileName, {
              type: file.type,
            });
            return {
              fileUrl: URL.createObjectURL(file),
              file: fileToUpload,
              uploaded: false,
              uuid: uuidv4(),
            };
          }),
        ];
        setAddedFiles(files);
        addedFilesRef.current = files;
      }
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragOver(false);
    handleFileChange(event.dataTransfer.files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleUpdateFileUrls = useCallback(
    (files: InternalFileValue) => {
      setAddedFiles(files);
      addedFilesRef.current = files;
      updateFileUrls(
        files.filter(({ uploaded }) => uploaded).map(({ fileUrl }) => fileUrl),
      );
    },
    [updateFileUrls],
  );

  const handleDeleteFile = useCallback(
    (index: number) => {
      handleUpdateFileUrls(addedFilesRef.current.filter((_, i) => i !== index));
    },
    [handleUpdateFileUrls],
  );

  const handleUploadFinish = useCallback(
    (fileUrl: string, index: number) => {
      handleUpdateFileUrls(
        addedFilesRef.current.map((file, i) =>
          i === index ? { ...file, fileUrl, uploaded: true } : file,
        ),
      );
    },
    [handleUpdateFileUrls],
  );

  const canUploadMoreFiles = !maxFiles || addedFiles.length < maxFiles;

  const accept = (
    isPhotos ? PHOTOS_ALLOWED_TYPES : DOCUMENTS_ALLOWED_TYPES
  ).join(",");

  return (
    <>
      <div
        className="photo-grid"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        {addedFiles.map(({ file, fileUrl, uuid }, index) => (
          <FileUpload
            key={`file-upload-${uuid}`}
            file={file}
            fileUrl={fileUrl}
            deleteFile={() => handleDeleteFile(index)}
            context={context}
            listingId={listingId}
            onFinish={(fileUrl) => handleUploadFinish(fileUrl, index)}
          />
        ))}

        {addedFiles.length === 0 && (
          <div
            className={
              isDragOver
                ? "photo-item full-width add-photo-drag-over"
                : "photo-item full-width"
            }
          >
            <input
              type="file"
              onChange={(e) => handleFileChange(e.target.files)}
              multiple
              accept={accept}
              style={{ display: "none" }}
              id={id.current}
            />
            <label htmlFor={id.current} className="add-photo-label full-width">
              <IonIcon className="file-input-icon" icon={cameraOutline} />
              <h6>
                <FormattedMessage
                  id={
                    isPhotos
                      ? "complete_listing_photos.add_photos"
                      : "complete_listing_compliance.files.add_documents"
                  }
                />
              </h6>
              <p>
                <FormattedMessage
                  id={
                    isPhotos
                      ? "complete_listing_photos.drag_or_select_photos"
                      : "complete_listing_compliance.files.drag_or_select"
                  }
                  values={{
                    a: (chunks) => <a className="link-underline">{chunks}</a>,
                  }}
                />
              </p>
              <p className="extra-small opacity">
                <FormattedMessage
                  id={
                    isPhotos
                      ? "complete_listing_photos.file_types"
                      : "complete_listing_compliance.files.file_types"
                  }
                  values={{ size: FILES_MAX_SIZE_MB }}
                />
              </p>
            </label>
          </div>
        )}

        {addedFiles.length > 0 && canUploadMoreFiles && (
          <div
            className={
              isDragOver
                ? "photo-item add-photo-drag-over"
                : "photo-item add-photo"
            }
          >
            <input
              type="file"
              onChange={(e) => handleFileChange(e.target.files)}
              multiple
              accept={accept}
              style={{ display: "none" }}
              id={id.current}
            />

            <label htmlFor={id.current} className="add-photo-label">
              <IonIcon icon={addOutline} />

              <p className="extra-small">
                <FormattedMessage id="complete_listing_photos.add_more" />
              </p>
            </label>
          </div>
        )}
      </div>

      <UploadErrorCards
        typeError={typeError}
        sizeError={sizeError}
        lengthError={lengthError}
        maxFiles={maxFiles}
      />
    </>
  );
};

export default FileSelector;
