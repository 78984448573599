import { CalendarListing } from "types/calendar.types";

import RestApiService from ".";

export default {
  getCalendarListing(
    this: RestApiService,
    id: string,
    startDate?: string,
    endDate?: string,
  ) {
    return this.api.get<{ data: CalendarListing }>(
      `/v1/listings/${id}/reservations`,
      { params: { startDate, endDate, "filter[status]": "confirmed" } },
    );
  },
};
