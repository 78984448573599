import { queryOptions } from "@tanstack/react-query";

import { apiService } from "services";

export const DOCUMENT_PREFIX = "document";

export const termsAndConditionsQuery = (locale: string) =>
  queryOptions({
    queryKey: [DOCUMENT_PREFIX, "termsAndConditions", locale],
    queryFn: () => apiService.getTermsAndConditionsPage(locale),
  });

export const privacyPolicyQuery = (locale: string) =>
  queryOptions({
    queryKey: [DOCUMENT_PREFIX, "privacyPolicy", locale],
    queryFn: () => apiService.getPrivacyPolicyPage(locale),
  });

export const requirementsQuery = (locale: string) =>
  queryOptions({
    queryKey: [DOCUMENT_PREFIX, "requirements", locale],
    queryFn: () => apiService.getRequirementsPage(locale),
  });

export const graphInformationQuery = (locale: string) =>
  queryOptions({
    queryKey: [DOCUMENT_PREFIX, "graphInformation", locale],
    queryFn: () => apiService.getGraphInformationPage(locale),
  });
