import { IonButton, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import {
  Availabilities,
  DefaultSchema,
  ListingStatus,
  ReviewListingProviderValues,
} from "types/listing.types";

import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";
import AvailabilityForm from "components/@review-listing/Availability/AvailabilityForm";

import { ReviewListingAdvanceNotice } from "../types";
import { useValidateAvailabilities } from "./utils";

const Availability = () => {
  const router = useIonRouter();
  const { updateListing, error, isLoading } = useUpdateListing();
  const listingId = useWatch({ name: "listingId" });
  const [dateError, setDateError] = useState("");

  const { getValues } = useFormContext<ReviewListingProviderValues>();

  const validateAvailabilities = useValidateAvailabilities();

  const onSubmit = ({ availabilities }: { availabilities: Availabilities }) => {
    const dateValues = getValues("availabilities.customSchema");
    const { defaultSchema, bookingWindow, advanceNotice, customSchema } =
      availabilities;

    if (defaultSchema === DefaultSchema.Custom) {
      const { error, isValid } = validateAvailabilities(dateValues);
      if (!isValid) {
        setDateError(error);
        return;
      }
    }
    setDateError("");

    updateListing(
      {
        id: listingId,
        attributes: {
          availabilities: {
            defaultSchema,
            bookingWindow:
              defaultSchema === DefaultSchema.Always ? bookingWindow : null,
            advanceNotice:
              advanceNotice === ReviewListingAdvanceNotice.None
                ? null
                : advanceNotice,
            customSchema:
              defaultSchema === DefaultSchema.Custom ? customSchema : [],
          },
          status: ListingStatus.Active,
        },
      },
      {
        onSuccess: () => {
          router.push(
            generatePath(Routes.ReviewListingSuccess, {
              listingId,
            }),
          );
        },
        onError: (error) => {
          captureMessage("Error updating availabilities in listing review", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.ReviewListingShowcase, { listingId }),
      "back",
    );
  };

  return (
    <StepperContainer
      flow={Flow.ReviewListing}
      route={Routes.ReviewListingAvailability}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form-availability"
          isLoading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <div className="review-availability">
        <p className="review-caption">
          <FormattedMessage id="review_listing.title_caption" />
        </p>

        <h2 className="review-availability-title">
          <FormattedMessage id="review_listing_availability.title" />
        </h2>

        <p className="margin-0">
          <FormattedMessage id="review_listing_availability.description" />
        </p>

        <AvailabilityForm
          error={error}
          dateError={dateError}
          onSubmit={onSubmit}
        />
      </div>
    </StepperContainer>
  );
};

export default Availability;
