import "./SocialLoginOptions.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { signInWithRedirect } from "aws-amplify/auth";
import { logoApple, logoFacebook } from "ionicons/icons";

import { isIos, isMacintosh } from "utils/capacitor.utils";

import { LoginProvider } from "services/auth/types";

const googleSrc =
  "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxZW0iIGhlaWdodD0iMWVtIiB2aWV3Qm94PSIwIDAgNDggNDgiPjxwYXRoIGZpbGw9IiNmZmMxMDciIGQ9Ik00My42MTEgMjAuMDgzSDQyVjIwSDI0djhoMTEuMzAzYy0xLjY0OSA0LjY1Ny02LjA4IDgtMTEuMzAzIDhjLTYuNjI3IDAtMTItNS4zNzMtMTItMTJzNS4zNzMtMTIgMTItMTJjMy4wNTkgMCA1Ljg0MiAxLjE1NCA3Ljk2MSAzLjAzOWw1LjY1Ny01LjY1N0MzNC4wNDYgNi4wNTMgMjkuMjY4IDQgMjQgNEMxMi45NTUgNCA0IDEyLjk1NSA0IDI0czguOTU1IDIwIDIwIDIwczIwLTguOTU1IDIwLTIwYzAtMS4zNDEtLjEzOC0yLjY1LS4zODktMy45MTciLz48cGF0aCBmaWxsPSIjZmYzZDAwIiBkPSJtNi4zMDYgMTQuNjkxbDYuNTcxIDQuODE5QzE0LjY1NSAxNS4xMDggMTguOTYxIDEyIDI0IDEyYzMuMDU5IDAgNS44NDIgMS4xNTQgNy45NjEgMy4wMzlsNS42NTctNS42NTdDMzQuMDQ2IDYuMDUzIDI5LjI2OCA0IDI0IDRDMTYuMzE4IDQgOS42NTYgOC4zMzcgNi4zMDYgMTQuNjkxIi8+PHBhdGggZmlsbD0iIzRjYWY1MCIgZD0iTTI0IDQ0YzUuMTY2IDAgOS44Ni0xLjk3NyAxMy40MDktNS4xOTJsLTYuMTktNS4yMzhBMTEuOTEgMTEuOTEgMCAwIDEgMjQgMzZjLTUuMjAyIDAtOS42MTktMy4zMTctMTEuMjgzLTcuOTQ2bC02LjUyMiA1LjAyNUM5LjUwNSAzOS41NTYgMTYuMjI3IDQ0IDI0IDQ0Ii8+PHBhdGggZmlsbD0iIzE5NzZkMiIgZD0iTTQzLjYxMSAyMC4wODNINDJWMjBIMjR2OGgxMS4zMDNhMTIuMDQgMTIuMDQgMCAwIDEtNC4wODcgNS41NzFsLjAwMy0uMDAybDYuMTkgNS4yMzhDMzYuOTcxIDM5LjIwNSA0NCAzNCA0NCAyNGMwLTEuMzQxLS4xMzgtMi42NS0uMzg5LTMuOTE3Ii8+PC9zdmc+";

const SocialLoginOptions = () => {
  return (
    <div className="option-container">
      {(isIos || isMacintosh) && (
        <IonButton
          data-testid="apple-login"
          className="circle-button"
          shape="round"
          onClick={() =>
            signInWithRedirect({
              provider: LoginProvider.Apple,
              customState: JSON.stringify({
                provider: LoginProvider.Apple,
              }),
            })
          }
        >
          <IonIcon className="apple-logo" size="large" icon={logoApple} />
        </IonButton>
      )}
      <IonButton
        data-testid="google-login"
        className="circle-button"
        shape="round"
        onClick={() =>
          signInWithRedirect({
            provider: LoginProvider.Google,
            customState: JSON.stringify({
              provider: LoginProvider.Google,
            }),
          })
        }
      >
        <IonIcon size="large" src={googleSrc} />
      </IonButton>
      <IonButton
        data-testid="facebook-login"
        shape="round"
        className="circle-button-facebook"
        onClick={() =>
          signInWithRedirect({
            provider: LoginProvider.Facebook,
            customState: JSON.stringify({
              provider: LoginProvider.Facebook,
            }),
          })
        }
      >
        <IonIcon className="facebook-logo" size="large" icon={logoFacebook} />
      </IonButton>
    </div>
  );
};

export default SocialLoginOptions;
