import { ExternalLink, RouteLink } from "types/menu.types";

import { Routes } from "./routes.constants";

export const supportLinksMap: Array<ExternalLink> = [
  {
    title: "mobile_menu_support.tickets",
    icon: "assets/icons/icon-receipt-check.svg",
    isExternal: true,
    href: "https://support.huswell.com/portal",
  },
  {
    title: "mobile_menu_support.chat",
    icon: "assets/icons/icon-chat.svg",
    isExternal: true,
    href: "https://support.huswell.com/host/kb-tickets/new",
  },
  {
    title: "mobile_menu_support.knowledge_base",
    icon: "assets/icons/icon-support.svg",
    isExternal: true,
    href: "https://support.huswell.com/host",
  },
  {
    title: "mobile_menu_support.huswell_status",
    icon: "assets/icons/icon-status.svg",
    isExternal: true,
    href: "https://status.huswell.com",
  },
];

export const accountLinksMap: Array<RouteLink> = [
  // @TODO profile
  /*   {
    title: "mobile_menu_account.profile",
    icon: "assets/icons/icon-user.svg",
    isExternal: false,
    route: Routes.Account,
  }, */
  {
    title: "mobile_menu_account.preferences",
    icon: "assets/icons/icon-settings.svg",
    isExternal: false,
    route: Routes.Preferences,
  },
];
