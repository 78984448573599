import { useMutation } from "@tanstack/react-query";
import {
  sendUserAttributeVerificationCode,
  VerifiableUserAttributeKey,
} from "aws-amplify/auth";

import { getTopLevelDomainFromCurrentLocation } from "utils/location.utils";

export const useVerifyEmail = () => {
  const { isPending, isPaused, mutate, error } = useMutation({
    mutationFn: (userAttributeKey: VerifiableUserAttributeKey) =>
      sendUserAttributeVerificationCode({
        userAttributeKey,
        options: {
          clientMetadata: {
            tld: getTopLevelDomainFromCurrentLocation(),
          },
        },
      }),
  });

  return {
    verifyEmail: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
