import "./IOSSafeArea.scss";

import { IonHeader, IonToolbar } from "@ionic/react";

import { isIos } from "utils/capacitor.utils";

const IOSSafeArea = () => {
  if (!isIos) return null;
  return (
    <IonHeader class="ios-safe-area">
      <IonToolbar />
    </IonHeader>
  );
};

export default IOSSafeArea;
