import { useQuery } from "@tanstack/react-query";

import { getReservationQuery } from "./queries";

export const useReservation = (
  listingId: string,
  reservationId: string,
  options: Partial<typeof getReservationQuery>,
) => {
  const { data, isFetching, isLoading, error, isError } = useQuery({
    ...getReservationQuery(listingId, reservationId),
    ...options,
  });
  return {
    data: data?.data.data,
    error,
    isFetching,
    isLoading,
    isError,
  };
};
