import { useQuery } from "@tanstack/react-query";

import { StaleTime } from "queries/types";

import { getBlockQuery } from "./queries";

export const useBlock = (
  listingId: string,
  blockageId: string,
  options: Partial<typeof getBlockQuery>,
) => {
  const { data, isFetching, isLoading, error, isError } = useQuery({
    ...getBlockQuery(listingId, blockageId),
    ...options,
    meta: { localStorage: true }, // save to localStorage so optimistic updates aren't overwritten, guesty update takes a couple of minutes
    gcTime: StaleTime.FIVE_MIN,
    staleTime: StaleTime.FIVE_MIN,
  });
  return {
    data: data?.data.data,
    error,
    isFetching,
    isLoading,
    isError,
  };
};
