import config from "config";

import { ScraperListingPayload } from "types/scraper.types";

import RestApiService from ".";

export default {
  postListingScraper(this: RestApiService, payload: ScraperListingPayload) {
    return this.api.post(`${config.API_BASE}/v1/scraper/listing`, payload, {
      headers: {
        "x-api-key": config.SCRAPER_X_API_KEY,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  },
};
