import "./BookedByItem.scss";

import { IonIcon } from "@ionic/react";

interface Props {
  name: string;
  verified: boolean;
  isOwnerReservation: boolean;
}

const BookedByItem = ({ name, verified, isOwnerReservation }: Props) => {
  return (
    <div className="booked-by-item">
      <IonIcon
        className="ellipse-icon"
        color={isOwnerReservation ? "secondary" : "success"}
        src="assets/icons/icon-ellipse.svg"
      />
      <p className="margin-0 bold"> {name}</p>
      {verified && (
        <IonIcon
          className="verified-icon"
          src="assets/icons/icon-verified.svg"
        />
      )}
    </div>
  );
};

export default BookedByItem;
