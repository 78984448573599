import { useEffect, useState } from "react";

import useIdToken from "queries/authentication/useIdToken";

export const useIsEmailVerified = () => {
  const idToken = useIdToken();
  const [emailVerified, setEmailVerified] = useState<boolean | null>(null);

  useEffect(() => {
    if (idToken) {
      const verificationStatus = idToken.payload.email_verified as boolean;
      setEmailVerified(verificationStatus);
    } else {
      setEmailVerified(null);
    }
  }, [idToken]);

  return emailVerified;
};

export default useIsEmailVerified;
