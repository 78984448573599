import { IonIcon, IonText } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { useListing } from "queries";

import { getListingAddress } from "components/@listing-detail/utils";

type Props = {
  id: string;
  nickname: string;
  isActive: boolean;
  onClick: () => void;
};

const CalendarSidebarItem = ({ id, nickname, isActive, onClick }: Props) => {
  const { data: listing } = useListing(id);

  return (
    <div
      style={{
        cursor: "pointer",
        display: "flex",
        background: "white",
        padding: 16,
        borderRadius: 8,
        border: "1px solid",
        borderColor: isActive ? "var(--ion-color-primary)" : "transparent",
        flexDirection: "column",
        boxShadow:
          "0px 0px 1px 0px rgba(36, 18, 97, 0.24), 0px 1px 3px 0px rgba(36, 18, 97, 0.12)",
      }}
      onClick={onClick}
    >
      <p
        className="margin-0"
        style={{
          fontSize: 13,
          fontWeight: 500,
          lineHeight: "100%",
          color: isActive ? "" : "var(--ion-color-primary)",
        }}
      >
        {nickname}
      </p>

      {listing ? (
        <>
          <IonText color={isActive ? "primary" : ""}>
            <h6 style={{ fontSize: 16 }}>{listing.attributes.title}</h6>
          </IonText>
          <p className="margin-0">{getListingAddress(listing.attributes)}</p>
          {!!listing.attributes.minimumNights && (
            <p
              className="margin-0"
              style={{
                marginTop: 8,
                color: "var(--ion-text-color-step-600)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <IonIcon
                style={{ fontSize: 16, marginBottom: 3 }}
                icon="assets/icons/icon-moon.svg"
              />
              <FormattedMessage
                id="calendar.amount_of_nights.minimum"
                values={{ count: listing.attributes.minimumNights }}
              />
            </p>
          )}
        </>
      ) : (
        <>...</>
      )}
    </div>
  );
};

export default CalendarSidebarItem;
