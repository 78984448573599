import { useMutation } from "@tanstack/react-query";
import {
  confirmResetPassword,
  ConfirmResetPasswordInput,
} from "aws-amplify/auth";

import { getTopLevelDomainFromCurrentLocation } from "utils/location.utils";

import { useIntlStore } from "store";

export const useResetPassword = () => {
  const locale = useIntlStore((state) => state.locale);
  const {
    mutate: resetSubmitMutation,
    isPending,
    error,
  } = useMutation({
    mutationFn: ({
      username,
      confirmationCode,
      newPassword,
    }: ConfirmResetPasswordInput) =>
      confirmResetPassword({
        username,
        confirmationCode,
        newPassword,
        options: {
          clientMetadata: {
            locale,
            tld: getTopLevelDomainFromCurrentLocation(),
          },
          autoSignIn: true,
        },
      }),
  });

  return {
    resetSubmitMutation,
    isLoading: isPending,
    error,
  };
};
