import { CognitoErrorCode } from "services/auth/types";

export const getCognitoErrorMessageKey = (errorName?: string): I18nKey => {
  if (!errorName || !Object.keys(CognitoErrorCode).includes(errorName))
    return "common.error_unknown";
  if (CognitoErrorCode.NotAuthorizedException === errorName) {
    return "sign_in.cognito.error";
  } else {
    return `cognito.error.${errorName}` as I18nKey;
  }
};
