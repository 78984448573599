import "./ErrorCard.scss";

import { IonCard, IonCardContent, IonIcon } from "@ionic/react";
import { alertCircle } from "ionicons/icons";
import { ReactNode } from "react";

interface Props {
  title: ReactNode;
}

const ErrorCard = ({ title }: Props) => {
  if (!title) return null;

  return (
    <IonCard className="error-card">
      <IonCardContent>
        <div className="error-card-container">
          <IonIcon
            color="danger"
            icon={alertCircle}
            className="error-card-icon"
          />

          <p className="extra-small whitespace">{title}</p>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default ErrorCard;
