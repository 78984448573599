import { format, isAfter, isBefore, isEqual, parseISO } from "date-fns";
import { matchPath } from "react-router";

import { Routes } from "constants/routes.constants";

import { ReservationStatus } from "./types";

export const getListingAndReservationIds = () => {
  try {
    const match = matchPath<{ listingId: string; reservationId: string }>(
      location.pathname,
      {
        path: Routes.ReservationDetails,
        exact: false,
        strict: false,
      },
    );

    return {
      listingId: match?.params.listingId || "",
      reservationId: match?.params.reservationId || "",
    };
  } catch (error) {
    return {
      listingId: "",
      reservationId: "",
    };
  }
};

export const formatCurrency = (amount?: number) => {
  if (!amount) return "-";
  return `€ ${amount.toFixed(2).replace(".", ",")}`;
};

export const formatDateMMMddyyyyHHmm = (dateString: string) => {
  const date = parseISO(dateString);
  return format(date, "MMM dd yyyy, HH:mm");
};

export const getReservationStatus = (
  checkInDate: string,
  checkOutDate: string,
  status: string,
): ReservationStatus => {
  const today = new Date();
  const startDate = parseISO(checkInDate);
  const endDate = parseISO(checkOutDate);

  if (status === "canceled") {
    return ReservationStatus.Canceled;
  }

  if (status === "confirmed") {
    if (isBefore(endDate, today)) {
      return ReservationStatus.Completed;
    }
    if (isBefore(today, startDate)) {
      return ReservationStatus.Upcoming;
    }
    if (
      (isAfter(today, startDate) || isEqual(today, startDate)) &&
      (isBefore(today, endDate) || isEqual(today, endDate))
    ) {
      return ReservationStatus.HostingNow;
    }
  }

  return ReservationStatus.Upcoming;
};
