import { AxiosError } from "axios";
import { format, parseISO } from "date-fns";

export const formatDateMMMyyyyddHHmm = (time: string, dateString?: string) => {
  if (!dateString) {
    return "-";
  }
  const dateTimeString = `${dateString}T${time}:00`;
  const date = parseISO(dateTimeString);
  return format(date, "MMM dd yyyy, HH:mm");
};

export const errorMessage = (error: unknown) => {
  try {
    const axiosError = error as AxiosError;

    // Convert response data to a string and check if it contains the specific error message
    const errorDataString = JSON.stringify(axiosError.response?.data || {});

    if (errorDataString.includes("A reservation already exists.")) {
      return "create_reservation.error.reservation_exists";
    }

    return "common.error_unknown";
  } catch (e) {
    return "common.error_unknown";
  }
};
