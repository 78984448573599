import { IonLoading } from "@ionic/react";
import { ComponentProps } from "react";
import { useIntl } from "react-intl";

const LoadingIndicator = ({
  isOpen,
  testId,
  ...props
}: ComponentProps<typeof IonLoading> & { testId?: string }) => {
  const intl = useIntl();

  return (
    <IonLoading
      isOpen={isOpen}
      data-testid={testId}
      className="custom-loading"
      message={intl.formatMessage({ id: "loading.indicator.label" })}
      spinner="crescent"
      {...props}
    />
  );
};

export default LoadingIndicator;
