import "./FooterBar.scss";

import { Keyboard } from "@capacitor/keyboard";
import {
  IonFooter,
  IonToolbar,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { memo, ReactNode, useEffect, useRef, useState } from "react";

import { isNative } from "utils/capacitor.utils";

import { useIsKeyboardOpen } from "hooks";

type Props = {
  topButton?: ReactNode;
  backButton?: ReactNode;
  skipButton?: ReactNode;
  continueButton?: ReactNode;
  continueOnBottom?: boolean;
};

const FooterBar = ({
  topButton,
  backButton,
  continueButton,
  skipButton,
  continueOnBottom,
}: Props) => {
  const keyboardIsOpen = useIsKeyboardOpen();
  // Hide the footer when the view is about to leave
  // Fixes ionic bug where the footer is still briefly visible when navigating to another page
  const [isHidden, setIsHidden] = useState(false);
  useIonViewWillLeave(() => {
    isNative && setIsHidden(true);
  });
  useIonViewWillEnter(() => {
    isNative && setIsHidden(false);
  });

  const footerRef = useRef<HTMLIonFooterElement>(null);

  useEffect(() => {
    if (!isNative) return;
    Keyboard.addListener("keyboardWillShow", () => {
      setTimeout(() => {
        footerRef.current?.style.setProperty("display", "none");
      }, 10);
    });
    Keyboard.addListener("keyboardWillHide", () => {
      setTimeout(() => {
        footerRef.current?.style.setProperty("display", "flex");
      }, 10);
    });
  });

  return (
    <IonFooter
      className="ion-no-border"
      ref={footerRef}
      style={{
        paddingBottom: keyboardIsOpen ? 0 : 16,
        opacity: isHidden ? 0 : 1,
        transition: "opacity 0.3s",
        display:
          !topButton && !backButton && !skipButton && !continueButton
            ? "none"
            : "flex",
      }}
    >
      {topButton && (
        <IonToolbar className="ion-no-padding toolbar-top">
          {topButton}
        </IonToolbar>
      )}
      <IonToolbar className="ion-no-padding">
        <div
          slot={continueButton || skipButton ? "start" : ""}
          className="button-container"
          style={{ display: backButton ? "" : "none" }}
        >
          {backButton}
        </div>
        <div
          slot={backButton ? "end" : ""}
          className="button-container-2"
          style={{ display: skipButton || continueButton ? "" : "none" }}
        >
          {skipButton}
          {!continueOnBottom && continueButton}
        </div>
      </IonToolbar>
      {continueOnBottom && (
        <IonToolbar className="ion-no-padding">
          <div className="button-container-bottom">{continueButton}</div>
        </IonToolbar>
      )}
    </IonFooter>
  );
};

export default memo(FooterBar);
