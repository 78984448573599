import { useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";

import { useFormatAmenities } from "components/@listing-detail/ListingDetails/Amenities/hooks";

import OverviewCard from "./OverviewCard";

interface Props {
  amenities: Array<string>;
}

const AmenitiesCard = ({ amenities }: Props) => {
  const intl = useIntl();
  const formatAmenities = useFormatAmenities();

  const essentials = amenities?.filter((a) => a.startsWith("essentials."));
  const favourites = amenities?.filter((a) => a.startsWith("favourites."));
  const other = amenities?.filter(
    (a) => !a.startsWith("essentials.") && !a.startsWith("favourites."),
  );

  return (
    <OverviewCard
      title="complete_listing_overview.amenities.title"
      route={Routes.CompleteListingAmenities}
    >
      {amenities.length === 0 && (
        <p className="margin-0 extra-small">
          <em>
            {intl.formatMessage({ id: "complete_listing_overview.not_set" })}
          </em>
        </p>
      )}

      {amenities.length > 0 && (
        <>
          {formatAmenities(essentials, "complete_listing_overview.essentials")}
          {formatAmenities(favourites, "complete_listing_overview.favourites")}
          {formatAmenities(other, "complete_listing_overview.other")}
        </>
      )}
    </OverviewCard>
  );
};

export default AmenitiesCard;
