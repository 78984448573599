import { BlockReasonsResponse } from "types/blocks.types";
import { BlockAttributes, ReservationResponse } from "types/reservation.types";

import RestApiService from "./";

export default {
  getBlockReasons(this: RestApiService, locale: string) {
    return this.api.get<BlockReasonsResponse>("/v1/blocks/reasons", {
      headers: {
        "Accept-Language": locale,
      },
    });
  },

  createBlock(
    this: RestApiService,
    listingId: string,
    attributes: BlockAttributes,
  ) {
    // ONLY apic call that uses /v2
    return this.api.post<ReservationResponse>(
      `/v2/listings/${listingId}/blockages`,
      {
        data: {
          type: "blockages",
          attributes,
        },
      },
    );
  },
};
