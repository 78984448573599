import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { ResetPasswordConfirm as ResetPasswordConfirmComponent } from "components/@authentication/ResetPasswordConfirm";
import LogoContainer from "components/@common/LogoContainer";

const ResetPasswordConfirm = () => {
  useAndroidStatusBar({ color: "#241261", iconsColor: "light" });
  return (
    <IonPage>
      <LogoContainer>
        <ResetPasswordConfirmComponent />
      </LogoContainer>
    </IonPage>
  );
};

export default ResetPasswordConfirm;
