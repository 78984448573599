import { IonPage } from "@ionic/react";

import RequirementsFailComponent from "components/@create-listing/RequirementsFail";

const RequirementsFail = () => {
  return (
    <IonPage>
      <RequirementsFailComponent />
    </IonPage>
  );
};

export default RequirementsFail;
