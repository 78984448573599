import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { SignIn } from "components/@authentication/SignIn";
import LogoContainer from "components/@common/LogoContainer";

const Login = () => {
  useAndroidStatusBar({ color: "#241261", iconsColor: "light" });
  return (
    <IonPage>
      <LogoContainer>
        <SignIn />
      </LogoContainer>
    </IonPage>
  );
};

export default Login;
