import ItemList from "../ItemList/ItemList";
import ItemListRow from "../ItemList/ItemListRow";

interface Props {
  title: string;
  summary: string;
  space: string;
  access: string;
  interactionWithGuests: string;
  neighbourhood: string;
  transit: string;
  notes: string;
}

const ShowcaseContent = ({
  title,
  summary,
  space,
  access,
  interactionWithGuests,
  neighbourhood,
  transit,
  notes,
}: Props) => {
  const contentItems: Array<{ label: I18nKey; value: string }> = [
    { label: "showcase_content.title", value: title },
    { label: "showcase_content.summary", value: summary },
    { label: "showcase_content.space", value: space },
    { label: "showcase_content.access", value: access },
    {
      label: "showcase_content.Interaction_with _guests",
      value: interactionWithGuests,
    },
    { label: "showcase_content.neighbourhood", value: neighbourhood },
    { label: "showcase_content.transit", value: transit },
    { label: "showcase_content.notes", value: notes },
  ];

  const filteredItems = contentItems.filter((item) => item.value);

  return (
    <ItemList>
      {filteredItems.map(({ label, value }) => (
        <ItemListRow key={label} title={label}>
          <p className="margin-0">{value}</p>
        </ItemListRow>
      ))}
    </ItemList>
  );
};

export default ShowcaseContent;
