import { IonContent } from "@ionic/react";
import { useRef } from "react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { IOSSafeArea } from "components/@common/IOSSafeArea";
import { PullToRefresh, RefreshHandle } from "components/@common/PullToRefresh";
import ReservationsComponent from "components/@reservations/Reservations/Reservations";

const Reservations = () => {
  useAndroidStatusBar({ iconsColor: "dark" });
  const reservationsRef = useRef<RefreshHandle>(null);
  return (
    <>
      <IOSSafeArea />
      <IonContent className="reservations-page-ion-content">
        <PullToRefresh dataRef={reservationsRef} />
        <div className="ion-tab-page android-top-padding">
          <ReservationsComponent ref={reservationsRef} />
        </div>
      </IonContent>
    </>
  );
};

export default Reservations;
