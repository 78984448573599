import "./LoadingButton.scss";

import { IonButton, IonSpinner } from "@ionic/react";
import { ComponentProps, ReactNode } from "react";

interface Props extends ComponentProps<typeof IonButton> {
  isLoading: boolean;
  children: ReactNode;
  message?: string;
}

const LoadingButton = ({ isLoading, children, message, ...props }: Props) => {
  return (
    <IonButton {...props}>
      {!isLoading && children}
      {isLoading && (
        <div className="loading-button-message">
          {message && <span>{message}</span>}
          <IonSpinner name="crescent" />
        </div>
      )}
    </IonButton>
  );
};

export default LoadingButton;
