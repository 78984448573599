import type { UpdateUserAttributes, User } from "types/user.types";

import RestApiService from "./";

export default {
  getUser(this: RestApiService) {
    return this.api.get<User>("/v1/owners/me");
  },

  updateUser(
    this: RestApiService,
    id: string,
    attributes?: UpdateUserAttributes,
  ) {
    return this.api.patch<User>(`/v1/owners/${id}`, {
      data: {
        id,
        type: "owners",
        attributes,
      },
    });
  },
};
