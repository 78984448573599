import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import TypeComponent from "components/@create-reservation/Type";

const Type = () => {
  useAndroidStatusBar({ iconsColor: "dark" });
  return (
    <IonPage>
      <TypeComponent />
    </IonPage>
  );
};

export default Type;
