import "./AppVersion.scss";

import { BUILD_ENV, COMMIT_HASH, PACKAGE_VERSION } from "config/version";

const AppVersion = () => {
  return (
    <div className="version">
      <p className="small">
        v{PACKAGE_VERSION} ({COMMIT_HASH}) - {BUILD_ENV}
      </p>
    </div>
  );
};

export default AppVersion;
