import { IonPage } from "@ionic/react";

import CheckoutComponent from "components/@create-listing/Checkout";

const Checkout = () => {
  return (
    <IonPage>
      <CheckoutComponent />
    </IonPage>
  );
};
export default Checkout;
