import "@formatjs/intl-relativetimeformat/locale-data/en";
import "@formatjs/intl-relativetimeformat/locale-data/nl";
import "@formatjs/intl-relativetimeformat/locale-data/fr";

import { createIntl, createIntlCache, IntlShape } from "react-intl";

import config from "config";

import MESSAGES_EN from "./translations/en.json";
import MESSAGES_FR from "./translations/fr-BE.json";
import MESSAGES_NL from "./translations/nl-BE.json";

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = ["en", "nl-BE", "fr-BE"];

export const languageMap: Record<string, string> = {
  en: "EN",
  "nl-BE": "NL",
  "fr-BE": "FR",
};

const intlCache = createIntlCache();

const intl: { [key: string]: IntlShape } = {
  en: createIntl({ locale: "en", messages: MESSAGES_EN }, intlCache),
  "nl-BE": createIntl({ locale: "nl-BE", messages: MESSAGES_NL }, intlCache),
  "fr-BE": createIntl({ locale: "fr-BE", messages: MESSAGES_FR }, intlCache),
};

export const phraseAppConfig = {
  projectId: config.PHRASE.PROJECT_ID,
  accountId: config.PHRASE.ACCOUNT_ID,
  phraseEnabled: true,
  prefix: "[[__",
  suffix: "__]]",
  fullReparse: true,
};

export { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE, intl, locales };
