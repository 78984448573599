import { useMutation, useQueryClient } from "@tanstack/react-query";

import { simplifiedCalendarQueryKey } from "queries/calendar/queries";
import { apiService } from "services";

export const useDeleteReservation = () => {
  const queryClient = useQueryClient();

  const { isPending, isPaused, mutate, error } = useMutation({
    mutationFn: ({
      listingId,
      reservationId,
    }: {
      listingId: string;
      reservationId: string;
    }) => apiService.deleteReservation(listingId, reservationId),

    onSuccess: async (_response, { listingId }) => {
      //TODO: Find a clean way to do this
      // optimistic update calendar + invalidate after 5s, will usually be enough time for guesty to sync (= race condition)
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: simplifiedCalendarQueryKey(listingId),
        });
      }, 5000);
    },
  });

  return {
    deleteReservation: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
