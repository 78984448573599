import { useMutation } from "@tanstack/react-query";

import { apiService } from "services";
import { ReservationAttributes } from "types/reservation.types";

export const useCreateReservation = () => {
  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: ({
      listingId,
      reservationAttributes,
    }: {
      listingId: string;
      reservationAttributes: ReservationAttributes;
    }) => apiService.createReservation(listingId, reservationAttributes),
  });

  return {
    createReservation: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
