import { PHOTOS_ALLOWED_TYPES } from "components/@common/FileSelector/constants";

const getFileExtension = (fileUrl: string) => {
  try {
    return new URL(fileUrl).pathname.split(".").pop(); // https://s3.bucket.image.png
  } catch (error) {
    return fileUrl.split(".").pop(); // image.png
  }
};

export const canPreviewFile = (fileUrl: string) => {
  if (!fileUrl) return false;

  return PHOTOS_ALLOWED_TYPES.map((fileType) =>
    fileType.split("/").pop(),
  ).includes(getFileExtension(fileUrl));
};
