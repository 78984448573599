import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { ForgotPassword as ForgotPasswordComponent } from "components/@authentication/ForgotPassword";
import LogoContainer from "components/@common/LogoContainer";

const ForgotPassword = () => {
  useAndroidStatusBar({ color: "#241261", iconsColor: "light" });
  return (
    <IonPage>
      <LogoContainer>
        <ForgotPasswordComponent />
      </LogoContainer>
    </IonPage>
  );
};

export default ForgotPassword;
