import "./LinkUnstyled.scss";

import { ReactNode } from "react";

interface Props {
  onClick: () => void;
  children?: ReactNode;
  id?: string;
  center?: boolean;
}

const LinkUnstyled = ({ onClick, children, id, center }: Props) => {
  return (
    <button
      onClick={onClick}
      className={`link-unstyled ${center && "centered"}`}
      id={id}
      type="button"
    >
      {children}
    </button>
  );
};

export default LinkUnstyled;
