import { IonPage } from "@ionic/react";

import SegmentationComponent from "components/@create-listing/Segmentation";

const Segmentation = () => {
  return (
    <IonPage>
      <SegmentationComponent />
    </IonPage>
  );
};

export default Segmentation;
