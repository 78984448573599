import { ShowOptions, Toast } from "@capacitor/toast";
import { useIntl } from "react-intl";

type Props = ShowOptions & {
  text: I18nKey;
};

export const useToastNotification = ({
  text,
  duration = "long",
  position = "bottom",
}: Props) => {
  const intl = useIntl();

  const showToast = async () => {
    await Toast.show({
      position,
      duration,
      text: intl.formatMessage({ id: text }),
    });
  };

  return showToast;
};
