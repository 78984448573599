export const getQueryParam = (param: QueryParam) => {
  const params = new URLSearchParams(location.search);
  return params.get(param);
};

export const enum QueryParam {
  Email = "email",
  Code = "code",
}

export const getTopLevelDomainFromCurrentLocation = () => {
  try {
    const hostname = window.location.hostname;

    const hostnameParts = hostname.split(".");
    return hostnameParts.length > 1
      ? hostnameParts[hostnameParts.length - 1]
      : "be";
  } catch (error) {
    console.error("Error while retrieving the TLD:", error);
    return "be";
  }
};
