import { CompleteListingHouseRulesFormFields } from "./types";

export const defaultHouseRules = [
  { name: CompleteListingHouseRulesFormFields.children, value: true },
  { name: CompleteListingHouseRulesFormFields.infants, value: true },
  { name: CompleteListingHouseRulesFormFields.pets, value: true },
  { name: CompleteListingHouseRulesFormFields.smoking, value: false },
  { name: CompleteListingHouseRulesFormFields.events, value: false },
  {
    name: CompleteListingHouseRulesFormFields.quietTimeStart,
    value: "22:00",
  },
  {
    name: CompleteListingHouseRulesFormFields.quietTimeEnd,
    value: "07:00",
  },
  {
    name: CompleteListingHouseRulesFormFields.additionalRules,
    value: "",
  },
];

export const emptyHouseRules = [
  { name: CompleteListingHouseRulesFormFields.children, value: false },
  { name: CompleteListingHouseRulesFormFields.infants, value: false },
  { name: CompleteListingHouseRulesFormFields.pets, value: false },
  { name: CompleteListingHouseRulesFormFields.smoking, value: false },
  { name: CompleteListingHouseRulesFormFields.events, value: false },
  {
    name: CompleteListingHouseRulesFormFields.quietTimeStart,
    value: "",
  },
  {
    name: CompleteListingHouseRulesFormFields.quietTimeEnd,
    value: "",
  },
  {
    name: CompleteListingHouseRulesFormFields.additionalRules,
    value: "",
  },
];
