import { useMutation, useQueryClient } from "@tanstack/react-query";

import { accountUserQuery } from "queries/authentication";
import { apiService } from "services";
import { Address } from "types/listing.types";

export const useCreateListingAddress = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, error } = useMutation({
    mutationFn: (address: Address) => apiService.postListing(address),
    onSuccess: () => {
      // @TODO Can we do this with an optimistic update?
      queryClient.invalidateQueries({ queryKey: accountUserQuery.queryKey });
    },
  });

  return {
    createListing: mutate,
    isLoading: isPending,
    error,
  };
};
