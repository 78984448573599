import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListingQuery } from "queries/listings/queries";
import { apiService } from "services";
import { UpdateListingAttributes } from "types/listing.types";

import { defaultHouseRules } from "components/@complete-listing/constants";

import { formatHouseRulesForApi } from "./utils";

export const useUpdateListing = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, error } = useMutation({
    mutationFn: ({
      id,
      attributes,
    }: {
      id: string;
      attributes: UpdateListingAttributes;
    }) => {
      return apiService.updateListing(id, {
        ...attributes,
        houseRules: attributes.houseRules
          ? formatHouseRulesForApi(attributes.houseRules)
          : defaultHouseRules,
      });
    },
    onSuccess: (_, { id }) => {
      // @TODO This should be an optimistic update
      queryClient.invalidateQueries({ queryKey: getListingQuery(id).queryKey });
    },
  });

  return {
    updateListing: mutate,
    isLoading: isPending,
    error,
  };
};
