import { useMemo } from "react";

import { DayProps } from "types/calendar.types";

import CalendarMarking from "components/@calendar/CalendarMarking/CalendarMarking";
import { getDayColor } from "components/@calendar/utils";

const CalendarDay = ({
  date,
  calendarMarkings,
  selectedDate,
  onRemoveReservation,
}: DayProps) => {
  const isToday = useMemo(
    () => date.toDateString() === new Date().toDateString(),
    [date],
  );

  return (
    <div
      style={{
        position: "relative",
        flex: 1,
        borderRight: "1px var(--ion-text-color-step-400) solid",
      }}
      className="calendar-day"
    >
      <div
        style={{
          backgroundColor: isToday ? "var(--ion-color-white)" : undefined,
          padding: isToday ? 6 : 13,
          height: "100%",
          position: "relative",
        }}
      >
        {calendarMarkings.map((marking, index) => (
          <CalendarMarking
            key={index}
            {...marking}
            onRemoveReservation={onRemoveReservation}
          />
        ))}
        <div
          style={
            isToday
              ? {
                  position: "relative",
                  zIndex: 1,
                  borderRadius: 24,
                  background: "var(--ion-color-primary)",
                  width: 32,
                  height: 32,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : undefined
          }
        >
          <span
            style={{
              position: "relative",
              color: getDayColor(date, selectedDate, calendarMarkings),
            }}
          >
            {date.getDate()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CalendarDay;
