export const Routes = {
  Calendar: "/tab/calendar",
  MobileMenu: "/tab/menu",

  // Account & Preferences
  Account: "/account",
  Preferences: "/tab/preferences",

  // Sign Up
  SignUp: "/sign-up",
  SignUpContinue: "/sign-up/continue",
  CreateAccount: "/sign-up/create-account",
  SignUpDetails: "/sign-up/details",
  SignUpWelcome: "/sign-up/welcome",

  // Sign In
  SignIn: "/sign-in",
  SignInContinue: "/sign-in/continue",
  Login: "/sign-in/login",
  ForgotPassword: "/sign-in/forgot-password",
  ResetPassword: "/sign-in/reset-password",
  ResetPasswordConfirm: "/sign-in/reset-password-confirm",
  ResetPasswordSuccess: "/sign-in/reset-password-success",
  VerifyEmail: "/sign-in/verify-email",
  Callback: "/auth/callback",

  // Listings
  Listings: "/tab/listings",
  ListingView: "/listings/:listingId",
  ListingOverview: "/listings/:listingId/overview",
  ListingCalendar: "/listings/:listingId/calendar",
  ListingDetails: "/listings/:listingId/details",
  ListingMarketing: "/listings/:listingId/marketing",
  ListingPlatforms: "/listings/:listingId/platforms",
  ListingSettings: "/listings/:listingId/settings",

  // Reservations
  Reservations: "/tab/reservations",
  ReservationDetails:
    "/reservations/listings/:listingId/reservations/:reservationId",

  // Create reservation
  CreateReservation: "/create-reservation",
  CreateReservationType: "/create-reservation/:listingId?/:reservationId?/type",
  CreateReservationDetails:
    "/create-reservation/:listingId/:reservationId?/details",
  CreateReservationOverview:
    "/create-reservation/:listingId/:reservationId?/overview",

  // Create listing
  CreateListing: "/create-listing",
  CreateListingLaunchpad: "/create-listing/:listingId?/launchpad",
  CreateListingAddress: "/create-listing/:listingId?/address",
  CreateListingPropertyDetails: "/create-listing/:listingId/property-details",
  CreateListingRequirements: "/create-listing/:listingId/requirements",
  CreateListingRequirementsFail: "/create-listing/:listingId/requirements-fail",
  CreateListingSegmentation: "/create-listing/:listingId/segmentation",
  CreateListingUrl: "/create-listing/:listingId/url",
  CreateListingConfirmPlan: "/create-listing/:listingId/confirm-plan",
  CreateListingCheckout: "/create-listing/:listingId/checkout",
  CreateListingSuccess: "/create-listing/:listingId/success",

  // Listing completion
  CompleteListing: "/complete-listing",
  CompleteListingLaunchpad: "/complete-listing/:listingId/launchpad",
  CompleteListingAbout: "/complete-listing/:listingId/about",
  CompleteListingAmenities: "/complete-listing/:listingId/amenities",
  CompleteListingSleepingArrangements:
    "/complete-listing/:listingId/sleeping-arrangements",
  CompleteListingPhotos: "/complete-listing/:listingId/photos",
  CompleteListingHouseRules: "/complete-listing/:listingId/house-rules",
  CompleteListingCompliance: "/complete-listing/:listingId/compliance",
  CompleteListingOverview: "/complete-listing/:listingId/overview",

  // Review listing
  ReviewListing: "/review-listing",
  ReviewListingLaunchpad: "/review-listing/:listingId/launchpad",
  ReviewListingShowcase: "/review-listing/:listingId/showcase",
  ReviewListingAvailability: "/review-listing/:listingId/availability",
  ReviewListingSuccess: "/review-listing/:listingId/success",
} as const;
