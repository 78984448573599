import "./Launchpad.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";
import { OverviewStep } from "types/listing.types";

import StepperContainer from "components/@common/StepperContainer";
import StepsOverview from "components/@common/StepsOverview";

interface Props {
  highlightedStep: OverviewStep;
}

const Launchpad = ({ highlightedStep }: Props) => {
  const router = useIonRouter();

  const listingId = useWatch({ name: "listingId" });

  const handleSubmit = () => {
    let route;
    let path;

    switch (highlightedStep) {
      case OverviewStep.Creation:
        route = Routes.CreateListingAddress;
        path = listingId
          ? generatePath(route, { listingId })
          : generatePath(route);
        break;

      case OverviewStep.Details:
        route = Routes.CompleteListingAbout;
        path = generatePath(route, { listingId });
        break;

      case OverviewStep.Review:
        route = Routes.ReviewListingShowcase;
        path = generatePath(route, { listingId });
        break;

      default:
        console.error("Unexpected step");
        return;
    }

    router.push(path);
  };

  return (
    <StepperContainer
      flow={Flow.CreateListing}
      continueButton={
        <IonButton
          shape="round"
          className="secondary-button width-100"
          onClick={handleSubmit}
        >
          <FormattedMessage id="create_listing_launchpad.button.label" />
        </IonButton>
      }
    >
      <p className="caption">
        <FormattedMessage id="create_listing_launchpad.caption" />
      </p>
      <h2 className="margin-0">
        <FormattedMessage id="create_listing_launchpad.title" />
      </h2>

      <StepsOverview highlightedStep={highlightedStep} />
    </StepperContainer>
  );
};

export default Launchpad;
