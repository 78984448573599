/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./SignIn.scss";

import { IonInputPasswordToggle, IonText, useIonRouter } from "@ionic/react";
import { useQueryClient } from "@tanstack/react-query";
import { fetchAuthSession } from "aws-amplify/auth";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { postMessageToRNWebView } from "utils/react-native-webview.utils";
import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { accountUserQuery, useCreateLogin } from "queries";
import { useAuthStore } from "store/auth-store";

import ButtonUnstyled from "components/@common/ButtonUnstyled";
import ErrorCard from "components/@common/ErrorCard";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import { Form } from "components/@form/Form";
import { FormIonInput } from "components/@form/FormIonInput";

import { LoginFormFields } from "../types";
import { getCognitoErrorMessageKey } from "./utils";

interface FormData {
  password: string;
}

const defaultValues = {
  [LoginFormFields.Password]: "",
};

const SignIn = () => {
  const intl = useIntl();
  const { email, userName } = useAuthStore((state) => ({
    email: state.values?.email,
    userName: state.values?.userName,
  }));
  const router = useIonRouter();
  const { createLogin, error } = useCreateLogin();
  const [isLoading, setIsLoading] = useState(false);
  const queryClient = useQueryClient();

  const handleSubmit = ({ password }: FormData) => {
    setIsLoading(true);
    createLogin(
      {
        username: email!,
        password: password,
      },
      {
        onSuccess: async () => {
          // Check if running inside a React Native WebView
          if (window.ReactNativeWebView) {
            const tokens = (await fetchAuthSession()).tokens;

            postMessageToRNWebView({
              event: "SignIn",
              token: tokens?.accessToken?.toString(),
            });

            // Delay the loading for 2 second to allow the WebView to process the message
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          } else {
            // Only navigate if not inside the WebView
            const user = queryClient.getQueryData(accountUserQuery.queryKey);
            const profileCompleted =
              !!user?.data.data.attributes.profileCompleted;

            router.push(
              profileCompleted ? Routes.Listings : Routes.SignUpDetails,
              profileCompleted ? "root" : "forward",
              profileCompleted ? "replace" : "push",
            );
            setIsLoading(false);
          }
        },
        onError: (loginError) => {
          if (
            loginError.message === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
          ) {
            router.push(
              generatePath(
                Routes.ResetPasswordConfirm + "?confirm-sign-in=true",
              ),
              "forward",
            );
          } else {
            captureMessage("Error creating login", {
              level: "error",
              extra: { error: loginError },
            });
          }
          setIsLoading(false);
        },
      },
    );
  };

  useEffect(() => {
    if (!email) {
      router.push(Routes.SignInContinue, "back");
    }
  }, [email, router]);

  return (
    <div className="container">
      <IonText>
        <h2>
          <FormattedMessage
            id={
              userName
                ? "sign_in.username_remembered.title"
                : "onboarding.signin.title"
            }
            values={{ userName }}
          />
        </h2>
      </IonText>
      <p className="description">
        <IonText>
          <FormattedMessage id="onboarding.signin.description" />
        </IonText>
      </p>

      {error && (
        <ErrorCard
          title={
            <FormattedMessage
              id={getCognitoErrorMessageKey(error.name)}
              values={{
                email: <b>{email}</b>,
              }}
            />
          }
        />
      )}

      <Form<FormData>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onSubmit"
      >
        <FormIonInput
          name={LoginFormFields.Password}
          aria-label={LoginFormFields.Password}
          data-testid="password-input"
          autocomplete="current-password"
          type="password"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "login.field.password.error.required",
              }),
            },
          }}
          clearOnEdit={false}
          fill="solid"
          placeholder={intl.formatMessage({
            id: "onboarding.login.password.placeholder",
          })}
        >
          <IonInputPasswordToggle
            data-testid="password-toggle"
            color="medium"
            slot="end"
          />
        </FormIonInput>

        <LinkUnstyled
          data-testid="forgot-password-link"
          id="forgot-password"
          onClick={() => router.push(Routes.ForgotPassword)}
        >
          <FormattedMessage id="onboarding.signin.forgot_password" />
        </LinkUnstyled>

        <LoadingButton
          data-testid="submit-button"
          className="continue-button"
          shape="round"
          expand="full"
          type="submit"
          isLoading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      </Form>

      <div className="back-container">
        <ButtonUnstyled
          id="back-to-sign-in"
          data-testid="back-to-sign-in"
          onClick={() => router.push(Routes.SignInContinue, "back")}
        >
          <FormattedMessage id="back_to_login.button" />
        </ButtonUnstyled>
      </div>
    </div>
  );
};

export default SignIn;
