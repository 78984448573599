import { DefaultSchema } from "types/listing.types";

import {
  ReviewListingAdvanceNotice,
  ReviewListingBookingWindow,
} from "./types";

export const defaultAvailabilities = {
  advanceNotice: ReviewListingAdvanceNotice.None,
  bookingWindow: ReviewListingBookingWindow.All,
  customSchema: [],
  defaultSchema: DefaultSchema.Always,
  preparationTime: "",
};
