import { IonIcon, IonText, IonTextarea } from "@ionic/react";
import { warning } from "ionicons/icons";
import { ComponentProps } from "react";
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

type Props = Pick<UseControllerProps, "rules" | "name"> &
  ComponentProps<typeof IonTextarea> & {
    hint?: string;
  };

const FormIonTextarea = ({
  rules,
  name,
  label,
  helperText,
  hint,
  ...rest
}: Props) => {
  const { control } = useFormContext();

  const getHelperText = (error?: FieldError) => {
    if (helperText && !error?.message) {
      return helperText;
    }
    if (error?.message) return error.message;
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, ...field },
        fieldState: { error },
      }) => (
        <div>
          <IonTextarea
            value={value}
            label={label}
            {...field}
            onIonChange={onChange}
            {...rest}
          />

          {getHelperText(error) && (
            <div className="helper-text-container">
              <IonIcon color="danger" icon={warning} />
              <IonText color="danger">
                <p data-testid="helper-text" className="helper-text margin-0">
                  {getHelperText(error)}
                </p>
              </IonText>
            </div>
          )}
          {hint && <p className="form-hints">{hint}</p>}
        </div>
      )}
    />
  );
};

export default FormIonTextarea;
