import "./Percentage.scss";

import { FormattedMessage } from "react-intl";

interface Props {
  number: number;
  sign: string;
  description: I18nKey;
}

const Percentage = ({ number, sign, description }: Props) => {
  return (
    <div className="percentage-container">
      <div className="percentage-flex">
        <p className="percentage">
          {/* <FormattedMessage id={`${plan}_plan.percentage.number`} /> */}
          {number}
        </p>
        <p className="percent">{sign}</p>
      </div>
      <p className="extra-small margin-0 whitespace">
        <FormattedMessage id={description} />
      </p>
    </div>
  );
};

export default Percentage;
