import { IonPage } from "@ionic/react";

import PropertyDetailsComponent from "components/@create-listing/PropertyDetails";

const PropertyDetails = () => {
  return (
    <IonPage>
      <PropertyDetailsComponent />
    </IonPage>
  );
};

export default PropertyDetails;
