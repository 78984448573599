import { IonPage } from "@ionic/react";

import AboutComponent from "components/@complete-listing/About";

const About = () => {
  return (
    <IonPage>
      <AboutComponent />
    </IonPage>
  );
};

export default About;
