import { IonPage } from "@ionic/react";

import DetailsComponent from "components/@create-reservation/Details";

const Details = () => {
  return (
    <IonPage>
      <DetailsComponent />
    </IonPage>
  );
};

export default Details;
