/* eslint-disable no-console */
import { Capacitor } from "@capacitor/core";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import React from "react";
import ReactDOM from "react-dom/client";

import { runInDemo, runInProd, runInStaging } from "utils/env.utils";

import { initNativeSentry } from "services/sentry/native-sentry";
import { initWebSentry } from "services/sentry/web-sentry";

import App from "./App";

runInProd(() => {
  if (Capacitor.isNativePlatform()) {
    initNativeSentry();
  } else {
    initWebSentry();
  }
});

runInStaging(() => {
  console.log("Running Sentry in staging mode for now");
  if (Capacitor.isNativePlatform()) {
    initNativeSentry();
  } else {
    initWebSentry();
  }
});

runInDemo(() => {
  console.log("Running Sentry in demo mode for now");
  if (Capacitor.isNativePlatform()) {
    initNativeSentry();
  } else {
    initWebSentry();
  }
});

defineCustomElements(window);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
