export enum COLUMN_KEYS {
  MainGuestName = "main_guest_name",
  Rating = "overall_score",
  Status = "status",
  Id = "listing.id",
  CheckInDate = "check_in_date",
  CheckOutDate = "check_out_date",
  Nights = "number_of_nights",
  Guests = "number_of_guests",
  CreatedDate = "created_date",
  OTAName = "ota_name",
  CanceledOn = "canceled_date",
  ConfirmationCode = "confirmation_code",
  RentalRevenue = "rental_revenue",
  OwnerRevenue = "owner_revenue",
}

export type Column = {
  id: COLUMN_KEYS;
  label: I18nKey;
  initialSort: SortDirection;
  optional: boolean;
  tabs: Array<TabOption>;
  width?: string;
};

export enum SortDirection {
  Asc = "asc",
  Desc = "desc",
}

export type ReservationFilters = {
  status?: string;
  "listing.id"?: string;
  start_date?: string;
  end_date?: string;
  by_owner?: boolean;
  ota_name?: string;
  search?: string;
};

export enum TabOption {
  Upcoming = "upcoming",
  Completed = "completed",
  Canceled = "canceled",
  All = "all",
}
