import {
  IonIcon,
  IonImg,
  IonSpinner,
  IonText,
  useIonAlert,
} from "@ionic/react";
import { documentOutline, trash } from "ionicons/icons";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { useUploadFile } from "queries";
import { Context } from "types/upload.types";

import { canPreviewFile } from "components/@common/FileSelector/FilePreview/utils";

interface Props {
  file?: File;
  fileUrl: string;
  deleteFile: () => void;
  listingId: string;
  context: Context;
  onFinish: (filePath: string) => void;
}

const FileUpload = ({
  file,
  fileUrl,
  listingId,
  context,
  deleteFile,
  onFinish,
}: Props) => {
  const [showFileIcon, setShowFileIcon] = useState(
    !canPreviewFile(file?.name ?? fileUrl),
  );
  const startedUpload = useRef(!file);
  const { uploadFile, isLoading, isErrorUpload } = useUploadFile({
    listingId,
    context,
  });
  const [presentAlert] = useIonAlert();
  const intl = useIntl();

  useEffect(() => {
    const handleUpload = async (file: File) => {
      try {
        const filePath = await uploadFile(file);
        if (filePath) {
          onFinish(filePath);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (!startedUpload.current && !!file) {
      handleUpload(file);
      startedUpload.current = true;
    }
  }, [file, fileUrl, onFinish, uploadFile]);

  const fileName = file?.name || fileUrl.split("/").pop();

  const handleOpenFile = () => {
    window.open(fileUrl, "_blank");
  };

  return (
    <div className="photo-item">
      {fileUrl && !showFileIcon ? (
        <IonImg src={fileUrl} onIonError={() => setShowFileIcon(true)} />
      ) : (
        <div className="file-preview" onClick={handleOpenFile}>
          <IonIcon icon={documentOutline} />
          <IonText color="medium">{fileName}</IonText>
        </div>
      )}

      {isLoading && (
        <div className="file-selector-overlay loading-overlay">
          <IonSpinner className="loading-overlay-spinner" name="crescent" />
        </div>
      )}

      {isErrorUpload && (
        <div className="file-selector-overlay error-overlay">
          <p>
            <FormattedMessage id="complete_listing_photos.upload_failed" />
          </p>
        </div>
      )}

      <button
        className="delete-button"
        onClick={() =>
          isErrorUpload
            ? deleteFile()
            : presentAlert({
                header: intl.formatMessage({
                  id: "complete_listing_photos.delete_photo_alert_title",
                }),
                message: intl.formatMessage({
                  id: "complete_listing_photos.delete_photo_alert_message",
                }),
                cssClass: "delete-alert",
                buttons: [
                  { text: intl.formatMessage({ id: "button.cancel" }) },
                  {
                    text: intl.formatMessage({ id: "button.delete" }),
                    handler: () => deleteFile(),
                  },
                ],
              })
        }
      >
        <IonIcon className="delete-button-icon" icon={trash} />
      </button>
    </div>
  );
};

export default FileUpload;
