import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useState } from "react";

import { ContentType, Context, UploadFileParams } from "types/upload.types";

import { useRequestPresignedUrl } from "./useRequestPresignedUrl";

const uploadFile = async ({ url, fields, file }: UploadFileParams) => {
  const body = new FormData();

  Object.entries(fields).forEach(([key, value]) => {
    switch (key.toLowerCase()) {
      case "awsaccesskeyid":
        body.append("AWSAccessKeyId", value);
        break;
      case "xamzsecuritytoken":
        body.append("x-amz-security-token", value);
        break;
      case "contenttype":
        // Skip content type as it's already set in request presigned url and it just throws an error
        break;
      default:
        body.append(key, value);
    }
  });

  body.append("file", file);

  const response = await axios.post(url, body);
  return response.data;
};

interface Props {
  listingId: string;
  context: Context;
}

export const useUploadFile = ({ listingId, context }: Props) => {
  const { requestPresignedUrl, error: requestUrlError } =
    useRequestPresignedUrl();
  const { mutateAsync, error: uploadError } = useMutation({
    mutationFn: uploadFile,
  });
  const [isErrorUpload, setIsErrorUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const uploadFileCallback = useCallback(
    async (file: File) => {
      setIsLoading(true);
      setIsErrorUpload(false);

      try {
        const presignedData = await requestPresignedUrl({
          listingId,
          context,
          filename: file.name,
          contentType: file.type as ContentType,
        });

        if (presignedData) {
          await mutateAsync({
            url: presignedData.data.url,
            fields: presignedData.data.fields,
            file,
          });

          return presignedData.data.path;
        }
      } catch (error) {
        setIsErrorUpload(true);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [listingId, context, requestPresignedUrl, mutateAsync],
  );

  return {
    uploadFile: uploadFileCallback,
    requestUrlError,
    uploadError,
    isErrorUpload,
    isLoading,
  };
};
