import "./ListingOverviewHeader.scss";

import {
  IonButton,
  IonHeader,
  IonIcon,
  IonText,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import { useMemo } from "react";

import { Routes } from "constants/routes.constants";
import { useListing } from "queries";

import DynamicShareButton from "components/@common/DynamicShareButton";

import { getListingId } from "../utils";

interface Props {
  isScrolled: boolean;
}

const ListingOverviewHeader = ({ isScrolled }: Props) => {
  const listingId = getListingId();
  const { data, isLoading } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });
  const router = useIonRouter();

  const listing = data?.attributes;

  const huswellPlatformUrl = useMemo(() => {
    return data?.relationships.otas.data.find(
      (ota) => ota.attributes.default === true,
    )?.attributes.externalUrl;
  }, [data]);

  return (
    <IonHeader className="ion-no-border ion-hide-md-down">
      <IonToolbar>
        <div className={`overview-header ${isScrolled ? "scrolled" : ""}`}>
          <div className="overview-header-left">
            <IonButton
              shape="round"
              onClick={() => router.push(Routes.Listings, "back")}
            >
              <IonIcon icon="assets/icons/icon-arrow-back.svg" />
            </IonButton>

            {isLoading ? (
              <div
                style={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                <div
                  style={{
                    background: "var(--ion-text-color-step-300)",
                    height: 22,
                    width: 135,
                    borderRadius: 8,
                  }}
                />
                <div
                  style={{
                    background: "var(--ion-text-color-step-300)",
                    height: 22,
                    width: 238,
                    borderRadius: 8,
                  }}
                />
              </div>
            ) : (
              <div className="overview-nickname">
                <p className="margin-0 small bold">
                  <IonText color="primary">{listing?.nickname}</IonText>
                </p>

                <h5>{listing?.title}</h5>
              </div>
            )}
          </div>

          <div>
            {huswellPlatformUrl && (
              <DynamicShareButton url={huswellPlatformUrl} isMobile={false} />
            )}
          </div>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default ListingOverviewHeader;
