import { IonCheckbox } from "@ionic/react";
import { ComponentProps, ReactNode } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

type Props = Pick<UseControllerProps, "rules" | "name"> &
  ComponentProps<typeof IonCheckbox> & {
    children: ReactNode;
  };

const FormIonCheckbox = ({ rules, name, children, ...rest }: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value, ...field } }) => (
        <IonCheckbox
          checked={value}
          {...field}
          onIonChange={(e) => onChange(e.detail.checked)}
          {...rest}
        >
          {children}
        </IonCheckbox>
      )}
    />
  );
};

export default FormIonCheckbox;
