import {
  Country,
  getErrorMessage,
  isValidCountryVat,
  isValidVat,
} from "vat-number-validator";

export const validateVatNumber = (vatNumber: string) => {
  const regionNames = new Intl.DisplayNames(["en"], {
    type: "region",
  });
  const vatCountry = vatNumber.slice(0, 2);
  const country = regionNames.of(vatCountry) as Country;
  const isValid = isValidVat(vatNumber);
  const isValidCountry = isValidCountryVat(vatNumber, country);

  const errorMessage = getErrorMessage(country);

  return {
    isValid: isValidCountry && isValid,
    errorMessage,
  };
};
