import { Routes } from "constants/routes.constants";
import { User } from "types/user.types";

export interface AuthProps {
  user: User | undefined;
  isLoggedIn: boolean;
  isLoading: boolean;
  signInError: boolean;
}

export enum CognitoErrorCode {
  UsernameExistsException = "UsernameExistsException",
  UserNotFoundException = "UserNotFoundException",
  UserAlreadyAuthenticatedException = "UserAlreadyAuthenticatedException",
  NotAuthorizedException = "NotAuthorizedException",
  UserNotConfirmedException = "UserNotConfirmedException",
  LimitExceededException = "LimitExceededException",
  ExpiredCodeException = "ExpiredCodeException",
  CodeMismatchException = "CodeMismatchException",
  InvalidParameterException = "InvalidParameterException",
}

export type CognitoUserParams = {
  username: string;
  password: string;
};

export enum LoginProvider {
  Apple = "Apple",
  Google = "Google",
  Facebook = "Facebook",
}

export type CallbackRoute =
  | typeof Routes.Listings
  | typeof Routes.SignUpDetails;

export type CustomState = {
  provider: LoginProvider;
};
