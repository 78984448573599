import { Context, UploadResponse } from "types/upload.types";

import RestApiService from "./";

export default {
  requestPresignedUrl(
    this: RestApiService,
    listingId: string,
    context: Context,
    filename: string,
    contentType: string,
  ) {
    return this.api.post<UploadResponse>("/v1/presigned-url", {
      data: {
        type: "presigned-url",
        attributes: {
          listingId,
          context,
          filename,
          contentType,
        },
      },
    });
  },
};
