import "./SuccessCard.scss";

import { IonCard, IonCardContent, IonIcon } from "@ionic/react";
import { informationCircle } from "ionicons/icons";
import { FormattedMessage } from "react-intl";

interface Props {
  title: I18nKey;
}

const SuccessCard = ({ title }: Props) => {
  if (!title) return null;

  return (
    <IonCard className="success-card">
      <IonCardContent>
        <div className="success-card-container">
          <IonIcon icon={informationCircle} className="success-card-icon" />

          <p className="extra-small whitespace">
            <FormattedMessage id={title} />
          </p>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default SuccessCard;
