import "./TableSkeleton.scss";

import { IonSkeletonText } from "@ionic/react";

import { Column } from "./types";

interface Props {
  columns: Array<Column>;
}

const TableSkeleton = ({ columns }: Props) => {
  return Array.from({ length: 3 }).map((_, rowIndex) => (
    <tr key={`skeleton-${rowIndex}`}>
      {columns.map(({ width }, colIndex) => (
        <td key={colIndex} style={{ width }}>
          <IonSkeletonText animated={true} />
        </td>
      ))}
    </tr>
  ));
};

export default TableSkeleton;
