import { IonPage } from "@ionic/react";

import { SignUp } from "components/@authentication/SignUp";
import LogoContainer from "components/@common/LogoContainer";

const CreateAccount = () => {
  return (
    <IonPage>
      <LogoContainer>
        <SignUp />
      </LogoContainer>
    </IonPage>
  );
};

export default CreateAccount;
