import { FormattedMessage } from "react-intl";

import { planKeys } from "constants/listing.constants";
import { ListingResponse } from "types/listing.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";

type Props = {
  listing: ListingResponse["data"];
};

const Plan = ({ listing }: Props) => {
  const plan = listing.attributes.pricingPlan;

  return (
    <ItemList title="listing.settings.plan">
      <ItemListRow title="create_listing_overview.plan.title">
        <div style={{ display: "flex", flexDirection: "column", rowGap: 4 }}>
          <p className="margin-0" style={{ fontWeight: 500 }}>
            <FormattedMessage
              id={
                !!plan
                  ? "listing.settings.plan.plan"
                  : "create_listing_overview.plan.not_set"
              }
              values={{
                plan: <FormattedMessage id={planKeys[plan]} />,
                percentage: (
                  <FormattedMessage id={`${plan}_plan.percentage.number`} />
                ),
              }}
            />
          </p>
          {!!plan && (
            <>
              <p className="margin-0">
                <FormattedMessage
                  id="listing.settings.plan.plan_percentage"
                  values={{
                    percentage: (
                      <FormattedMessage id={`${plan}_plan.percentage.number`} />
                    ),
                  }}
                />
              </p>
              <p className="margin-0">
                <FormattedMessage
                  id="listing.settings.plan.plan_cost"
                  values={{
                    cost: (
                      <FormattedMessage
                        id={
                          !!plan
                            ? `${plan}_plan.per_month`
                            : "complete_listing_overview.not_set"
                        }
                      />
                    ),
                  }}
                />
              </p>
            </>
          )}
        </div>
      </ItemListRow>
    </ItemList>
  );
};

export default Plan;
