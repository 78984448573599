import "./FormAccordion.scss";

import {
  IonAccordion,
  IonAccordionGroup,
  IonCheckbox,
  IonItem,
  IonLabel,
  IonText,
} from "@ionic/react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { Option } from "../types";

type Props = Pick<UseControllerProps, "rules" | "name"> & {
  options: Array<Option>;
  label: string;
  hint?: string;
};

const FormAccordion = ({ rules, name, label, options, hint }: Props) => {
  const { control } = useFormContext();

  const handleItemClick = (option: string, value: Array<string>) => {
    const isChecked = value.includes(option);
    return isChecked
      ? value.filter((val: string) => val !== option)
      : [...value, option];
  };

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const selectedOptions = Array.isArray(value) ? value : [];
        // Calculate the number of selected options in this group
        const selectedCount = options.filter((option) =>
          selectedOptions.includes(option.value),
        ).length;

        return (
          <div>
            <IonAccordionGroup>
              <IonAccordion value={name}>
                <IonItem slot="header" className="accordion-header">
                  <IonLabel>
                    <div className="accordion-header-label">
                      <p className="regular">{label}</p>
                      <p className="extra-small">
                        {selectedCount}/{options.length}
                      </p>
                    </div>
                  </IonLabel>
                </IonItem>
                <div slot="content">
                  {options.map((option, i) => (
                    <IonItem
                      button
                      detail={false}
                      lines="full"
                      className="accordion-content"
                      key={`${option.value}-${i}`}
                      onClick={() =>
                        onChange(handleItemClick(option.value, value))
                      }
                    >
                      <IonLabel>
                        <FormattedMessage id={option.label ?? ""} />
                      </IonLabel>

                      <IonCheckbox
                        className="accordion-checkbox"
                        slot="start"
                        value={option.value}
                        checked={value?.includes(option.value)}
                        onIonChange={() =>
                          onChange(handleItemClick(option.value, value))
                        }
                      />
                    </IonItem>
                  ))}
                </div>
              </IonAccordion>
            </IonAccordionGroup>

            {error?.message && (
              <div className="helper-text-container">
                <IonText color="danger">
                  <p data-testid="helper-text" className="helper-text margin-0">
                    {error.message}
                  </p>
                </IonText>
              </div>
            )}
            {hint && <p className="form-hints">{hint}</p>}
          </div>
        );
      }}
    />
  );
};

export default FormAccordion;
