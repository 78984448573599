import { useIonRouter, useIonViewWillEnter } from "@ionic/react";
import { format, parseISO } from "date-fns";
import { ReactNode, useEffect, useMemo, useRef } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { generatePath, matchPath, useLocation } from "react-router";

import { Routes } from "constants/routes.constants";
import { useToastNotification } from "hooks";
import { useUser } from "queries";
import { useReservation } from "queries/reservations";
import { CreateReservationProviderValues } from "types/reservation.types";

import LoadingIndicator from "components/@common/LoadingIndicator";

interface Props {
  children: ReactNode;
}

const CreateReservationProvider = ({ children }: Props) => {
  const location = useLocation();
  const router = useIonRouter();

  const { data: user } = useUser();

  const match = matchPath<{ listingId: string; reservationId: string }>(
    location.pathname,
    {
      path: "/create-reservation/:listingId/:reservationId?/*",
      exact: false,
      strict: false,
    },
  );

  const listingId = match?.params.listingId;
  const reservationId = match?.params.reservationId;

  const defaultValues: CreateReservationProviderValues = useMemo(
    () => ({
      listingId: listingId ?? "",
      reservationId: reservationId ?? "",
      checkInDate: "",
      checkOutDate: "",
      numberOfGuests: 1,
      note: null,
      blockReason: null,
      reservationType: null,
      reasonId: null,
    }),
    [listingId, reservationId],
  );

  const methods = useForm<CreateReservationProviderValues>({
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const showErrorToast = useToastNotification({
    text: "listing_overview.reservations.error",
  });

  const {
    data: reservation,
    isLoading,
    isError,
  } = useReservation(listingId ?? "", reservationId ?? "", {
    enabled: !!listingId && !!reservationId,
  });

  const hasInitialised = useRef(false);
  const backRoute =
    new URLSearchParams(router.routeInfo.search).get("backroute") ||
    Routes.Reservations;

  useIonViewWillEnter(() => {
    if (!router.routeInfo.lastPathname) {
      router.push(
        generatePath(Routes.CreateReservationType + `?backroute=${backRoute}`, {
          listingId,
          reservationId: reservationId ? reservationId : undefined,
        }),
      );
    }
  });

  useEffect(() => {
    hasInitialised.current = false;
  }, [listingId, reservationId]);

  useEffect(() => {
    const userListings = user?.data.relationships.listings.data || [];
    const newListingId =
      listingId ?? (userListings.length === 1 ? userListings[0].id : "");

    if (reservation) {
      hasInitialised.current = true;
      const defaultValues: CreateReservationProviderValues = {
        listingId: newListingId,
        reservationId: reservationId ?? "",
        checkInDate: format(
          parseISO(reservation.attributes.checkInDate),
          "yyyy-MM-dd",
        ),
        checkOutDate: format(
          parseISO(reservation.attributes.checkOutDate),
          "yyyy-MM-dd",
        ),
        numberOfGuests: reservation.attributes.numberOfGuests,
        note: "",
        reservationType: null,
        reasonId: "",
      };
      methods.reset(defaultValues);
    } else {
      hasInitialised.current = true;
      methods.setValue("listingId", newListingId);
    }
  }, [
    listingId,
    methods,
    reservation,
    reservationId,
    user?.data.relationships.listings.data,
    defaultValues,
  ]);

  useEffect(() => {
    if (isError) {
      if (!listingId || !reservationId) {
        router.push(Routes.Reservations, "back");
        return;
      } else {
        router.push(
          generatePath(Routes.ReservationDetails, {
            listingId,
            reservationId,
          }),
          "back",
        );
      }
      showErrorToast();
    }
  }, [isError, listingId, reservationId, router, showErrorToast]);

  return (
    <FormProvider {...methods}>
      <LoadingIndicator isOpen={isLoading} testId="CreateReservationProvider" />
      {children}
    </FormProvider>
  );
};

export default CreateReservationProvider;
