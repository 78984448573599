import "./HuswellShield.scss";

import { IonContent, IonIcon, IonPopover, IonText } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
  reservationConfirmed: boolean;
  contractSigned: boolean;
  identityVerified: boolean;
}

const HuswellShield = ({
  reservationConfirmed,
  contractSigned,
  identityVerified,
}: Props) => {
  const intl = useIntl();

  // If reservation is cancelled (not confirmed) or all fields are false, return null
  if (!reservationConfirmed || (!contractSigned && !identityVerified)) {
    return null;
  }

  const statusMessages: Array<{ id: I18nKey; show: boolean }> = [
    { id: "huswell_shield.reservation_confirmed", show: reservationConfirmed },
    { id: "huswell_shield.contract_signed", show: contractSigned },
    { id: "huswell_shield.identity_verified", show: identityVerified },
  ];

  const activeStatuses = statusMessages
    .filter((status) => status.show)
    .map((status) => intl.formatMessage({ id: status.id }))
    .join(" · ");

  return (
    <>
      <div className="huswell-shield">
        <IonIcon
          className="verified-icon"
          src="assets/icons/icon-verified.svg"
        />

        <div className="huswell-shield-info">
          <div className="huswell-shield-title">
            <p className="margin-0 bold">
              <FormattedMessage id="huswell_shield.title" />
            </p>
            <IonIcon
              className="info-icon"
              src="assets/icons/icon-info-circle.svg"
              id="hover-trigger"
            />
          </div>

          <IonText color="primary">
            <p className="margin-0">{activeStatuses}</p>
          </IonText>
        </div>
      </div>

      <IonPopover
        side="bottom"
        alignment="center"
        keepContentsMounted
        trigger="hover-trigger"
        triggerAction="hover"
      >
        <IonContent>
          <div className="tooltip-container">
            <p className="margin-0">
              <FormattedMessage id="huswell_shield_info.tooltip" />
            </p>
          </div>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default HuswellShield;
