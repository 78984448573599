import "./Reservations.scss";

import { IonSkeletonText } from "@ionic/react";

const ReservationSkeletonBox = () => (
  <div className="reservation skeleton">
    <IonSkeletonText animated={true} style={{ width: "35%" }} />
    <IonSkeletonText animated={true} style={{ width: "50%" }} />
    <IonSkeletonText animated={true} style={{ width: "70%" }} />
  </div>
);

export default ReservationSkeletonBox;
