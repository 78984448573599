import "./About.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useAmenities, useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import { CompleteListingProviderValues } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";
import { FormIonInput } from "components/@form/FormIonInput";

import { CompleteListingAboutFormFields } from "../types";

const About = () => {
  const intl = useIntl();
  useAmenities(); // preload amenities
  const { updateListing, error, isLoading } = useUpdateListing();

  const router = useIonRouter();
  const { handleSubmit } = useFormContext<CompleteListingProviderValues>();

  const listingId = useWatch({ name: "listingId" });

  const onSubmit = (data: CompleteListingProviderValues) => {
    updateListing(
      {
        id: listingId,
        attributes: {
          neighborhood: data.neighborhood,
          title: data.title,
          address: {
            region: data.region || null,
            unit: data.unit || null,
            appartment: data.appartment || null,
            floorNumber: data.floorNumber || null,
            floorAmount: data.floorAmount || null,
          },
        },
      },
      {
        onSuccess: () => {
          router.push(
            generatePath(Routes.CompleteListingAmenities, {
              listingId,
            }),
          );
        },
        onError: (error) => {
          captureMessage("Error updating address in listing completion", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const handleBack = () => {
    router.push(
      generatePath(Routes.CompleteListingLaunchpad, {
        listingId,
      }),
      "back",
    );
  };

  return (
    <StepperContainer
      flow={Flow.CompleteListing}
      route={Routes.CompleteListingAbout}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form-about"
          isLoading={isLoading}
          disabled={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="complete_listing_about.title" />
      </h2>
      <p className="create-listing-description about-description">
        <FormattedMessage id="complete_listing_about.description" />
      </p>

      {error && (
        <ErrorCard title={<FormattedMessage id={"common.error_unknown"} />} />
      )}

      <form id="hook-form-about" onSubmit={handleSubmit(onSubmit)}>
        <p className="margin-16 bold">
          <FormattedMessage id="complete_listing_about.subtitle1" />
        </p>

        <FormIonInput
          name={CompleteListingAboutFormFields.Title}
          data-testid="nickname-input"
          aria-label={CompleteListingAboutFormFields.Title}
          type="text"
          fill="solid"
          maxlength={50}
          placeholder={intl.formatMessage({ id: "nickname_input.placeholder" })}
          label={intl.formatMessage({ id: "nickname.label" })}
          rules={{
            required: intl.formatMessage({ id: "nickname.required" }),
            validate: (value) => {
              if (!value.trim()) {
                return intl.formatMessage({ id: "nickname.required" });
              }
            },
          }}
          labelPlacement="floating"
        />

        <p className="margin-16 bold">
          <FormattedMessage id="complete_listing_about.subtitle2" />
        </p>

        <div className="grid-container">
          <FormIonInput
            name={CompleteListingAboutFormFields.Neighborhood}
            data-testid="neighbourhood-input"
            aria-label={CompleteListingAboutFormFields.Neighborhood}
            type="text"
            fill="solid"
            placeholder={intl.formatMessage({
              id: "neighbourhood_input.placeholder",
            })}
            label={intl.formatMessage({ id: "neighbourhood.label" })}
            labelPlacement="floating"
          />

          <FormIonInput
            name={CompleteListingAboutFormFields.Region}
            data-testid="region-input"
            aria-label={CompleteListingAboutFormFields.Region}
            type="text"
            fill="solid"
            placeholder={intl.formatMessage({
              id: "region_input.placeholder",
            })}
            label={intl.formatMessage({ id: "region.label" })}
            labelPlacement="floating"
          />

          <FormIonInput
            name={CompleteListingAboutFormFields.Unit}
            data-testid="unit-input"
            aria-label={CompleteListingAboutFormFields.Unit}
            type="text"
            fill="solid"
            placeholder={intl.formatMessage({
              id: "unit_input.placeholder",
            })}
            label={intl.formatMessage({ id: "unit.label" })}
            labelPlacement="floating"
          />

          <FormIonInput
            name={CompleteListingAboutFormFields.Appartment}
            data-testid="appartment-input"
            aria-label={CompleteListingAboutFormFields.Appartment}
            type="text"
            fill="solid"
            placeholder={intl.formatMessage({
              id: "appartment_input.placeholder",
            })}
            label={intl.formatMessage({ id: "appartment.label" })}
            labelPlacement="floating"
          />

          <FormIonInput
            name={CompleteListingAboutFormFields.FloorNumber}
            data-testid="floor-number-input"
            aria-label={CompleteListingAboutFormFields.FloorNumber}
            type="number"
            fill="solid"
            placeholder={intl.formatMessage({
              id: "floor_number_input.placeholder",
            })}
            label={intl.formatMessage({ id: "floor_number.label" })}
            labelPlacement="floating"
          />

          <FormIonInput
            name={CompleteListingAboutFormFields.FloorAmount}
            data-testid="floor-amount-input"
            aria-label={CompleteListingAboutFormFields.FloorAmount}
            type="number"
            fill="solid"
            placeholder={intl.formatMessage({
              id: "floor_amount_input.placeholder",
            })}
            label={intl.formatMessage({ id: "floor_amount.label" })}
            labelPlacement="floating"
          />
        </div>
      </form>
    </StepperContainer>
  );
};

export default About;
