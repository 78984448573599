import "./Type.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useQueries } from "@tanstack/react-query";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { useUser } from "queries";
import { getListingQuery } from "queries/listings/queries";
import { Flow } from "types/flow.types";
import {
  CreateReservationProviderValues,
  ReservationType,
} from "types/reservation.types";
import { UserListing } from "types/user.types";

import StepperContainer from "components/@common/StepperContainer";
import FormRadioOptionGroup from "components/@form/FormRadioGroupOption";

const Type = () => {
  const intl = useIntl();
  const { trigger, getValues } =
    useFormContext<CreateReservationProviderValues>();

  const router = useIonRouter();

  const reservationId = useWatch({ name: "reservationId" });
  const { data: user } = useUser();

  const listings = user?.data.relationships.listings.data || [];

  const backRoute =
    new URLSearchParams(window.location.search).get("backroute") ||
    Routes.Reservations;
  const handleBack = () =>
    router.push(backRoute, "back", "pop", { unmount: true });

  const onSubmit = async () => {
    const data = getValues();
    const valid =
      (await trigger("reservationType")) && (await trigger("listingId"));
    if (!valid) return;
    router.push(
      generatePath(
        Routes.CreateReservationDetails + `?backroute=${backRoute}`,
        {
          listingId: data.listingId,
          reservationId: reservationId ? reservationId : undefined,
        },
      ),
    );
  };

  const listingsDetails = useQueries({
    queries: listings.map((listing) => ({ ...getListingQuery(listing.id) })),
  });

  const getListingName = (userListing: UserListing) => {
    const listingDetail = listingsDetails.find(
      (listing) => listing.data?.data.data.id === userListing.id,
    );

    return (
      listingDetail?.data?.data.data.attributes.title ||
      listingDetail?.data?.data.data.attributes.nickname ||
      userListing.attributes.nickname ||
      userListing.id
    );
  };

  return (
    <StepperContainer
      flow={Flow.CreateReservation}
      route={Routes.CreateReservationType}
      backRoute={backRoute}
      continueButton={
        <IonButton
          data-testid="continue-button"
          className="continue-button-stepper"
          onClick={onSubmit}
          shape="round"
          expand="full"
        >
          <FormattedMessage id="common.continue" />
        </IonButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <div className="create-reservation-type">
        <h2 className="margin-top-24">
          <FormattedMessage id="create_reservation_type.title" />
        </h2>

        <h6 className="subtitle">
          <FormattedMessage id="create_reservation_type.type_subtitle" />
        </h6>

        <form id="hook-form-type" onSubmit={onSubmit}>
          <FormRadioOptionGroup
            name="reservationType"
            options={[
              {
                label: "create_reservation.type.myself",
                value: ReservationType.Myself,
              },
              {
                label: "create_reservation.type.block",
                value: ReservationType.Block,
              },
            ]}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "requirements.error.required",
                }),
              },
            }}
          />

          <h6 className="subtitle">
            <FormattedMessage id="create_reservation_type.listing_id_subtitle" />
          </h6>

          <FormRadioOptionGroup
            name="listingId"
            options={listings.map((listing) => ({
              label: getListingName(listing),
              value: listing.id,
            }))}
            rules={{
              required: intl.formatMessage({
                id: "requirements.error.required",
              }),
            }}
          />
        </form>
      </div>
    </StepperContainer>
  );
};

export default Type;
