import { Column, COLUMN_KEYS, SortDirection, TabOption } from "./types";

export const COLUMNS: Array<Column> = [
  {
    id: COLUMN_KEYS.MainGuestName,
    label: "column_title.main_guest_name",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "12%",
  },
  {
    id: COLUMN_KEYS.Id,
    label: "column_title.id",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "12%",
  },
  {
    id: COLUMN_KEYS.Rating,
    label: "column_title.rating",
    initialSort: SortDirection.Desc,
    optional: true,
    tabs: [TabOption.Completed],
    width: "6%",
  },
  {
    id: COLUMN_KEYS.Status,
    label: "column_title.status",
    initialSort: SortDirection.Asc,
    optional: true,
    tabs: [TabOption.All],
    width: "6%",
  },
  {
    id: COLUMN_KEYS.CheckInDate,
    label: "column_title.check_in_date",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "10%",
  },
  {
    id: COLUMN_KEYS.CheckOutDate,
    label: "column_title.check_out_date",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "10%",
  },
  {
    id: COLUMN_KEYS.Nights,
    label: "column_title.nights",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "6%",
  },
  {
    id: COLUMN_KEYS.Guests,
    label: "column_title.guests",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "6%",
  },
  {
    id: COLUMN_KEYS.CreatedDate,
    label: "column_title.created_date",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "8%",
  },
  {
    id: COLUMN_KEYS.OTAName,
    label: "column_title.ota_name",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "8%",
  },
  {
    id: COLUMN_KEYS.CanceledOn,
    label: "column_title.cancelled_on",
    initialSort: SortDirection.Asc,
    optional: true,
    tabs: [TabOption.Canceled],
    width: "6%",
  },
  {
    id: COLUMN_KEYS.ConfirmationCode,
    label: "column_title.confirmation_code",
    initialSort: SortDirection.Asc,
    optional: true,
    tabs: [TabOption.All, TabOption.Upcoming, TabOption.Completed],
    width: "10%",
  },
  /* {
    id: COLUMN_KEYS.RentalRevenue,
    label: "column_title.rental_income",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "6%",
  }, */
  {
    id: COLUMN_KEYS.OwnerRevenue,
    label: "column_title.owner_income",
    initialSort: SortDirection.Asc,
    optional: false,
    tabs: [
      TabOption.All,
      TabOption.Upcoming,
      TabOption.Completed,
      TabOption.Canceled,
    ],
    width: "6%",
  },
];
