import { useContext, useMemo } from "react";

import { AuthContext } from "./context";

export const useCurrentUser = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useCurrentUser must be used with an AuthProvider");
  }

  return useMemo(
    () => ({
      user: context.user,
      isLoggedIn: context.isLoggedIn,
      isLoading: context.isLoading,
      signInError: context.signInError,
    }),
    [context.isLoading, context.isLoggedIn, context.signInError, context.user],
  );
};
