import "./Breadcrumbs.scss";

import { IonIcon, IonRouterLink } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";

import { Breadcrumb } from "./types";

type Props = {
  items: Array<Breadcrumb>;
};

const Breadcrumbs = ({ items }: Props) => {
  return (
    <div className="breadcrumbs">
      {items.map((item, index) => (
        <div key={index} className="breadcrumb">
          {item.href ? (
            <IonRouterLink routerLink={item.href} routerDirection="back">
              {item.label}
            </IonRouterLink>
          ) : (
            <p className="breadcrumb-label">{item.label}</p>
          )}

          <IonIcon className="separator" icon={chevronForwardOutline} />
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;
