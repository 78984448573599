import "./SupportedUrls.scss";

import { IonText } from "@ionic/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import LinkUnstyled from "components/@common/LinkUnstyled";
import Modal from "components/@common/Modal";

const SupportedUrls = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="margin-top-16">
        <LinkUnstyled
          data-testid="supported-urls-link"
          onClick={() => setIsModalOpen(true)}
        >
          <span style={{ textWrap: "wrap" }}>
            <FormattedMessage id="create_listing_url.supported_urls" />
          </span>
        </LinkUnstyled>
      </div>

      <Modal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
        <div className="title-container">
          <h2 className="margin-0">
            <FormattedMessage id="supported_urls.modal.title" />
          </h2>
        </div>

        <p className="margin-24">
          <FormattedMessage id="supported_urls.modal.intro" />
        </p>

        <IonText color="primary">
          <h5 className="margin-0">
            <FormattedMessage id="supported_urls.modal.listing_title" />
          </h5>
        </IonText>

        <p className="margin-16">
          <FormattedMessage id="supported_urls.modal.listing_intro" />
        </p>

        <div className="margin-16">
          <FormattedMessage
            id="supported_urls.modal.listing_options"
            values={{
              ul: (chunks) => <ul className="unordered-list">{chunks}</ul>,
              li: (chunks) => <li>{chunks}</li>,
              p: (chunks) => <p className="margin-bottom-0">{chunks}</p>,
            }}
          />
        </div>

        <IonText color="primary">
          <h5>
            <FormattedMessage id="supported_urls.modal.copying_title" />
          </h5>
        </IonText>

        <p className="margin-16">
          <FormattedMessage id="supported_urls.modal.copying_description" />
        </p>

        <div className="example-container">
          <p className="example margin-0">
            <FormattedMessage id="supported_urls.modal.url_example_1" />
          </p>

          <p className="example margin-0">
            <FormattedMessage id="supported_urls.modal.url_example_2" />
          </p>
        </div>
      </Modal>
    </>
  );
};

export default SupportedUrls;
