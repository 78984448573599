import "./VerifyEmailbanner.scss";

import { IonIcon, IonSpinner } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { captureMessage } from "utils/sentry.utils";

import { useIsEmailVerified } from "hooks/useIsEmailVerified";
import { useVerifyEmail } from "queries";

import LinkUnstyled from "../LinkUnstyled";

interface Props {
  type: "desktop" | "mobile";
}

const VerifyEmailBanner = ({ type }: Props) => {
  const { verifyEmail } = useVerifyEmail();
  const emailVerified = useIsEmailVerified();
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleVerifyEmail = () => {
    if (isLoading) return;

    setIsLoading(true);
    verifyEmail("email", {
      onSuccess: () => setEmailSent(true),
      onError: (error) =>
        captureMessage("Error verifying email in listings", {
          level: "error",
          extra: { error },
        }),
      onSettled: () => setIsLoading(false),
    });
  };

  if (emailVerified === null || emailVerified === true) {
    return null;
  }

  // @TODO email banner cuts off too much vertical content on desktop view when it takes up 2 lines.
  // See if we can make it part of the header, or make the content shift dynamically?

  return (
    <>
      {!emailVerified && (
        <div className={`verify-email-${type}`}>
          <IonIcon src="assets/icons/icon-verified.svg" />
          <FormattedMessage
            id="verify_email"
            values={{
              b: (chunks) => <b>{chunks}</b>,
              a: (chunks) =>
                isLoading ? (
                  <IonSpinner style={{ height: 22 }} />
                ) : (
                  <div className="email-button">
                    {emailSent ? (
                      <IonIcon color="primary" icon={checkmarkOutline} />
                    ) : (
                      <LinkUnstyled onClick={handleVerifyEmail}>
                        {chunks}
                      </LinkUnstyled>
                    )}
                  </div>
                ),
            }}
          />
        </div>
      )}
    </>
  );
};

export default VerifyEmailBanner;
