import {
  BedType,
  PropertyKind,
  PropertyType,
  RequirementsAnswer,
} from "types/listing.types";

export enum PricingPlan {
  Starter = "starter",
  Professional = "professional",
  Premium = "premium",
  Manual = "manual",
}

export const propertyTypeKeys: Record<PropertyType | PropertyKind, I18nKey> = {
  [PropertyType.Apartment]: "property_type.apartment.label",
  [PropertyType.House]: "property_type.house.label",
  // [PropertyType.Hotel]: "property_type.hotel.label",
  [PropertyType.Other]: "property_type.other.label",
  [PropertyKind.Room]: "property_type.room.label",
  [PropertyKind.Place]: "property_type.place.label",
};

export const requirementsKeys: Record<RequirementsAnswer, I18nKey> = {
  [RequirementsAnswer.No]: "create_listing_requirements.no_checkbox",
  [RequirementsAnswer.Yes]: "create_listing_requirements.yes_checkbox",
};

export const planKeys: Record<string, I18nKey> = {
  [PricingPlan.Starter]: "starter_plan.title",
  [PricingPlan.Professional]: "professional_plan.title",
  [PricingPlan.Premium]: "premium_plan.title",
  [PricingPlan.Manual]: "manual_plan.title",
};

export const bedTypeKeys: Record<string, I18nKey> = {
  [BedType.KING_BED]: "bed_type.KING_BED",
  [BedType.QUEEN_BED]: "bed_type.QUEEN_BED",
  [BedType.DOUBLE_BED]: "bed_type.DOUBLE_BED",
  [BedType.SINGLE_BED]: "bed_type.SINGLE_BED",
  [BedType.SOFA_BED]: "bed_type.SOFA_BED",
  [BedType.AIR_MATTRESS]: "bed_type.AIR_MATTRESS",
  [BedType.BUNK_BED]: "bed_type.BUNK_BED",
  [BedType.FLOOR_MATTRESS]: "bed_type.FLOOR_MATTRESS",
  [BedType.WATER_BED]: "bed_type.WATER_BED",
  [BedType.TODDLER_BED]: "bed_type.TODDLER_BED",
  [BedType.CRIB]: "bed_type.CRIB",
};

export const essentialsKeys: Record<string, I18nKey> = {
  wifi: "essentials.wifi",
  tv: "essentials.tv",
  kitchen: "essentials.kitchen",
  washer: "essentials.washer",
  dryer: "essentials.dryer",
  free_parking: "essentials.free_parking",
  paid_parking: "essentials.paid_parking",
  private_parking: "essentials.private_parking",
  airco: "essentials.airco",
  heating: "essentials.heating",
};

export const favouritesKeys: Record<string, I18nKey> = {
  pool: "favourites.pool",
  hot_tub: "favourites.hot_tub",
  patio: "favourites.patio",
  bbq: "favourites.bbq",
  pool_table: "favourites.pool_table",
  lake_access: "favourites.lake_access",
  beach_access: "favourites.beach_access",
  ski_access: "favourites.ski_access",
};

const accessibilityKeys: Record<string, I18nKey> = {
  grab_rails_for_shower_and_toilet:
    "accessibility.grab_rails_for_shower_and_toilet",
  tub_with_shower_bench: "accessibility.tub_with_shower_bench",
  wheelchair_accessible: "accessibility.wheelchair_accessible",
  wide_clearance_to_bed: "accessibility.wide_clearance_to_bed",
  wide_clearance_to_shower_and_toilet:
    "accessibility.wide_clearance_to_shower_and_toilet",
  wide_hallway_clearance: "accessibility.wide_hallway_clearance",
  roll_in_shower_with_shower_bench_or_chair:
    "accessibility.roll_in_shower_with_shower_bench_or_chair",
  accessible_height_toilet: "accessibility.accessible_height_toilet",
  accessible_height_bed: "accessibility.accessible_height_bed",
};

const bathroomKeys: Record<string, I18nKey> = {
  bathtub: "bathroom.bathtub",
  hair_dryer: "bathroom.hair_dryer",
  hot_water: "bathroom.hot_water",
  shampoo: "bathroom.shampoo",
};

const bedroomKeys: Record<string, I18nKey> = {
  essentials: "bedroom.essentials",
  bed_linens: "bedroom.bed_linens",
  dryer: "bedroom.dryer",
  dryer_in_common_space: "bedroom.dryer_in_common_space",
  extra_pillows_and_blankets: "bedroom.extra_pillows_and_blankets",
  hangers: "bedroom.hangers",
  iron: "bedroom.iron",
  room_darkening_shades: "bedroom.room_darkening_shades",
  safe: "bedroom.safe",
  towels_provided: "bedroom.towels_provided",
  washer: "bedroom.washer",
  washer_in_common_space: "bedroom.washer_in_common_space",
};

const entertainmentKeys: Record<string, I18nKey> = {
  cable_tv: "entertainment.cable_tv",
  dvd_player: "entertainment.dvd_player",
  game_console: "entertainment.game_console",
  stereo_system: "entertainment.stereo_system",
  tv: "entertainment.tv",
};

const familyKeys: Record<string, I18nKey> = {
  baby_bath: "family.baby_bath",
  baby_monitor: "family.baby_monitor",
  babysitter_recommendations: "family.babysitter_recommendations",
  children_books_and_toys: "family.children_books_and_toys",
  changing_table: "family.changing_table",
  children_dinnerware: "family.children_dinnerware",
  crib: "family.crib",
  family_kid_friendly: "family.family_kid_friendly",
  fireplace_guards: "family.fireplace_guards",
  high_chair: "family.high_chair",
  outlet_covers: "family.outlet_covers",
  pack_n_play_travel_crib: "family.pack_n_play_travel_crib",
  stair_gates: "family.stair_gates",
  table_corner_guards: "family.table_corner_guards",
  window_guards: "family.window_guards",
};

const heatingKeys: Record<string, I18nKey> = {
  heating: "heating.heating",
  air_conditioning: "heating.airco",
  indoor_fireplace: "heating.fireplace",
};

const safetyKeys: Record<string, I18nKey> = {
  carbon_monoxide_detector: "safety.carbon_monoxide_detector",
  emergency_exit: "safety.emergency_exit",
  fire_extinguisher: "safety.fire_extinguisher",
  first_aid_kit: "safety.first_aid_kit",
  smoke_detector: "safety.smoke_detector",
};

const kitchenKeys: Record<string, I18nKey> = {
  coffee_maker: "kitchen.coffee_maker",
  dishwasher: "kitchen.dishwasher",
  microwave: "kitchen.microwave",
  oven: "kitchen.oven",
  stove: "kitchen.stove",
  toaster: "kitchen.toaster",
  cookware: "kitchen.cookware",
  dishes_and_silverware: "kitchen.dishes_and_silverware",
  kitchen: "kitchen.kitchen",
  kettle: "kitchen.kettle",
  refrigerator: "kitchen.refrigerator",
};

const locationKeys: Record<string, I18nKey> = {
  city: "location.city",
  country: "location.country",
  lake: "location.lake",
  mountain: "location.mountain",
  sea: "location.sea",
  beach: "location.beach",
  beach_front: "location.beach_front",
  beach_view: "location.beach_view",
  downtown: "location.downtown",
  golf_course_front: "location.golf_course_front",
  golf_view: "location.golf_view",
  lake_access: "location.lake_access",
  lake_front: "location.lake_front",
  mountain_view: "location.mountain_view",
  near_ocean: "location.near_ocean",
  ocean_front: "location.ocean_front",
  resort: "location.resort",
  river: "location.river",
  rural: "location.rural",
  sea_view: "location.sea_view",
  ski_in: "location.ski_in",
  ski_in_ski_out: "location.ski_in_ski_out",
  ski_out: "location.ski_out",
  town: "location.town",
  village: "location.village",
  water_view: "location.water_view",
  waterfront: "location.waterfront",
};

const logisticsKeys: Record<string, I18nKey> = {
  shuttle: "logistics.shuttle",
  storage: "logistics.storage",
};

const outdoorKeys: Record<string, I18nKey> = {
  outdoor_pool: "outdoor.outdoor_pool",
  garden_or_backyard: "outdoor.garden_or_backyard",
  bicycles_available: "outdoor.bicycles_available",
  patio_or_balcony: "outdoor.patio_or_balcony",
  bbq_grill: "outdoor.bbq_grill",
  beach_essentials: "outdoor.beach_essentials",
};

const parkingKeys: Record<string, I18nKey> = {
  elevator: "parking.elevator",
  free_parking: "parking.free_parking",
  garage: "parking.garage",
  paid_parking: "parking.paid_parking",
  private_parking: "parking.private_parking",
  free_parking_on_premises: "parking.free_parking_on_premises",
  paid_parking_off_premises: "parking.paid_parking_off_premises",
  free_parking_on_street: "parking.free_parking_on_street",
  indoor_pool: "parking.indoor_pool",
  swimming_pool: "parking.swimming_pool",
  communal_pool: "parking.communal_pool",
  ev_charger: "parking.ev_charger",
  single_level_home: "parking.single_level_home",
  private_pool: "parking.private_pool",
  gym: "parking.gym",
  hot_tub: "parking.hot_tub",
};

const internetAndOfficeKeys: Record<string, I18nKey> = {
  desk: "internet.desk",
  internet: "internet.internet",
  laptop_friendly_workspace: "internet.laptop_friendly_workspace",
  pocket_wifi: "internet.pocket_wifi",
  wireless_internet: "internet.wireless_internet",
};

const servicesKeys: Record<string, I18nKey> = {
  breakfast: "services.breakfast",
  lunch: "services.lunch",
  long_term_stays_allowed: "services.long_term_stays_allowed",
  luggage_dropoff_allowed: "services.luggage_dropoff_allowed",
  high_touch_surfaces_disinfected: "services.high_touch_surfaces_disinfected",
  enhanced_cleaning_practices: "services.enhanced_cleaning_practices",
  doorman: "services.doorman",
  cleaning_disinfection: "services.cleaning_disinfection",
  cleaning_before_checkout: "services.cleaning_before_checkout",
};

export const otherKeys: Record<string, I18nKey> = {
  ...accessibilityKeys,
  ...bathroomKeys,
  ...bedroomKeys,
  ...entertainmentKeys,
  ...familyKeys,
  ...heatingKeys,
  ...safetyKeys,
  ...kitchenKeys,
  ...locationKeys,
  ...logisticsKeys,
  ...outdoorKeys,
  ...parkingKeys,
  ...servicesKeys,
  ...internetAndOfficeKeys,
};

export const amenitiesKeys: Record<string, I18nKey> = {
  pool: "amenities.pool",
  family: "amenities.family",
  wellness: "amenities.wellness",
  homeSafety: "amenities.safety",
  bedroomAndLaundry: "amenities.bedroom",
  heatingAndCooling: "amenities.heating",
  kitchenAndDining: "amenities.kitchen",
  outdoor: "amenities.outdoor",
  parkingAndFacilities: "amenities.parking",
  bathroom: "amenities.bathroom",
  locationFeatures: "amenities.location",
  services: "amenities.services",
  entertainment: "amenities.entertainment",
  accessibilityFeatures: "amenities.accessibility",
  logistics: "amenities.logistics",
  internetAndOffice: "amenities.internet",
};

export const allKeys: Record<string, I18nKey> = {
  ...essentialsKeys,
  ...favouritesKeys,
  ...otherKeys,
  ...amenitiesKeys,
};

export const availibilityBookingWindowKeys: Record<string, I18nKey> = {
  ALL: "review_listing_availability.window.all",
  DAYS_30: "review_listing_availability.window.days_30",
  DAYS_60: "review_listing_availability.window.days_60",
  DAYS_90: "review_listing_availability.window.days_90",
  DAYS_120: "review_listing_availability.window.days_120",
  DAYS_150: "review_listing_availability.window.days_150",
  DAYS_180: "review_listing_availability.window.days_180",
  DAYS_210: "review_listing_availability.window.days_210",
  DAYS_240: "review_listing_availability.window.days_240",
  DAYS_270: "review_listing_availability.window.days_270",
  DAYS_300: "review_listing_availability.window.days_300",
  DAYS_330: "review_listing_availability.window.days_330",
  DAYS_365: "review_listing_availability.window.days_365",
};

export const advanceNoticeKeys: Record<string, I18nKey> = {
  SAME_DAY: "review_listing_availability.notice.same_day",
  DAYS_1: "review_listing_availability.notice.days_1",
  DAYS_2: "review_listing_availability.notice.days_2",
  DAYS_3: "review_listing_availability.notice.days_3",
  DAYS_7: "review_listing_availability.notice.days_7",
  NONE: "review_listing_availability.notice.none",
};
