import "./OverviewCard.scss";

import { FormattedMessage, useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";

import FilePreview from "components/@common/FileSelector/FilePreview/FilePreview";

import OverviewCard from "./OverviewCard";

interface Props {
  pictures: Array<string>;
}

const PhotosCard = ({ pictures }: Props) => {
  const intl = useIntl();

  return (
    <OverviewCard
      title="complete_listing_overview.photos.title"
      route={Routes.CompleteListingPhotos}
    >
      {pictures.length === 0 && (
        <p className="margin-0 extra-small">
          <em>
            {intl.formatMessage({ id: "complete_listing_overview.not_set" })}
          </em>
        </p>
      )}

      {pictures.length > 0 && (
        <>
          <FilePreview fileUrls={pictures} />

          <p className="margin-0 extra-small">
            <FormattedMessage
              id="complete_listing_overview.photos"
              values={{ count: pictures.length }}
            />
          </p>
        </>
      )}
    </OverviewCard>
  );
};

export default PhotosCard;
