import { CognitoErrorCode } from "services/auth/types";

export const getCognitoErrorMessageKey = (
  errorName?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { key: I18nKey; values?: Record<string, any> } => {
  if (!errorName || !Object.keys(CognitoErrorCode).includes(errorName))
    return { key: "common.error_unknown" };
  if (CognitoErrorCode.UserNotFoundException === errorName) {
    return { key: `forgot_password.cognito.error.${errorName}` };
  }
  if (CognitoErrorCode.InvalidParameterException === errorName) {
    return {
      key: `forgot_password.cognito.error.${errorName}`,
      values: {
        a: (chunk: string) => <a href="mailto:host@huswell.com">{chunk}</a>,
      },
    };
  } else {
    return { key: `cognito.error.${errorName}` as I18nKey };
  }
};
