import { FormattedMessage } from "react-intl";

const ReservationDelayDisclaimer = () => {
  return (
    <div
      style={{
        width: "fit-content",
        padding: 12,
        borderRadius: 8,
        backgroundColor: "#cbe2fe",
        color: "black",
      }}
    >
      <FormattedMessage id="reservation_detail.update_disclaimer" />
    </div>
  );
};

export default ReservationDelayDisclaimer;
