import "./rating.scss";

import { IonIcon } from "@ionic/react";

import { formatRating } from "utils/reservation.utils";

interface Props {
  rating?: number | null;
  stars?: boolean;
  max?: number;
  small?: boolean;
}

const Rating = ({
  rating = 0,
  stars = false,
  max = 5,
  small = false,
}: Props) => {
  const clampedRating = Math.min(rating || 0, max);
  const fullStars = Math.floor(clampedRating);
  const halfStar = clampedRating - fullStars >= 0.5;

  return (
    <div className="rating">
      {stars ? (
        <div className={`stars ${small ? "small" : ""}`}>
          {Array.from({ length: fullStars }, (_, i) => (
            <IonIcon
              key={`full-${i}`}
              icon="assets/icons/icon-star.svg"
              className="star full"
            />
          ))}
          {halfStar && (
            <IonIcon
              icon="assets/icons/icon-star-half.svg"
              className="star half"
            />
          )}
          {Array.from(
            { length: 5 - fullStars - (halfStar ? 1 : 0) },
            (_, i) => (
              <IonIcon
                key={`empty-${i}`}
                icon="assets/icons/icon-star-empty.svg"
              />
            ),
          )}
        </div>
      ) : (
        <div className="numeric">
          <p className="margin-0 bold">
            {rating === null ? "-" : formatRating(clampedRating)}
          </p>
          <p className="margin-0 bold divisor">/5</p>
        </div>
      )}
    </div>
  );
};

export default Rating;
