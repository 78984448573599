import "./FormIonToggle.scss";

import { IonToggle } from "@ionic/react";
import { ComponentProps, ReactNode } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

type Props = Pick<UseControllerProps, "rules" | "name"> &
  ComponentProps<typeof IonToggle> & {
    children: ReactNode;
    labelPlacement?: "start" | "end";
  };

const FormIonToggle = ({
  rules,
  name,
  children,
  labelPlacement,
  ...rest
}: Props) => {
  const { control } = useFormContext();

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({ field: { onChange, value, ...field } }) => (
        <div className="ion-toggle-container">
          <IonToggle
            labelPlacement={labelPlacement}
            checked={value}
            {...field}
            onIonChange={(e) => onChange(e.detail.checked)}
            {...rest}
          >
            <div className="ion-toggle-label">{children}</div>
          </IonToggle>
        </div>
      )}
    />
  );
};

export default FormIonToggle;
