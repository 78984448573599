import "./PasswordHints.scss";

import { IonText } from "@ionic/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import LinkUnstyled from "components/@common/LinkUnstyled";
import Modal from "components/@common/Modal";

const PasswordHints = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <FormattedMessage
        id="onboarding.signup.help"
        tagName="p"
        values={{
          a: (chunks) => (
            <LinkUnstyled onClick={() => setIsModalOpen(true)}>
              {chunks}
            </LinkUnstyled>
          ),
        }}
      />

      <Modal isOpen={isModalOpen} onDidDismiss={() => setIsModalOpen(false)}>
        <div className="title-container">
          <h3 className="margin-0">
            <FormattedMessage id="password_hints.modal.title" />
          </h3>
        </div>

        <p className="margin-24">
          <FormattedMessage id="password_hints.modal.intro" />
        </p>

        <IonText color="primary">
          <h5 className="margin-0">
            <FormattedMessage id="password_hints.modal.passphrase_title" />
          </h5>
        </IonText>

        <p className="margin-16">
          <FormattedMessage id="password_hints.modal.passphrase_intro" />
        </p>

        <p className="margin-0 example">
          <FormattedMessage id="password_hints.modal.passphrase_example" />
        </p>

        <p className="margin-16">
          <FormattedMessage id="password_hints.modal.passphrase_description" />
        </p>

        <p className="margin-16">
          <FormattedMessage id="password_hints.modal.extra_security" />
        </p>

        <p className="margin-bottom-24 example">
          <FormattedMessage id="password_hints.modal.final_example" />
        </p>

        <IonText color="primary">
          <h5>
            <FormattedMessage id="password_hints.modal.password_manager_title" />
          </h5>
        </IonText>

        <p className="margin-16 bottom-margin">
          <FormattedMessage id="password_hints.modal.password_manager_description" />
        </p>
      </Modal>
    </>
  );
};

export default PasswordHints;
