import axios from "axios";

import config from "config";

import { DEFAULT_LOCALE, languageMap } from "services/i18n/config";
import { DocumentType } from "types/document.types";

// Function to get language code from locale
const getLanguageCode = (locale: string): string =>
  (languageMap[locale] || languageMap[DEFAULT_LOCALE]).toLowerCase();

// Function to fetch page content by type and locale using Axios
const fetchPageContent = async (
  pageType: DocumentType,
  locale: string,
): Promise<string> => {
  const languageCode = getLanguageCode(locale);
  const url = `${config.S3_BUCKET}/${pageType}_${languageCode}.html`;

  try {
    const response = await axios.get(url, {
      responseType: "text", // Ensure the response is parsed as text
    });
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch ${pageType}:`, error);
    throw error;
  }
};

export default {
  getTermsAndConditionsPage: (locale: string) =>
    fetchPageContent(DocumentType.TermsAndConditions, locale),
  getPrivacyPolicyPage: (locale: string) =>
    fetchPageContent(DocumentType.PrivacyPolicy, locale),
  getRequirementsPage: (locale: string) =>
    fetchPageContent(DocumentType.Requirements, locale),
  getGraphInformationPage: (locale: string) =>
    fetchPageContent(DocumentType.GraphInformation, locale),
};
