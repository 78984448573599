import "./EmptyTable.scss";

import { IonIcon } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { TabOption } from "./types";

interface Props {
  tab: TabOption;
  // TODO: add clear filters
}

const EmptyTable = ({ tab }: Props) => {
  return (
    <div className="empty-reservations">
      <IonIcon src="assets/icons/icon-nav-reservation.svg" />

      <h6>
        <FormattedMessage id={`empty_reservations_${tab}.title`} />
      </h6>
      <p className="margin-0">
        <FormattedMessage id={`empty_reservations_${tab}.description`} />
      </p>
    </div>
  );
};

export default EmptyTable;
