import "./FormradioGroupCard.scss";

import { IonCard, IonIcon, IonRadio } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { PropertyKind, PropertyType } from "types/listing.types";

import { CardType, Option } from "../types";

interface Props {
  setSelection: (value: PropertyType | PropertyKind | string) => void;
  type: CardType;
  option: Option;
}

const iconMap: Record<string, string> = {
  // property details
  ROOM: "assets/icons/icon-door.svg",
  PLACE: "assets/icons/icon-house.svg",
  HOUSE: "assets/icons/icon-house.svg",
  APPARTMENT: "assets/icons/icon-appartment.svg",
  HOTEL: "assets/icons/icon-hotel.svg",
  OTHER: "assets/icons/icon-building-other.svg",
  FALLBACK: "assets/icons/icon-fallback.svg",
  // amenities
  "essentials.wifi": "assets/icons/icon-wifi.svg",
  "essentials.tv": "assets/icons/icon-tv.svg",
  "essentials.kitchen": "assets/icons/icon-kitchen.svg",
  "essentials.washer": "assets/icons/icon-washer.svg",
  "essentials.dryer": "assets/icons/icon-dryer.svg",
  "essentials.free_parking": "assets/icons/icon-parking.svg",
  "essentials.paid_parking": "assets/icons/icon-parking.svg",
  "essentials.private_parking": "assets/icons/icon-parking.svg",
  "essentials.airco": "assets/icons/icon-airco.svg",
  "essentials.heating": "assets/icons/icon-heating.svg",
  "favourites.pool": "assets/icons/icon-pool.svg",
  "favourites.hot_tub": "assets/icons/icon-hot-tub.svg",
  "favourites.patio": "assets/icons/icon-patio.svg",
  "favourites.bbq": "assets/icons/icon-bbq.svg",
  "favourites.pool_table": "assets/icons/icon-pool-table.svg",
  "favourites.lake_access": "assets/icons/icon-lake-access.svg",
  "favourites.beach_access": "assets/icons/icon-beach-access.svg",
  "favourites.ski_access": "assets/icons/icon-ski-access.svg",
  // ...
};

const PropertyCard = ({ type, option, setSelection }: Props) => {
  let className;
  switch (type) {
    case "PropertyKind":
      className = "radio-card-property-details kind";
      break;
    case "PropertyType":
      className = "radio-card-property-details type";
      break;
    case "Amenity":
      className = "radio-card-property-details amenity";
      break;
  }

  return (
    <IonCard
      button
      onClick={() => setSelection(option.value)}
      className={className}
    >
      <div className="radio-card-content-details">
        <div className="radio-card-container">
          <IonIcon
            icon={iconMap[option.value] ?? iconMap.FALLBACK}
            className="radio-icon"
          />

          <p className="bold margin-0">
            <FormattedMessage id={option.label} />
          </p>
        </div>
        <IonRadio
          mode="ios"
          className="radio-card-radio"
          value={option.value}
          slot="start"
          justify="space-between"
          labelPlacement="start"
        />
      </div>
    </IonCard>
  );
};

export default PropertyCard;
