import { IonPage } from "@ionic/react";

import HouseRulesComponent from "components/@complete-listing/HouseRules";

const About = () => {
  return (
    <IonPage>
      <HouseRulesComponent />
    </IonPage>
  );
};

export default About;
