import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import {
  PersistQueryClientProviderProps,
  removeOldestQuery,
} from "@tanstack/react-query-persist-client";

import { hasWindow } from "utils/browser.utils";

import { StaleTime } from "queries/types";

// Allows to easily create a query client with the right options
export const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: StaleTime.ONE_DAY, // 24 hours for persisted Cache
        staleTime: StaleTime.FIVE_MIN,
        refetchOnWindowFocus: false,
        retry: 0,
        meta: {
          // By default we don't want to dehydrate queries
          dehydrate: false,
          // By default we don't want to store queries in local storage
          localStorage: false,
        },
      },
    },
  });

const safelyFetchWindowLocalStorage = () => {
  try {
    if (hasWindow()) return window.localStorage;
    return null;
  } catch {
    return null;
  }
};

const LocalStoragePersistor = createSyncStoragePersister({
  key: "husw-cache-queries",
  storage: safelyFetchWindowLocalStorage(),
  retry: removeOldestQuery,
});

export const PersistOptions: PersistQueryClientProviderProps["persistOptions"] =
  {
    buster: `${process.env.REACT_APP_SENTRY_ENVIRONMENT}@${process.env.REACT_APP_SENTRY_RELEASE}`,
    persister: LocalStoragePersistor,
    // Keep persisted cache for 12 hours
    maxAge: 1000 * 60 * 60 * 12,
    dehydrateOptions: {
      shouldDehydrateMutation: () => false,
      shouldDehydrateQuery: ({ meta }) => !!meta?.localStorage,
    },
    hydrateOptions: {
      defaultOptions: {
        queries: {
          meta: { localStorage: true },
        },
      },
    },
  };
