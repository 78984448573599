import "./PlanCard.scss";

import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import { FormattedMessage } from "react-intl";

import { PricingPlan } from "types/listing.types";

import LoadingButton from "components/@common/LoadingButton";

import Percentage from "./Percentage";

interface Props {
  plan: PricingPlan;
  isLoading?: boolean;
  onSelect: (plan: PricingPlan) => void;
}

const stepsPerPlan = {
  [PricingPlan.Starter]: 6,
  [PricingPlan.Manual]: 6,
  [PricingPlan.Professional]: 5,
  [PricingPlan.Premium]: 5,
};

const PlanCard = ({ plan, isLoading, onSelect }: Props) => {
  return (
    <div className={`plan-card ${plan}`}>
      <div className="card-section">
        <h4>
          <FormattedMessage id={`${plan}_plan.title`} />
        </h4>
        {plan === PricingPlan.Professional && (
          <div className="plan-chip">
            <FormattedMessage id="plan.chip.label" />
          </div>
        )}

        <p className="extra-small plan-description">
          <FormattedMessage id={`${plan}_plan.description`} />
        </p>
      </div>

      <div className="card-section-button">
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Percentage
            number={15}
            sign="%"
            description="plan.percentage.description"
          />
          <Percentage
            number={49}
            sign="€"
            description="plan.cost.description"
          />
        </div>
        <LoadingButton
          shape="round"
          className={`${plan}-plan-button`}
          onClick={() => onSelect(plan)}
          isLoading={!!isLoading}
          disabled={!!isLoading}
          style={{ height: 52 }}
        >
          <FormattedMessage id="plan.button.label" />
        </LoadingButton>
      </div>

      <div className="card-section">
        <p className="card-section-title">
          <FormattedMessage id={`${plan}_plan.features.title`} />
        </p>

        {Array(stepsPerPlan[plan])
          .fill(null)
          .map((_, index) => (
            <div
              key={`${plan}-feature-${index}`}
              className="features-container"
            >
              <IonIcon
                data-testid="checkmark-icon"
                icon={checkmarkOutline}
                className="feature-icon"
              />
              <p className="extra-small margin-4">
                <FormattedMessage
                  id={`${plan}_plan.features.${index}` as I18nKey}
                />
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PlanCard;
