import { IonPage } from "@ionic/react";

import { SignUpContinue as SignUpContinueComponent } from "components/@authentication/SignUpContinue";
import LogoContainer from "components/@common/LogoContainer";

const SignUpContinue = () => {
  return (
    <IonPage>
      <LogoContainer>
        <SignUpContinueComponent />
      </LogoContainer>
    </IonPage>
  );
};

export default SignUpContinue;
