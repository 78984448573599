import { useQuery } from "@tanstack/react-query";

import { getListingSuccessManagerQuery } from "./queries";

export const useListingSuccessManager = (
  id: string,
  options?: Partial<ReturnType<typeof getListingSuccessManagerQuery>>,
) => {
  const { data, isLoading, error, isError } = useQuery({
    ...getListingSuccessManagerQuery(id),
    ...options,
  });
  return {
    data: data?.data,
    error,
    isLoading,
    isError,
  };
};
