import { IonPage } from "@ionic/react";

import AddressComponent from "components/@create-listing/Address";

const Address = () => {
  return (
    <IonPage>
      <AddressComponent />
    </IonPage>
  );
};

export default Address;
