import { IonInput } from "@ionic/react";
import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useUpdateListing } from "queries";
import { ListingResponse } from "types/listing.types";

import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";

interface Props {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
}

const Guests = ({ listing, isReadOnly }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [guests, setGuests] = useState(listing.attributes.maximumOccupancy);

  const { updateListing, isLoading } = useUpdateListing();

  const handleAction = () => {
    if (isEditing) {
      setGuests(listing.attributes.maximumOccupancy);
    }

    setIsEditing((isEditing) => !isEditing);
  };

  const handleSubmit = () => {
    updateListing(
      { id: listing.id, attributes: { maximumOccupancy: guests } },
      {
        onSuccess: () => setIsEditing(false),
        onError: (error) => {
          captureMessage(
            "Error updating max occupancy (guests) in listing detail",
            { level: "error", extra: { error } },
          );
        },
      },
    );
  };

  return (
    <ItemListRow
      title="listing_detail.details.max_guests"
      actionKey={isEditing ? "button.cancel" : "common.edit"}
      isLoading={isLoading}
      onActionClick={isReadOnly ? undefined : handleAction}
    >
      {isEditing ? (
        <div>
          <IonInput
            value={guests}
            fill="solid"
            type="number"
            onIonChange={(e) =>
              setGuests(Number((e.target as HTMLIonInputElement).value))
            }
          />
          <LoadingButton
            isLoading={isLoading}
            shape="round"
            style={{ height: 48, marginTop: 12 }}
            onClick={handleSubmit}
          >
            <FormattedMessage id="common.save" />
          </LoadingButton>
        </div>
      ) : (
        <p className="margin-0">{guests}</p>
      )}
    </ItemListRow>
  );
};

export default Guests;
