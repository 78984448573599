import { useMutation } from "@tanstack/react-query";

import { apiService } from "services";
import { BlockAttributes } from "types/reservation.types";

export const useCreateBlock = () => {
  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: ({
      listingId,
      blockAttributes,
    }: {
      listingId: string;
      blockAttributes: BlockAttributes;
    }) => apiService.createBlock(listingId, blockAttributes),
  });

  return {
    createblock: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
