import { create, StateCreator } from "zustand";
import { persist } from "zustand/middleware";

import { LoginProvider } from "services/auth/types";

type AuthValues = Partial<{
  email: string;
  userName: string;
  loginProvider: LoginProvider;
  otp: string;
}>;

export interface AuthSlice {
  currentStep: number;
  actions: {
    setValues(values?: AuthValues): void;
    clearStorage: () => void;
  };
  values?: AuthValues;
}

const createAuthSlice: StateCreator<AuthSlice> = (set) => ({
  currentStep: 0,
  actions: {
    setValues: (values) =>
      set((state) => ({ values: { ...state.values, ...values } })),
    clearStorage: () => {
      localStorage.removeItem("multistep-sign-in-store");
      set({
        currentStep: 0,
        values: {},
      });
    },
  },
});

export const useAuthStore = create(
  persist(createAuthSlice, {
    name: "auth-store",
    getStorage: () => localStorage,
    partialize: (state) => ({
      currentStep: state.currentStep,
      values: state.values,
    }),
  }),
);
