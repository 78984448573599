import "./LogoContainer.scss";

import {
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonRow,
  IonToolbar,
  useIonRouter,
} from "@ionic/react";
import swirl from "images/swirl 1.png";
import logo from "images/Vector.svg";
import { ReactNode } from "react";

import { isAndroid, isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import LanguageSelect from "../LanguageSelect";

interface Props {
  children: ReactNode;
}

const LogoContainer = ({ children }: Props) => {
  const router = useIonRouter();
  return (
    <>
      <IonHeader className="logo-header">
        <IonToolbar
          className={`ion-hide-md-up logo-container ${isAndroid && "android"}`}
          style={{ minHeight: isNative ? 110 : 68 }}
        >
          <IonButtons slot="start">
            <button
              data-testid="huswell-logo-button-mobile"
              className="huswell-logo-button-mobile"
              onClick={() => router.push(Routes.SignInContinue, "back")}
            >
              <IonIcon id="header-logo" icon={logo} />
            </button>
          </IonButtons>
          <IonButtons slot="end">
            <div className="language-select-sm ion-hide-md-up">
              <LanguageSelect />
            </div>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid class="ion-grid">
          {/* <IonRow
            class="ion-hide-md-up logo-container"
            style={{ minHeight: isNative ? 110 : 84 }}
          >
            <button
              data-testid="huswell-logo-button-mobile"
              className="huswell-logo-button-mobile"
              onClick={() => router.push(Routes.SignInContinue, "back")}
            >
              <IonIcon id="header-logo" icon={logo} />
            </button>
            <div className="language-select-sm ion-hide-md-up">
              <LanguageSelect />
            </div>
          </IonRow> */}

          <IonRow class="ion-row">
            <IonCol
              sizeMd="5"
              sizeXl="6"
              class="ion-col ion-hide-md-down center special-gradient"
            >
              <IonImg
                // TODO: use huswell vector images
                src={swirl}
              />
              <button
                data-testid="huswell-logo-button-desktop"
                className="huswell-logo-button-desktop"
                onClick={() => router.push(Routes.SignInContinue, "back")}
              >
                <IonIcon icon={logo} id="huswell-logo" />
              </button>
            </IonCol>
            <IonCol
              class="ion-col dynamic-col"
              size="12"
              sizeMd="7"
              sizeXl="6"
              sizeXs="12"
            >
              {children}
            </IonCol>

            <div className="language-select-md ion-hide-md-down">
              <LanguageSelect />
            </div>
          </IonRow>
        </IonGrid>
      </IonContent>
    </>
  );
};

export default LogoContainer;
