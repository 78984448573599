import "./TopNav.scss";

import { IonContent, IonIcon, IonLabel, IonPopover } from "@ionic/react";
import { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

import { supportLinksMap } from "constants/menu.constants";

import MenuItem from "components/@common/MenuItem";

const DesktopMenu = () => {
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: unknown) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  return (
    <>
      <div
        onClick={openPopover}
        className={`router-link ${popoverOpen ? "active" : ""}`}
      >
        <IonIcon icon="assets/icons/icon-nav-menu.svg" />
        <IonLabel>
          <FormattedMessage id="mobile_menu" />
        </IonLabel>
      </div>

      <IonPopover
        keepContentsMounted
        ref={popover}
        isOpen={popoverOpen}
        onDidDismiss={() => setPopoverOpen(false)}
      >
        <IonContent>
          <div className="menu-links-container">
            <p className="bold margin-bottom-8">
              <FormattedMessage id="mobile_menu_support.title" />
            </p>
            {supportLinksMap.map((link, index) => (
              <MenuItem
                key={index}
                onClick={() => window.open(link.href, "_blank")}
                {...link}
              />
            ))}
          </div>
        </IonContent>
      </IonPopover>
    </>
  );
};

export default DesktopMenu;
