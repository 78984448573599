import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { ListingResponse } from "types/listing.types";

import { defaultHouseRules } from "components/@complete-listing/constants";

import { getListingQuery } from "./queries";
import { formatHouseRulesFromApi } from "./utils";

export const useListing = (
  id: string,
  options?: Partial<ReturnType<typeof getListingQuery>>,
) => {
  const { data, isLoading, error, isError } = useQuery({
    ...getListingQuery(id),
    ...options,
  });

  const listing = useMemo(() => {
    return (
      !!data?.data.data
        ? {
            ...data.data.data,
            attributes: {
              ...data.data.data.attributes,
              houseRules: !!data.data?.data?.attributes?.houseRules
                ? formatHouseRulesFromApi(data.data.data.attributes.houseRules)
                : defaultHouseRules,
            },
          }
        : data?.data.data
    ) as ListingResponse["data"];
  }, [data?.data.data]);

  return {
    data: listing,
    error,
    isLoading,
    isError,
  };
};
