import { FormattedMessage, useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";

import OverviewCard from "./OverviewCard";

interface Props {
  title?: string;
  neighborhood?: string;
  region?: string | null;
  unit?: string | null;
  appartment?: string | null;
  floorNumber?: number | null;
  floorAmount?: number | null;
}

const AboutCard = ({
  title,
  neighborhood,
  region,
  unit,
  appartment,
  floorNumber,
  floorAmount,
}: Props) => {
  const intl = useIntl();
  const isAnyFieldSet = [
    title,
    neighborhood,
    region,
    unit,
    appartment,
    floorNumber,
    floorAmount,
  ].some(Boolean);

  return (
    <OverviewCard
      title="complete_listing_overview.about.title"
      route={Routes.CompleteListingAbout}
    >
      {!isAnyFieldSet && (
        <p className="margin-0 extra-small">
          <em>
            {intl.formatMessage({ id: "complete_listing_overview.not_set" })}
          </em>
        </p>
      )}

      {title && (
        <p className="margin-0 extra-small">
          <FormattedMessage
            id="complete_listing_overview.nickname"
            values={{ title: <b>{title}</b> }}
          />
        </p>
      )}

      {neighborhood && (
        <p className="margin-0 extra-small">
          <FormattedMessage
            id="complete_listing_overview.neighborhood"
            values={{ neighborhood: <b>{neighborhood}</b> }}
          />
        </p>
      )}

      {region && (
        <p className="margin-0 extra-small">
          <FormattedMessage
            id="complete_listing_overview.region"
            values={{ region: <b>{region}</b> }}
          />
        </p>
      )}

      {unit && (
        <p className="margin-0 extra-small">
          <FormattedMessage
            id="complete_listing_overview.unit"
            values={{ unit: <b>{unit}</b> }}
          />
        </p>
      )}

      {appartment && (
        <p className="margin-0 extra-small">
          <FormattedMessage
            id="complete_listing_overview.apartment"
            values={{
              apartment: <b>{appartment}</b>,
            }}
          />
        </p>
      )}

      {floorNumber && (
        <p className="margin-0 extra-small">
          <FormattedMessage
            id="complete_listing_overview.floor_number"
            values={{
              floorNumber: <b>{floorNumber}</b>,
            }}
          />
        </p>
      )}

      {floorAmount && (
        <p className="margin-0 extra-small">
          <FormattedMessage
            id="complete_listing_overview.floor_amount"
            values={{
              floorAmount: <b>{floorAmount}</b>,
            }}
          />
        </p>
      )}
    </OverviewCard>
  );
};

export default AboutCard;
