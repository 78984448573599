export enum SignUpStatus {
  Idle = "idle",
  SigningUp = "signingUp",
  SigningIn = "signingIn",
  Redirecting = "redirecting",
}

export const StatusMessageIdLookup: Record<SignUpStatus, I18nKey | null> = {
  [SignUpStatus.Idle]: null,
  [SignUpStatus.SigningUp]: "signing_up",
  [SignUpStatus.SigningIn]: "signing_in",
  [SignUpStatus.Redirecting]: "redirecting",
};
