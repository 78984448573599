import { IonPage } from "@ionic/react";

import AmenitiesComponent from "components/@complete-listing/Amenities";

const Amenities = () => {
  return (
    <IonPage>
      <AmenitiesComponent />
    </IonPage>
  );
};

export default Amenities;
