import "./ReservationDetailContent.scss";

import { IonContent, IonIcon, IonPopover } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { PublicReview as PublicReviewType } from "types/reservation.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import PublicReview from "components/@common/PublicReview/PublicReview";

import { formatCurrency, formatDateMMMddyyyyHHmm } from "../utils";

interface Props {
  revenue?: number;
  rentalRevenue?: number;
  bookingReference?: string;
  checkInDate?: string;
  checkOutDate?: string;
  numberOfNights?: number;
  otaName?: string;
  mainGuestName?: string;
  numberOfGuests?: number;
  email?: string;
  phoneNumber?: string;
  publicReview?: PublicReviewType;
}

const ReservationDetailContent = ({
  rentalRevenue,
  revenue,
  bookingReference,
  checkInDate,
  checkOutDate,
  numberOfNights,
  otaName,
  mainGuestName,
  numberOfGuests,
  email,
  phoneNumber,
  publicReview,
}: Props) => {
  return (
    <div className="reservation-detail-content">
      {(publicReview?.isPositiveScore || publicReview?.isPositiveScore) && (
        <>
          <h6 className="margin-0">
            <FormattedMessage id="reservation_detail.satisfaction_title" />
          </h6>
          <PublicReview publicReview={publicReview} />
        </>
      )}

      {(!!rentalRevenue || !!revenue) && (
        <>
          <h6 className="margin-0">
            <FormattedMessage id="reservation_detail.revenue_title" />
          </h6>

          <div className="info-block">
            <ItemList>
              {rentalRevenue && (
                <ItemListRow>
                  <div className="revenue-row">
                    <div className="revenue-row-title">
                      <p className="margin-0 bold">
                        <FormattedMessage id="reservation_detail.revenue.rental_income" />
                      </p>
                      <IonIcon
                        className="info-icon"
                        src="assets/icons/icon-info-circle.svg"
                        id="hover-trigger-rental-income"
                      />

                      <IonPopover
                        side="bottom"
                        alignment="center"
                        keepContentsMounted
                        trigger="hover-trigger-rental-income"
                        triggerAction="hover"
                      >
                        <IonContent>
                          <div className="tooltip-container">
                            <p className="margin-0">
                              <FormattedMessage id="reservation_detail.rental_income.tooltip" />
                            </p>
                          </div>
                        </IonContent>
                      </IonPopover>
                    </div>

                    <p className="margin-0 extra-small">
                      <FormattedMessage id="reservation_detail.revenue.rental_income_description" />
                    </p>
                  </div>

                  <p className="margin-0 bold">
                    {formatCurrency(rentalRevenue)}
                  </p>
                </ItemListRow>
              )}

              {revenue && (
                <ItemListRow>
                  <div className="revenue-row">
                    <div className="revenue-row-title">
                      <p className="margin-0 bold">
                        <FormattedMessage id="reservation_detail.revenue.owner_income" />
                      </p>
                      <IonIcon
                        className="info-icon"
                        src="assets/icons/icon-info-circle.svg"
                        id="hover-trigger-owner-income"
                      />

                      <IonPopover
                        side="bottom"
                        alignment="center"
                        keepContentsMounted
                        trigger="hover-trigger-owner-income"
                        triggerAction="hover"
                      >
                        <IonContent>
                          <div className="tooltip-container">
                            <p className="margin-0">
                              <FormattedMessage id="reservation_detail.owner_income.tooltip" />
                            </p>
                          </div>
                        </IonContent>
                      </IonPopover>
                    </div>

                    <p className="margin-0 extra-small">
                      <FormattedMessage id="reservation_detail.revenue.owner_income_description" />
                    </p>
                  </div>

                  <p className="margin-0 bold">{formatCurrency(revenue)}</p>
                </ItemListRow>
              )}
            </ItemList>
          </div>
        </>
      )}
      {(bookingReference ||
        checkInDate ||
        checkOutDate ||
        numberOfNights ||
        otaName) && (
        <>
          <h6 className="margin-0">
            <FormattedMessage id="reservation_detail.reservation_title" />
          </h6>

          <div className="info-block">
            <ItemList>
              {bookingReference && (
                <ItemListRow title="reservation_detail.booking_reference">
                  <p className="margin-0">{bookingReference}</p>
                </ItemListRow>
              )}

              {checkInDate && (
                <ItemListRow title="reservation_detail.check_in">
                  <p className="margin-0">
                    {formatDateMMMddyyyyHHmm(checkInDate)}
                  </p>
                </ItemListRow>
              )}

              {checkOutDate && (
                <ItemListRow title="reservation_detail.check_out">
                  <p className="margin-0">
                    {formatDateMMMddyyyyHHmm(checkOutDate)}
                  </p>
                </ItemListRow>
              )}

              {numberOfNights && (
                <ItemListRow title="reservation_detail.nights">
                  <p className="margin-0">{numberOfNights}</p>
                </ItemListRow>
              )}

              {otaName && (
                <ItemListRow title="reservation_detail.ota">
                  <p className="margin-0">{otaName}</p>
                </ItemListRow>
              )}
            </ItemList>
          </div>
        </>
      )}
      {((mainGuestName && numberOfGuests) || email || phoneNumber) && (
        <>
          <h6 className="margin-0">
            <FormattedMessage id="reservation_detail.guest_title" />
          </h6>

          <div className="info-block">
            <ItemList>
              {mainGuestName && numberOfGuests && (
                <ItemListRow>
                  <div className="guests-item">
                    <p className="margin-0 bold">
                      <FormattedMessage
                        id="reservation_detail.guests"
                        values={{ count: numberOfGuests }}
                      />
                    </p>
                    <p className="margin-0">
                      <FormattedMessage
                        id="reservation_detail.guests_names"
                        values={{ count: numberOfGuests - 1, mainGuestName }}
                      />
                    </p>
                  </div>
                </ItemListRow>
              )}

              {email && (
                <ItemListRow title="reservation_detail.email">
                  <p className="margin-0">{email}</p>
                </ItemListRow>
              )}

              {phoneNumber && (
                <ItemListRow title="reservation_detail.phone">
                  <p className="margin-0">{phoneNumber}</p>
                </ItemListRow>
              )}
            </ItemList>
          </div>
        </>
      )}
    </div>
  );
};

export default ReservationDetailContent;
