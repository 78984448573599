import { Routes } from "constants/routes.constants";
import { useUser } from "queries";
import { Route } from "types/route.types";

export const tabs: Array<{
  icon: string;
  label: I18nKey;
  route: Route;
  disabled?: boolean;
}> = [
  {
    icon: "assets/icons/icon-nav-listing.svg",
    label: "nav.listings",
    route: Routes.Listings,
  },
  {
    icon: "assets/icons/icon-nav-calendar.svg",
    label: "nav.calendar",
    route: Routes.Calendar,
  },
  {
    icon: "assets/icons/icon-nav-reservation.svg",
    label: "nav.reservations",
    route: Routes.Reservations,
  },
];

export const mobileTabs: Array<{
  icon: string;
  label: I18nKey;
  route: Route;
  disabled?: boolean;
}> = [
  ...tabs,
  {
    icon: "assets/icons/icon-nav-menu.svg",
    label: "nav.menu",
    route: Routes.MobileMenu,
  },
];

export const useTabs = (type: "mobile" | "desktop") => {
  const { data: user } = useUser();

  const hasListings = (user?.data.relationships.listings.data.length || 0) > 0;

  return (type === "mobile" ? mobileTabs : tabs).map((route) =>
    ([Routes.Calendar, Routes.Reservations] as Array<Route>).includes(
      route.route,
    ) && !hasListings
      ? { ...route, disabled: true }
      : route,
  );
};
