import { IonPage } from "@ionic/react";

import AvailabilityComponent from "components/@review-listing/Availability";

const Availability = () => {
  return (
    <IonPage>
      <AvailabilityComponent />
    </IonPage>
  );
};

export default Availability;
