import { IonContent } from "@ionic/react";
import { useRef } from "react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import CalendarContainer from "components/@calendar/CalendarContainer/CalendarContainer";
import { IOSSafeArea } from "components/@common/IOSSafeArea";
import { PullToRefresh, RefreshHandle } from "components/@common/PullToRefresh";

const Calendar = () => {
  useAndroidStatusBar({ iconsColor: "dark" });
  const calendarRef = useRef<RefreshHandle>(null);
  return (
    <>
      <IOSSafeArea />
      <IonContent>
        <PullToRefresh dataRef={calendarRef} />
        <div className="ion-tab-page android-top-padding">
          <CalendarContainer ref={calendarRef} />
        </div>
      </IonContent>
    </>
  );
};

export default Calendar;
