import { PushNotifications } from "@capacitor/push-notifications";

const useRegisterNotifications = () => {
  const registerNotifications = async () => {
    const permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === "prompt") {
      await PushNotifications.requestPermissions().then((status) => {
        if (status.receive === "granted") {
          PushNotifications.register();
        }
      });
    }

    if (permStatus.receive !== "granted") {
      throw new Error("User denied permissions!");
    }

    await PushNotifications.register();
  };

  return {
    registerNotifications,
  };
};

export default useRegisterNotifications;
