import { useMemo } from "react";

import { useListing, useOtas } from "queries";

import { getListingId } from "../utils";

export const useListingPlatforms = () => {
  const listingId = getListingId();

  const { data, isLoading, error } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });
  const { data: otas, isLoading: isLoadingOtas, error: errorOtas } = useOtas();

  const listingOtas = useMemo(() => {
    return data?.relationships.otas.data || [];
  }, [data]);

  const listingOtasSet = useMemo(
    () => new Set(listingOtas.map((ota) => ota.id)),
    [listingOtas],
  );

  const huswellPlatform = useMemo(() => {
    return listingOtas.find((ota) => ota.attributes.default === true);
  }, [listingOtas]);

  // Difference between all otas and listing otas
  const inactivePlatforms = useMemo(() => {
    return otas?.filter((ota) => !listingOtasSet.has(ota.id)) || [];
  }, [otas, listingOtasSet]);

  return {
    huswellPlatform,
    activePlatforms: listingOtas,
    inactivePlatforms,
    isLoading: isLoading || isLoadingOtas,
    error: error || errorOtas,
    listingData: data,
  };
};
