import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { useUpdateListing } from "queries";
import { HouseRule, ListingResponse } from "types/listing.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import { defaultHouseRules } from "components/@complete-listing/constants";
import HouseRulesForm from "components/@complete-listing/HouseRules/HouseRulesForm";

interface Props {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
}

const HouseRules = ({ listing, isReadOnly }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const { updateListing, isLoading, error } = useUpdateListing();

  // needed for existing listings from the old platform
  const areHouseRulesValid = listing.attributes.houseRules?.find(
    (houseRule) => houseRule.name === "suitable_for_children",
  );

  const methods = useForm<{
    houseRules: Array<HouseRule>;
  }>({
    defaultValues: {
      houseRules: areHouseRulesValid
        ? listing.attributes.houseRules
        : defaultHouseRules,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const onSubmit = ({ houseRules }: { houseRules: Array<HouseRule> }) => {
    updateListing(
      {
        id: listing.id,
        attributes: { houseRules },
      },
      {
        onSuccess: () => {
          methods.reset(methods.getValues());
          setIsEditing(false);
        },
        onError: (error) => {
          captureMessage("Error updating house rules in listing detail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const { houseRules } = methods.getValues();

  const suitableForChildren = houseRules.find(
    (r) => r.name === "suitable_for_children",
  )?.value;
  const suitableForInfants = houseRules.find(
    (r) => r.name === "suitable_for_infants",
  )?.value;
  const petsAllowed = houseRules.find((r) => r.name === "pets_allowed")?.value;
  const smokingAllowed = houseRules.find(
    (r) => r.name === "smoking_allowed",
  )?.value;
  const partiesAllowed = houseRules.find(
    (r) => r.name === "events_allowed",
  )?.value;
  const additionalRules = houseRules.find(
    (r) => r.name === "additional_rules",
  )?.value;
  const quietTimeStart = houseRules.find(
    (r) => r.name === "quite_time_start",
  )?.value;
  const quietTimeEnd = houseRules.find(
    (r) => r.name === "quite_time_end",
  )?.value;

  return (
    <div className="listing-detail-block">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="subtitle">
          <FormattedMessage id="complete_listing_overview.house_rules.title" />
        </p>
        {!isReadOnly && (
          <LinkUnstyled onClick={() => setIsEditing((isEditing) => !isEditing)}>
            <FormattedMessage
              id={isEditing ? "button.cancel" : "common.edit"}
            />
          </LinkUnstyled>
        )}
      </div>

      <ItemList>
        {isEditing ? (
          <FormProvider {...methods}>
            <HouseRulesForm error={error} onSubmit={onSubmit} />

            <LoadingButton
              isLoading={isLoading}
              shape="round"
              style={{ height: 48, marginTop: 36 }}
              type="submit"
              form="hook-form-rules"
            >
              <FormattedMessage id="common.save" />
            </LoadingButton>
          </FormProvider>
        ) : (
          <>
            <ItemListRow title="complete_listing_house_rules.child_policy">
              <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                <span>
                  <FormattedMessage
                    values={{ strong: (message) => <strong>{message}</strong> }}
                    id={
                      suitableForChildren
                        ? "complete_listing_house_rules.children"
                        : "complete_listing_house_rules.children.not_suitable"
                    }
                  />
                </span>
                <span>
                  <FormattedMessage
                    values={{ strong: (message) => <strong>{message}</strong> }}
                    id={
                      suitableForInfants
                        ? "complete_listing_house_rules.infants"
                        : "complete_listing_house_rules.infants.not_suitable"
                    }
                  />
                </span>
              </div>
            </ItemListRow>
            <ItemListRow title="listing_detail.details.house_rules.pets">
              <p className="margin-0"> {petsAllowed ? allowed : notAllowed}</p>
            </ItemListRow>
            <ItemListRow title="listing_detail.details.house_rules.smoking">
              <p className="margin-0">
                {smokingAllowed ? allowed : notAllowed}{" "}
              </p>
            </ItemListRow>
            <ItemListRow title="listing_detail.details.house_rules.parties">
              <p className="margin-0">
                {partiesAllowed ? allowed : notAllowed}{" "}
              </p>
            </ItemListRow>
            <ItemListRow title="listing_detail.details.house_rules.quiet_time">
              <p className="margin-0">
                {quietTimeStart} - {quietTimeEnd}{" "}
              </p>
            </ItemListRow>
            {additionalRules && (
              <ItemListRow title="complete_listing_house_rules.additional_rules">
                <p className="margin-0"> {additionalRules} </p>
              </ItemListRow>
            )}
          </>
        )}
      </ItemList>
    </div>
  );
};

const allowed = (
  <FormattedMessage id="listing_detail.details.house_rules.allowed" />
);
const notAllowed = (
  <FormattedMessage id="listing_detail.details.house_rules.not_allowed" />
);

export default HouseRules;
