import { IonContent } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { IOSSafeArea } from "components/@common/IOSSafeArea";
import MobileMenuComponent from "components/@mobile-menu/MobileMenu/MobileMenu";

const MobileMenu = () => {
  useAndroidStatusBar({ iconsColor: "dark" });
  return (
    <>
      <IOSSafeArea />
      <IonContent>
        <MobileMenuComponent />
      </IonContent>
    </>
  );
};

export default MobileMenu;
