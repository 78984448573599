import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Redirect, Route } from "react-router";

import { isAndroid } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

import Calendar from "pages/calendar/Calendar";
import ListingTable from "pages/listings/ListingTable";
import MobileMenu from "pages/mobile-menu/MobileMenu";
import Preferences from "pages/preferences/Preferences";
import Reservations from "pages/reservations/Reservations";

import { useTabs } from "./tabs";

type Props = {
  showNavigation: boolean;
};

const TabsNavigation = ({ showNavigation }: Props) => {
  const tabs = useTabs("mobile");

  // Hide the footer when the view is about to leave
  // Fixes ionic bug where the footer is still briefly visible when navigating to another page
  const [isHidden, setIsHidden] = useState(false);
  useIonViewWillLeave(() => {
    setIsHidden(true);
  });
  useIonViewWillEnter(() => {
    setIsHidden(false);
  });

  return (
    <IonTabs>
      <IonRouterOutlet animated={false}>
        <Route exact path={Routes.Listings} render={() => <ListingTable />} />
        <Route exact path={Routes.Calendar} render={() => <Calendar />} />
        <Route
          exact
          path={Routes.Reservations}
          render={() => <Reservations />}
        />
        <Route exact path={Routes.MobileMenu} render={() => <MobileMenu />} />
        <Route exact path={Routes.Preferences} component={Preferences} />
        <Redirect exact path="/tab" to={Routes.Listings} />
      </IonRouterOutlet>
      <IonTabBar
        slot="bottom"
        className={isAndroid ? "tab-bar-android" : ""}
        style={{ display: showNavigation && !isHidden ? "" : "none" }}
      >
        {tabs.map(({ icon, label, route, disabled }) => (
          <IonTabButton
            key={route}
            tab={route}
            href={route}
            disabled={disabled}
          >
            <IonIcon src={icon} />
            <IonLabel>
              <FormattedMessage id={label} />
            </IonLabel>
          </IonTabButton>
        ))}
      </IonTabBar>
    </IonTabs>
  );
};

export default TabsNavigation;
