export type Reservation = {
  id: string;
  type: "reservations";
  attributes: {
    isOwnerReservation: boolean;
    isVerified: boolean;
    mainGuestName: string;
    checkInDate: string;
    checkOutDate: string;
    numberOfNights: number;
    numberOfGuests: number;
    confirmationCode: string;
    createdDate: string;
    confirmedDate: string;
    canceledDate: string;
    ownerRevenue: number;
    rentalRevenue: number;
    overallScore: number | null;
    status: string;
    otaName: string;
  };
  relationships: {
    listing: {
      id: string;
      title: string;
      nickname: string;
    };
    ota: {
      id: string;
      name: string;
      otaName: string;
      logoUrl: string;
      premium: boolean;
      default: boolean;
    };
  };
};

export type Meta = {
  total: number;
  pageNumber: number;
  limit: number;
};

export type ReservationsResponse = {
  data: Array<Reservation>;
  meta: Meta;
};

type DuveReview = {
  cleaningScore: number;
  propertyScore: number;
  staffScore: number;
  overallScore: number;
  review: string;
  propertyIssues: string;
};

enum ReviewReplyStatus {
  COMPLETED = "COMPLETED",
  FAILING = "FAILING",
  PENDING = "PENDING",
}

type ReviewReply = {
  reply: string | null;
  status: ReviewReplyStatus;
  replyAt: string;
};

export type AirBnbReview = {
  otaReviewId: string;
  overallRating: number;
  cleanlinessScore: number;
  locationScore: number;
  valueScore: number;

  publicReview: string;
  privateFeedback: string;
  communicationScore: number;
  checkinScore: number;
  accuracyScore: number;
};

export type BookingComReview = {
  otaReviewId: string;
  overallRating: number;
  cleanlinessScore: number;
  locationScore: number;
  valueScore: number;

  facilitiesScore: number;
  positiveFeedback: string | null;
  negativeFeedback: string | null;
  comfortScore: number;
  staffScore: number;
  reviewer: string | null;
};

export type PublicReview = {
  score: number;
  isPositiveScore: boolean;
  replies: Array<ReviewReply>;
  rawReview: AirBnbReview | BookingComReview;
};

export type GuestyReservation = {
  data: {
    id: string;
    type: "reservations";
    attributes: {
      checkInDate: string;
      checkOutDate: string;
      isVerified: boolean;
      revenue: number;
      rentalRevenue?: number;
      confirmationCode: string;
      numberOfNights: number;
      otaName: string;
      status: string;
      guests: {
        mainGuestName: string;
        mainGuestEmail: string;
        mainGuestPhone: string;
        phoneVerified: boolean;
        emailVerified: boolean;
        identityVerified: boolean;
        requiredDocumentsUploaded: boolean;
        nationality: string;
        preferredLanguage: string;
        adults: number;
        children: number;
        babies: number;
      };
      isOwnerReservation: boolean;
      confirmedDate: string;
      numberOfGuests: number;
      listingId: string;
      checkinCompleted: boolean;
      guestyVersion: string;
      checkoutCompleted: boolean;
      duveReview: DuveReview;
      publicReview: PublicReview;
      createdDate: string;
    };
    relationships: {
      listing: {
        id: string;
        title: string;
        nickname: string;
      };
      ota: {
        id: string;
        name: string;
        otaName: string;
        logoUrl: string;
        premium: boolean;
        default: boolean;
      };
    };
  };
};

export type GuestyBlock = {
  data: {
    attributes: {
      startDate: string;
      endDate: string;
      note: string | null;
      isOwnerBlock: boolean;
    };
  };
};

export type ReservationAttributes = {
  checkInDate: string;
  checkOutDate: string;
  cleaningRequired: boolean;
  numberOfGuests: number;
};

export type BlockAttributes = {
  startDate: string;
  endDate: string;
  note: string;
  reasonId: string;
};

export type ReservationResponse = {
  data: {
    id: string;
    type: string;
  };
};

export enum ReservationType {
  Myself = "Myself",
  Block = "Block",
}

export type CreateReservationProviderValues = {
  listingId?: string;
  reservationId?: string;
  reservationType: ReservationType | null;
  checkInDate: string;
  checkOutDate: string;
  numberOfGuests: number;
  note?: string | null;
  reasonId?: string | null;
};
