import "./StepsOverview.scss";

import { FormattedMessage } from "react-intl";

import { OverviewStep } from "types/listing.types";

interface StepInfo {
  number: number;
  title: I18nKey;
  description: I18nKey;
}

const stepDetails: Record<OverviewStep, StepInfo> = {
  [OverviewStep.Creation]: {
    number: 1,
    title: "step_overview.creation.title",
    description: "step_overview.creation.description",
  },
  [OverviewStep.Details]: {
    number: 2,
    title: "step_overview.details.title",
    description: "step_overview.details.description",
  },
  [OverviewStep.Setup]: {
    number: 3,
    title: "step_overview.set_up.title",
    description: "step_overview.set_up.description",
  },
  [OverviewStep.Review]: {
    number: 4,
    title: "step_overview.review.title",
    description: "step_overview.review.description",
  },
  [OverviewStep.Publish]: {
    number: 5,
    title: "step_overview.publish.title",
    description: "step_overview.publish.description",
  },
};

interface Props {
  highlightedStep: OverviewStep;
}

const StepsOverview = ({ highlightedStep }: Props) => {
  return (
    <div className="steps-overview">
      {Object.values(OverviewStep).map((step) => {
        const { number, title, description } = stepDetails[step];
        const isHighlighted = step === highlightedStep;

        return (
          <div key={step} className="step-item">
            <span
              className={`step-number ${isHighlighted ? "highlighted" : ""}`}
            >
              {number}
            </span>

            <div className="step-text-container">
              <p className="step-title">
                <FormattedMessage id={title} />
              </p>
              <p className="step-description">
                <FormattedMessage id={description} />
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default StepsOverview;
