import "./Preferences.scss";

import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";
import { useUser } from "queries";

import AppVersion from "components/@common/AppVersion";
import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LanguageSelect from "components/@common/LanguageSelect";

const Preferences = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const { data: user } = useUser();

  const onRequestData = () => {
    window.open(
      intl.formatMessage({ id: "preferences.privacy.mailto" }),
      "_blank",
    );
  };
  const onRequestDeactivation = () => {
    window.open(
      intl.formatMessage({ id: "preferences.deactivate.mailto" }),
      "_blank",
    );
  };

  return (
    <div className="preferences-wrapper android-top-padding">
      <div
        style={{
          maxWidth: 760,
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
          <div className="preferences-header">
            <IonButton
              shape="round"
              className="close-button"
              onClick={() => router.push(Routes.MobileMenu, "back", "pop")}
            >
              <IonIcon
                className="close-icon"
                icon="assets/icons/icon-arrow-back.svg"
              />
            </IonButton>
            <p
              className="margin-0"
              style={{
                opacity: 0.5,
                fontWeight: 700,
                color: "#241261",
              }}
            >
              {user?.data.attributes.firstName} {user?.data.attributes.lastName}
            </p>
            <h3>
              <FormattedMessage id="mobile_menu_account.preferences" />
            </h3>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <h6>
              <FormattedMessage id="preferences.language" />
            </h6>
            <ItemList>
              <ItemListRow title="preferences.language">
                <LanguageSelect />
              </ItemListRow>
            </ItemList>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
            <h6>
              <FormattedMessage id="preferences.privacy" />
            </h6>
            <ItemList>
              <ItemListRow
                title="preferences.privacy.title"
                actionKey="preferences.request"
                onActionClick={onRequestData}
              >
                <FormattedMessage id="preferences.privacy.description" />
              </ItemListRow>
            </ItemList>
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", gap: 24 }}
            className="request-deactivation"
          >
            <h6>
              <FormattedMessage id="preferences.account" />
            </h6>
            <ItemList>
              <ItemListRow
                title="preferences.account.title"
                actionKey="preferences.request"
                onActionClick={onRequestDeactivation}
              >
                <FormattedMessage id="preferences.account.description" />
              </ItemListRow>
            </ItemList>
          </div>

          <AppVersion />
        </div>
      </div>
    </div>
  );
};

export default Preferences;
