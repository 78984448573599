import { useQuery } from "@tanstack/react-query";

import { useIntlStore } from "store";

import { requirementsQuery } from "./queries";

export const useRequirements = () => {
  const locale = useIntlStore((state) => state.locale);
  const { data, isLoading, isPaused, error } = useQuery({
    ...requirementsQuery(locale),
  });

  return {
    data,
    error,
    isLoading: isLoading && !isPaused,
  };
};
