import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";

import { useUpdateListing } from "queries";
import { ListingResponse } from "types/listing.types";

import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";
import {
  CreateListingPropertyDetailsFormFields,
  PropertyDetailsType,
} from "components/@create-listing/types";
import FormCounter from "components/@form/FormCounter";

interface Props {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
}

type RoomDetailsForm = {
  [CreateListingPropertyDetailsFormFields.Bedrooms]: number;
  [CreateListingPropertyDetailsFormFields.Toilets]: number;
  [CreateListingPropertyDetailsFormFields.Bathrooms]: number;
};

const RoomDetails = ({ listing, isReadOnly }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const { updateListing, isLoading } = useUpdateListing();

  const methods = useForm<RoomDetailsForm>({
    defaultValues: {
      [CreateListingPropertyDetailsFormFields.Bedrooms]:
        listing.attributes.bedrooms,
      [CreateListingPropertyDetailsFormFields.Toilets]:
        listing.attributes.toilets,
      [CreateListingPropertyDetailsFormFields.Bathrooms]:
        listing.attributes.bathrooms,
    },
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleAction = () => {
    if (isEditing) {
      methods.reset();
    }

    setIsEditing((isEditing) => !isEditing);
  };

  const handleSubmit = ({ bathrooms, toilets, bedrooms }: RoomDetailsForm) => {
    updateListing(
      { id: listing.id, attributes: { bathrooms, toilets, bedrooms } },
      {
        onSuccess: () => {
          methods.reset(methods.getValues());
          setIsEditing(false);
        },
        onError: (error) => {
          captureMessage("Error updating room details in listing detail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const { bathrooms, bedrooms, toilets } = methods.watch();

  return (
    <ItemListRow
      title="listing_detail.details.room_details"
      actionKey={isEditing ? "button.cancel" : "common.edit"}
      isLoading={isLoading}
      onActionClick={isReadOnly ? undefined : handleAction}
    >
      {isEditing ? (
        <FormProvider {...methods}>
          <div style={{ width: "100%" }}>
            <FormCounter
              name={CreateListingPropertyDetailsFormFields.Bedrooms}
              label={
                <FormattedMessage
                  id={`create_listing_property_details.${PropertyDetailsType.Bedrooms}`}
                />
              }
            />

            <hr className="counter-divider margin-8" />

            <FormCounter
              name={CreateListingPropertyDetailsFormFields.Toilets}
              label={
                <FormattedMessage
                  id={`create_listing_property_details.${PropertyDetailsType.Toilets}`}
                />
              }
            />

            <hr className="counter-divider margin-8" />

            <FormCounter
              name={CreateListingPropertyDetailsFormFields.Bathrooms}
              label={
                <FormattedMessage
                  id={`create_listing_property_details.${PropertyDetailsType.Bathrooms}`}
                />
              }
            />
            <LoadingButton
              isLoading={isLoading}
              shape="round"
              style={{ height: 48, marginTop: 12 }}
              onClick={methods.handleSubmit(handleSubmit)}
            >
              <FormattedMessage id="common.save" />
            </LoadingButton>
          </div>
        </FormProvider>
      ) : (
        <div
          style={{
            marginTop: 12,
            display: "flex",
            gap: 80,
            flexWrap: "wrap",
            rowGap: 24,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <FormattedMessage id="create_listing_property_details.bedrooms" />
            </span>
            <span>{bedrooms}</span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <FormattedMessage id="create_listing_property_details.toilets" />
            </span>
            <span>{toilets}</span>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <FormattedMessage id="create_listing_property_details.bathrooms" />
            </span>
            <span>{bathrooms}</span>
          </div>
        </div>
      )}
    </ItemListRow>
  );
};

export default RoomDetails;
