/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */
/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css'; */
// import "@ionic/react/css/palettes/dark.system.css";
/* Theme variables */
import "./theme/variables.scss";

import { Capacitor } from "@capacitor/core";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { PostHogProvider } from "posthog-js/react";
import { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";

import config from "config/env";

import { isDev } from "utils";
import { isAndroid } from "utils/capacitor.utils";

import { useBackButtonListener, useLockScreenOrientation } from "hooks";
import { configureAWS } from "services/auth/config";
import { AuthProvider } from "services/auth/provider";
import IntlSelectorProvider from "services/i18n";
import LazyMotion from "services/motion";
import NotificationProvider from "services/notifications/NotificationProvider";
import { postogOptions } from "services/posthog";
import { createQueryClient, PersistOptions } from "services/react-query";

import RootBoundary from "components/@common/RootBoundary/RootBoundary";
import AppRoutes from "components/@routes/AppRoutes";
import AppUrlListener from "components/@routes/AppUrlListener";
import Helmet from "components/@seo/Helmet";

setupIonicReact({
  swipeBackEnabled: true,
});

configureAWS();
const queryClient = createQueryClient();

const App = () => {
  if (isAndroid) {
    NavigationBar.setTransparency({ isTransparent: true });
  }

  useBackButtonListener();
  useLockScreenOrientation();

  return (
    <RootBoundary>
      <PostHogProvider apiKey={config.POSTHOG.API_KEY} options={postogOptions}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={PersistOptions}
        >
          <AuthProvider>
            <IntlSelectorProvider>
              <IonReactRouter>
                <AppUrlListener />
                <LazyMotion>
                  <HelmetProvider>
                    <Helmet />
                  </HelmetProvider>
                  <RootBoundary>
                    {Capacitor.isNativePlatform() ? (
                      <Suspense>
                        <NotificationProvider>
                          <IonApp>
                            <AppRoutes />
                          </IonApp>
                        </NotificationProvider>
                      </Suspense>
                    ) : (
                      <IonApp>
                        <AppRoutes />
                      </IonApp>
                    )}
                  </RootBoundary>
                </LazyMotion>
              </IonReactRouter>
            </IntlSelectorProvider>
          </AuthProvider>
          {isDev() && (
            <ReactQueryDevtools initialIsOpen={false} position="bottom" />
          )}
        </PersistQueryClientProvider>
      </PostHogProvider>
    </RootBoundary>
  );
};

export default App;
