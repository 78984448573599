import { format, parseISO } from "date-fns";

export const formatDateRange = (fromDate: string, toDate: string) => {
  const from = parseISO(fromDate);
  const to = parseISO(toDate);

  if (
    from.getMonth() === to.getMonth() &&
    from.getFullYear() === to.getFullYear()
  ) {
    // If the same month and year, return "01 - 10 Sep"
    return `${format(from, "dd")} - ${format(to, "dd MMM")}`;
  } else {
    // Otherwise, return "01 Sep - 10 Oct"
    return `${format(from, "dd MMM")} - ${format(to, "dd MMM")}`;
  }
};
