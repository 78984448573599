import { IonPage } from "@ionic/react";

import OverviewComponent from "components/@create-reservation/Overview";

const Overview = () => {
  return (
    <IonPage>
      <OverviewComponent />
    </IonPage>
  );
};

export default Overview;
