import "./Segmentation.scss";

import {
  IonButton,
  IonCard,
  IonRadio,
  IonRadioGroup,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";
import { RequirementsAnswer } from "types/listing.types";

import StepperContainer from "components/@common/StepperContainer";
import SuccessCard from "components/@common/SuccessCard";

const Segmentation = () => {
  const router = useIonRouter();
  const [selection, setSelection] = useState<RequirementsAnswer | null>(null);

  const handleSubmit = () => {
    const routePath =
      selection === RequirementsAnswer.Yes
        ? Routes.CreateListingUrl
        : Routes.CreateListingPropertyDetails;

    router.push(routePath);
  };

  return (
    <StepperContainer
      flow={Flow.CreateListing}
      route={Routes.CreateListingSegmentation}
      continueButton={
        <IonButton
          data-testid="continue-button"
          className="continue-button-stepper"
          shape="round"
          expand="full"
          onClick={handleSubmit}
          disabled={!selection}
        >
          <FormattedMessage id="common.continue" />
        </IonButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={() => router.goBack()}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="create_listing_segmentation.title" />
      </h2>

      <p className="create-listing-description">
        <FormattedMessage id="create_listing_segmentation.description" />
      </p>

      <IonRadioGroup
        value={selection}
        onIonChange={(e) => setSelection(e.detail.value)}
        className="margin-top-16"
      >
        <IonCard className="radio-card">
          <div
            className="radio-card-content"
            onClick={() => setSelection(RequirementsAnswer.Yes)}
          >
            <div>
              <p className="radio-card-title margin-0">
                <FormattedMessage id="create_listing_segmentation.yes_checkbox.title" />
              </p>
              <p className="small margin-top-8">
                <FormattedMessage id="create_listing_segmentation.yes_checkbox.description" />
              </p>
            </div>
            <IonRadio
              value={RequirementsAnswer.Yes}
              slot="start"
              justify="space-between"
              labelPlacement="start"
            />
          </div>
        </IonCard>
        <IonCard className="radio-card">
          <div
            className="radio-card-content"
            onClick={() => setSelection(RequirementsAnswer.No)}
          >
            <div>
              <p className="radio-card-title margin-0">
                <FormattedMessage id="create_listing_segmentation.no_checkbox.title" />
              </p>
              <p className="small margin-top-8">
                <FormattedMessage id="create_listing_segmentation.no_checkbox.description" />
              </p>
            </div>
            <IonRadio
              value={RequirementsAnswer.No}
              slot="start"
              justify="space-between"
              labelPlacement="start"
            />
          </div>
        </IonCard>
      </IonRadioGroup>

      {selection === RequirementsAnswer.No && (
        <SuccessCard title="create_listing_segmentation.success_card.title" />
      )}
    </StepperContainer>
  );
};

export default Segmentation;
