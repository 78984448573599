import { Helmet as ReactHelmet } from "react-helmet-async";
import { useIntl } from "react-intl";

const Helmet = () => {
  const intl = useIntl();

  return (
    <ReactHelmet>
      <title>{intl.formatMessage({ id: "seo.title" })}</title>
      <meta
        name="description"
        content={intl.formatMessage({ id: "seo.description" })}
      />
    </ReactHelmet>
  );
};

export default Helmet;
