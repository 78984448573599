import { queryOptions } from "@tanstack/react-query";

import { apiService } from "services";

export const BLOCKS_PREFIX = "block";

export const getBlockReasonsQuery = (locale: string) =>
  queryOptions({
    queryKey: [BLOCKS_PREFIX, "reasons", locale],
    queryFn: () => apiService.getBlockReasons(locale),
  });
