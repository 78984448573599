import { MonthlyInsight } from "types/listing.types";

export const TEST_DATA: Array<MonthlyInsight> = [
  {
    revenue: 1000,
    index: 0,
    cumulativeRevenue: 1000,
  },
  {
    revenue: 1500,
    index: 1,
    cumulativeRevenue: 2500,
  },
  {
    revenue: 2000,
    index: 2,
    cumulativeRevenue: 4500,
  },
  {
    revenue: 2500,
    index: 3,
    cumulativeRevenue: 7000,
  },
  {
    revenue: 3000,
    index: 4,
    cumulativeRevenue: 10000,
  },
  {
    revenue: 3500,
    index: 5,
    cumulativeRevenue: 13500,
  },
  {
    revenue: 4000,
    index: 6,
    cumulativeRevenue: 17500,
  },
  {
    revenue: 4500,
    index: 7,
    cumulativeRevenue: 22000,
  },
  {
    revenue: 5000,
    index: 8,
    cumulativeRevenue: 27000,
  },
  {
    revenue: 5500,
    index: 9,
    cumulativeRevenue: 32500,
  },
  {
    revenue: 6000,
    index: 10,
    cumulativeRevenue: 38500,
  },
  {
    revenue: 6500,
    index: 11,
    cumulativeRevenue: 45000,
  },
];
