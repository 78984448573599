import "./ListingPlatforms.scss";

import { IonChip, IonImg } from "@ionic/react";
import { FormattedMessage } from "react-intl";

interface Props {
  logoUrl: string;
  name: string;
  premium: boolean;
}

const Platform = ({ logoUrl, name, premium }: Props) => {
  return (
    <div className="platform-container">
      <IonImg className="platform-img" src={logoUrl} />
      <div className="platform-title-container">
        <h6 className="margin-0">{name}</h6>
        {premium && (
          <div>
            <IonChip className="premium-chip">
              <FormattedMessage id="platform_status.premium" />
            </IonChip>
          </div>
        )}
      </div>
    </div>
  );
};

export default Platform;
