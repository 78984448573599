import "./Divider.scss";

import { FormattedMessage } from "react-intl";

const Divider = () => {
  return (
    <div className="divider margin-16">
      <p className="divider-text margin-0">
        <FormattedMessage id="social_login.divider" />
      </p>
    </div>
  );
};

export default Divider;
