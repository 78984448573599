import "./ListingOverview.scss";

import { IonSkeletonText } from "@ionic/react";
import { format } from "date-fns";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useCalendarListing } from "queries";

import PublicReviews from "components/@common/PublicReviews/PublicReviews";

import { getListingId } from "../utils";
import Chart from "./Chart/Chart";
import Reservations from "./Reservations/Reservations";
import Support from "./Support/Support";

const ListingOverview = () => {
  const listingId = getListingId();

  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 3);
  const endDate = new Date();

  const { data, isLoading } = useCalendarListing(
    listingId || "",
    format(startDate, "yyyy-MM-dd"),
    format(endDate, "yyyy-MM-dd"),
    {
      enabled: !!listingId,
    },
  );

  const reservationsWithPublicReview =
    data?.attributes?.reservations?.filter(
      (reservation) =>
        reservation.publicReview &&
        Object.keys(reservation.publicReview).length > 0,
    ) || [];

  const publicReviews = reservationsWithPublicReview.map(
    (reservation) => reservation.publicReview,
  );

  useEffect(() => {
    document.body.classList.add("overview-page");
    return () => document.body.classList.remove("overview-page");
  }, []);

  return (
    <div className="listing-overview">
      <Chart />
      {isLoading && (
        <div className="review-loading">
          <IonSkeletonText
            animated={true}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      )}
      {!!publicReviews.length && !isLoading && (
        <div className="rating-block">
          <h5 className="margin-0">
            <FormattedMessage id="reservation_detail.satisfaction_title" />
          </h5>

          <PublicReviews publicReviews={publicReviews} />
        </div>
      )}
      <Reservations />
      <Support />
    </div>
  );
};

export default ListingOverview;
