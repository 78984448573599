import { COUNTRIES } from "constants/countries.constants";

interface CountryDetails {
  name: string;
  alpha3: string;
}

// TODO: use Intl web api to get country code
const nameToAlpha2Map: Record<string, keyof typeof COUNTRIES> = Object.entries(
  COUNTRIES,
).reduce(
  (acc, [alpha2, details]) => {
    const countryDetails = details as CountryDetails;
    acc[countryDetails.name.toLowerCase()] = alpha2 as keyof typeof COUNTRIES;
    return acc;
  },
  {} as Record<string, keyof typeof COUNTRIES>,
);

export const getCountryCode = (countryName?: string): string | undefined => {
  if (!countryName) {
    return undefined;
  }
  const lowerCaseName = countryName.toLowerCase();
  return nameToAlpha2Map[lowerCaseName] || countryName;
};
