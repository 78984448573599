import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { simplifiedCalendarQueryKey } from "queries/calendar/queries";
import { apiService } from "services";
import { CalendarListing } from "types/calendar.types";
import { BlockAttributes } from "types/reservation.types";

import { getBlockQuery } from "./queries";

export const useUpdateBlock = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, error } = useMutation({
    mutationFn: ({
      listingId,
      blockageGuestyId,
      attributes,
    }: {
      listingId: string;
      blockageGuestyId: string;
      attributes: BlockAttributes;
    }) => apiService.updateBlock(listingId, blockageGuestyId, attributes),
    onSuccess: async (
      _response,
      { listingId, blockageGuestyId, attributes },
    ) => {
      // "optimistic" update reservation
      const { queryKey } = getBlockQuery(listingId, blockageGuestyId);
      await queryClient.cancelQueries({ queryKey });

      const previousData = queryClient.getQueryData(queryKey);

      if (previousData) {
        queryClient.setQueryData(queryKey, {
          ...previousData,
          data: {
            ...previousData.data,
            data: {
              ...previousData.data.data,
              attributes: {
                ...previousData.data.data.attributes,
                ...attributes,
              },
            },
          },
        });
      }
      //TODO: Find a clean way to do this
      // optimistic update calendar + invalidate after 5s, will usually be enough time for guesty to sync (= race condition)
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: simplifiedCalendarQueryKey(listingId),
        });
      }, 5000);
      const calendarData = queryClient.getQueriesData<
        AxiosResponse<{ data: CalendarListing }>
      >({
        queryKey: simplifiedCalendarQueryKey(listingId),
      });

      calendarData.forEach((query) => {
        const [queryKey, previousData] = query;
        if (previousData) {
          queryClient.setQueryData<typeof previousData>(queryKey, {
            ...previousData,
            data: {
              ...previousData.data,
              data: {
                ...previousData.data.data,
                attributes: {
                  ...previousData.data.data.attributes,
                  ownerReservations:
                    previousData.data.data.attributes.ownerReservations.map(
                      (reservation) =>
                        reservation.guestyId === blockageGuestyId
                          ? { ...reservation, ...attributes }
                          : reservation,
                    ),
                },
              },
            },
          });
        }
      });
    },
  });

  return {
    updateBlock: mutate,
    isPending,
    error,
  };
};
