import { HouseRule } from "types/listing.types";

import {
  defaultHouseRules,
  emptyHouseRules,
} from "components/@complete-listing/constants";

export const formatHouseRulesForApi = (houseRules: Array<HouseRule>) => {
  return houseRules.map((rule, index) => {
    if (!rule.name) {
      return {
        value: rule.value || defaultHouseRules[index].value,
        name: defaultHouseRules[index].name,
      };
    }
    return rule;
  });
};

export const formatHouseRulesFromApi = (houseRules: Array<HouseRule>) => {
  const valuesMap = houseRules.reduce(
    (map, obj) => {
      map[obj.name] = obj.value !== undefined ? obj.value : map[obj.name];
      return map;
    },
    {} as Record<HouseRule["name"], HouseRule["value"] | undefined>,
  );

  return emptyHouseRules.map(
    (rule) =>
      ({
        name: rule.name,
        value:
          valuesMap.hasOwnProperty(rule.name) && !!valuesMap[rule.name]
            ? valuesMap[rule.name]
            : rule.value,
      }) as HouseRule,
  );
};
