import { DEFAULT_LOCALE } from "services/i18n";
import { MonthlyInsight } from "types/listing.types";

export const formatMonthWithLocale = (
  value: number,
  locale: string,
  format: "short" | "long",
) => {
  try {
    if (value < 0 || value > 11) {
      throw new RangeError(
        "Month value must be between 0 (January) and 11 (December).",
      );
    }
    const monthFormatter = new Intl.DateTimeFormat(locale ?? DEFAULT_LOCALE, {
      month: format,
    });

    const date = new Date(1999, value); // Year is arbitrary since we only need the month
    const formattedMonth = monthFormatter.format(date);
    return formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);
  } catch (error) {
    console.error("Error formatting month:", error);
    return "Invalid";
  }
};

export const formatTickValue = (value: number) => {
  if (typeof value !== "number" || isNaN(value)) {
    console.error(`Invalid tick value: ${value}`);
    return "Invalid";
  }

  if (value === 0) {
    return "0";
  }

  return `${value / 1000}k`;
};

export const formatCurrency = (amount?: number) => {
  if (amount == null) return "-";

  return new Intl.NumberFormat(DEFAULT_LOCALE, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const constructMonths = (monthlyInsights: Array<MonthlyInsight>) => {
  let lastCumulativeValue = 0;
  return Array.from({ length: 12 }, (_, idx) => {
    const apiMonthData = monthlyInsights.find((m) => m.index === idx);

    if (apiMonthData) {
      const adjustedCumulativeRevenue = lastCumulativeValue;
      lastCumulativeValue += apiMonthData.revenue;

      return {
        ...apiMonthData,
        revenue: apiMonthData.revenue,
        cumulativeRevenue: adjustedCumulativeRevenue,
        index: idx,
      };
    } else {
      return {
        revenue: 0,
        cumulativeRevenue: lastCumulativeValue,
        index: idx,
      };
    }
  });
};
