import "./Modal.scss";

import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonModal,
  isPlatform,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { ReactNode, useRef } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  isOpen: boolean;
  children: ReactNode;
  onDidDismiss?: () => void;
  continueButton?: ReactNode;
}

const Modal = ({ isOpen, children, onDidDismiss, continueButton }: Props) => {
  const modal = useRef<HTMLIonModalElement>(null);

  // NOTE: when passing breakpoints to IonModal, it becomes a sheet modal
  const modalProps = isPlatform("mobile")
    ? {
        initialBreakpoint: 1,
        breakpoints: [0, 1],
      }
    : {};

  return (
    <IonModal
      onDidDismiss={onDidDismiss}
      isOpen={isOpen}
      ref={modal}
      className="modal"
      {...modalProps}
    >
      <div className="modal-content">
        <IonContent className="modal-container">
          <div className="modal-container">
            <IonButton
              color="primary"
              shape="round"
              className="icon-button ion-hide-md-up"
              fill="clear"
              onClick={onDidDismiss}
            >
              <IonIcon size="large" slot="icon-only" icon={closeOutline} />
            </IonButton>
            <IonButton
              color="primary"
              shape="round"
              className="icon-button ion-hide-md-down absolute-button"
              fill="clear"
              onClick={onDidDismiss}
            >
              <IonIcon size="large" slot="icon-only" icon={closeOutline} />
            </IonButton>

            {children}
          </div>
        </IonContent>

        <IonFooter className="sticky-footer">
          <IonButton
            className="contrast-button"
            shape="round"
            onClick={onDidDismiss}
          >
            <FormattedMessage id="password_hints.modal.close" />
          </IonButton>
          {continueButton}
        </IonFooter>
      </div>
    </IonModal>
  );
};

export default Modal;
