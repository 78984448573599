export const chartTheme =
  // You can pass this object to the `theme` property of your chart component.
  {
    background: "var(--ion-color-primary-tint)",

    axis: {
      ticks: {
        line: {
          stroke: "transparent",
          strokeWidth: 1,
        },
        text: {
          fontSize: 14,
          fill: "var(--ion-color-primary-shade)",
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
    grid: {
      line: {
        stroke: "var(--ion-color-neutral-shade)",
        strokeWidth: 1,
        opacity: 0.5,
        strokeDasharray: "4 8",
      },
    },

    annotations: {
      text: {
        fontSize: 13,
        fill: "#333333",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      link: {
        stroke: "#000000",
        strokeWidth: 1,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      outline: {
        stroke: "#000000",
        strokeWidth: 2,
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
      symbol: {
        fill: "#000000",
        outlineWidth: 2,
        outlineColor: "#ffffff",
        outlineOpacity: 1,
      },
    },
  };
