/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./SignUp.scss";

import {
  InputChangeEventDetail,
  IonInputPasswordToggle,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { InputCustomEvent } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { getCognitoErrorMessageKey } from "utils/cognito.utils";
import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useResizeObserver } from "hooks";
import { useCreateLogin, useCreateUser } from "queries";
import { useAuthStore } from "store/auth-store";

import ErrorCard from "components/@common/ErrorCard";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import { Form } from "components/@form/Form";
import { FormIonInput } from "components/@form/FormIonInput";

import PasswordCriteria, {
  PasswordCriteriaSuccess,
  validatePassword,
} from "../PasswordCriteria";
import PasswordHints from "../PasswordHints";
import { LoginFormFields } from "../types";
import { SignUpStatus, StatusMessageIdLookup } from "./types";

interface FormData {
  password: string;
}

const defaultValues = {
  [LoginFormFields.Password]: "",
};

const SignUp = () => {
  const intl = useIntl();
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState<SignUpStatus>(SignUpStatus.Idle);

  const ref = useRef<HTMLDivElement>(null);
  const [_, observedHeight] = useResizeObserver(ref);
  const [height, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { createUser, error: signUpError } = useCreateUser();
  const { createLogin, error: loginError } = useCreateLogin();

  const errorName = signUpError?.name || loginError?.name;

  const email = useAuthStore((state) => state.values?.email);
  const router = useIonRouter();
  const handleSubmit = async ({ password }: FormData) => {
    setIsLoading(true);
    setStatus(SignUpStatus.SigningUp);
    createUser(
      {
        username: email!,
        password: password,
      },
      {
        onSuccess: () => {
          setStatus(SignUpStatus.SigningIn);
          createLogin(
            {
              username: email!,
              password: password,
            },
            {
              onSuccess: () => {
                setStatus(SignUpStatus.Redirecting);
                router.push(Routes.SignUpDetails);
                setIsLoading(false);
              },
              onError: (loginError) => {
                captureMessage("Error creating login", {
                  level: "error",
                  extra: { error: loginError },
                });
                setStatus(SignUpStatus.Idle);
                setIsLoading(false);
              },
            },
          );
        },
        onError: (signUpError) => {
          captureMessage("Error creating user", {
            level: "error",
            extra: { error: signUpError },
          });
          setStatus(SignUpStatus.Idle);
          setIsLoading(false);
        },
      },
    );
  };

  const handlePasswordChange = (
    event: InputCustomEvent<InputChangeEventDetail>,
  ) => {
    if (event.detail.value != null) setPassword(event.detail.value);
  };

  const isPasswordValid = () => {
    const results = validatePassword(password);
    return Object.values(results).every((value) => value);
  };

  useEffect(() => {
    if (!email) {
      router.push(Routes.SignUpContinue, "back");
    }
  }, [email, router]);

  useEffect(() => {
    if (observedHeight > 0) {
      setHeight(observedHeight); // Update height only if it's non-zero
    }
  }, [observedHeight]);

  return (
    <div className="container">
      <IonText>
        <h2>
          <FormattedMessage id="onboarding.signup.title" />
        </h2>
      </IonText>
      <p className="sign-up-description">
        <IonText>
          <FormattedMessage
            id="onboarding.signup.description"
            values={{
              email: <b>{email}</b>,
            }}
          />
        </IonText>
      </p>

      <LinkUnstyled
        data-testid="wrong-email-link"
        id="wrong-email"
        onClick={() => router.push(Routes.SignUpContinue, "back")}
      >
        <FormattedMessage id="onboarding.signup.wrong_email" />
      </LinkUnstyled>

      {errorName && (
        <ErrorCard
          title={
            <FormattedMessage
              id={getCognitoErrorMessageKey(errorName)}
              values={{
                email: <b>{email}</b>,
              }}
            />
          }
        />
      )}

      <Form<FormData>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onSubmit"
      >
        <FormIonInput
          data-testid="password-input"
          name={LoginFormFields.Password}
          aria-label={LoginFormFields.Password}
          autocomplete="current-password"
          type="password"
          clearOnEdit={false}
          rules={{ required: true }}
          fill="solid"
          placeholder={intl.formatMessage({
            id: "onboarding.login.password.placeholder",
          })}
          onIonInput={handlePasswordChange}
        >
          <IonInputPasswordToggle color="medium" slot="end" />
        </FormIonInput>
        <LoadingButton
          data-testid="continue-button"
          className="continue-button"
          shape="round"
          expand="full"
          type="submit"
          disabled={!isPasswordValid() || isLoading}
          isLoading={isLoading}
          message={
            StatusMessageIdLookup[status]
              ? intl.formatMessage({ id: StatusMessageIdLookup[status] })
              : undefined
          }
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      </Form>

      {isPasswordValid() ? (
        <PasswordCriteriaSuccess minHeight={height - 12}>
          <FormattedMessage id="password_criteria.success" />
        </PasswordCriteriaSuccess>
      ) : (
        <div ref={ref}>
          <PasswordCriteria password={password} />
          <PasswordHints />
        </div>
      )}
    </div>
  );
};

export default SignUp;
