import "aws-amplify/auth/enable-oauth-listener";

import { Amplify } from "aws-amplify";

import { isDev } from "utils";
import { getTopLevelDomainFromCurrentLocation } from "utils/location.utils";

const tld = getTopLevelDomainFromCurrentLocation();
const frontendUrl = isDev()
  ? new URL(process.env.REACT_APP_API_REDIRECT_AMPLIFY_URL ?? "")
  : new URL(`${process.env.REACT_APP_API_REDIRECT_AMPLIFY_URL}.${tld}/`);

export const configureAWS = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID ?? "",
        userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID ?? "",
        loginWith: {
          oauth: {
            domain: process.env.REACT_APP_OAUTH_DOMAIN ?? "",
            scopes: [
              "profile",
              "email",
              "openid",
              "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: [`${frontendUrl}auth/callback`],
            redirectSignOut: [`${frontendUrl}sign-in/continue`],
            responseType: "code",
          },
        },
      },
    },
  });
};
