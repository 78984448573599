import "./SparkleCard.scss";

import { IonCard, IonCardContent, IonIcon } from "@ionic/react";
import { sparklesOutline } from "ionicons/icons";
import { CSSProperties } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
  title: I18nKey;
  icon?: string;
  style?: CSSProperties;
}

const SparkleCard = ({ title, icon, style }: Props) => {
  if (!title) return null;

  return (
    <IonCard className="sparkle-card" style={style}>
      <IonCardContent>
        <div className="sparkle-card-container">
          <IonIcon
            icon={icon ? icon : sparklesOutline}
            className="sparkle-card-icon"
          />

          <p className="extra-small whitespace">
            <FormattedMessage id={title} />
          </p>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default SparkleCard;
