import "./ListingPlatforms.scss";

import { IonButton, IonImg } from "@ionic/react";
import { FormattedMessage, useIntl } from "react-intl";

import useCopyToClipboard from "hooks/useCopyToClipboard";
import { useListing } from "queries";
import { OwnerType } from "types/listing.types";

import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingIndicator from "components/@common/LoadingIndicator";
import ListingBreadcrumbs from "components/@listing-detail/ListingDetails/ListingBreadcrumbs/ListingBreadcrumbs";
import { getListingId, useOwnerType } from "components/@listing-detail/utils";

import { useListingPlatforms } from "./hooks";
import Platform from "./Platform";

const ListingPlatforms = () => {
  const intl = useIntl();
  const {
    huswellPlatform,
    activePlatforms,
    inactivePlatforms,
    isLoading,
    listingData,
  } = useListingPlatforms();
  const listingId = getListingId();
  const { data: listing } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });
  const isReadOnly = useOwnerType(listing) === OwnerType.SUB;

  const { copy, isCopied } = useCopyToClipboard();

  return (
    <div className="listing-platforms">
      <ListingBreadcrumbs listing={listingData} />
      <LoadingIndicator isOpen={isLoading} testId="ListingPlatforms" />

      <h5 className="title">
        <FormattedMessage id="listing_detail.nav.platforms" />
      </h5>

      {huswellPlatform && (
        <div className="banner">
          <IonImg
            className="huswell-img"
            src={huswellPlatform.attributes.logoUrl}
          />
          <div className="banner-container">
            <div>
              <p className="banner-caption extra-small margin-0">
                <FormattedMessage id="listing_platforms.banner.caption" />
              </p>
              <h6 className="banner-title">
                <FormattedMessage id="listing_platforms.banner.title" />
              </h6>
            </div>

            <div className="banner-buttons">
              <IonButton
                shape="round"
                className="secondary-button"
                onClick={() =>
                  window.open(huswellPlatform.attributes.externalUrl, "_blank")
                }
              >
                <FormattedMessage id="platform_visit_link" />
              </IonButton>

              <IonButton
                shape="round"
                className="banner-copy-button"
                onClick={() => copy(huswellPlatform.attributes.externalUrl)}
              >
                <FormattedMessage
                  id={!isCopied ? "platform_copy_url" : "copied"}
                />
              </IonButton>
            </div>
          </div>
        </div>
      )}
      {activePlatforms.length !== 0 && (
        <>
          <h6 className="subtitle">
            <FormattedMessage id="listing_platforms.active_title" />
          </h6>

          <ItemList>
            {activePlatforms.map((platform) => (
              <ItemListRow
                key={platform.id}
                actionKey="platform_visit_link"
                onActionClick={() =>
                  window.open(platform.attributes.externalUrl, "_blank")
                }
              >
                <Platform
                  logoUrl={platform.attributes.logoUrl}
                  name={platform.attributes.name}
                  premium={platform.attributes.premium}
                />
              </ItemListRow>
            ))}
          </ItemList>
        </>
      )}

      {inactivePlatforms.length !== 0 && (
        <>
          <h6 className="subtitle">
            <FormattedMessage id="listing_platforms.inactive_title" />
          </h6>

          <ItemList>
            {inactivePlatforms.map((platform) => (
              <ItemListRow
                key={platform.id}
                actionKey="platform_request_link"
                onActionClick={
                  isReadOnly
                    ? undefined
                    : () =>
                        window.open(
                          intl.formatMessage({
                            id: "mailto.inactive_platform",
                          }),
                        )
                }
              >
                <Platform
                  logoUrl={platform.attributes.logoUrl}
                  name={platform.attributes.name}
                  premium={platform.attributes.premium}
                />
              </ItemListRow>
            ))}
          </ItemList>
        </>
      )}
    </div>
  );
};

export default ListingPlatforms;
