// List of allowed domains
const allowedDomains = [
  "booking",
  "airbnb",
  "vrbo",
  "expedia",
  "tripadvisor",
  "homeaway",
  "huswell",
  "rentalsunited",
  "plumguide",
  "marriott",
  "hometogo",
  "travelstaytion",
  "holidu",
  "nestpick",
  "travel",
  "theblueground",
];

export const validateUrl = (value: string) => {
  if (!value) return true;
  try {
    const url = new URL(value);
    return allowedDomains.some((domain) =>
      url.hostname.toLowerCase().includes(domain.toLowerCase()),
    );
  } catch (err) {
    return false;
  }
};
