import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";
import { useEffect, useState } from "react";

export const useIsKeyboardOpen = () => {
  const [keyboardIsOpen, setKeyboardIsOpen] = useState(false);

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") return; // Keyboard plugin is not available on web

    Keyboard.addListener("keyboardWillShow", () => setKeyboardIsOpen(true));
    Keyboard.addListener("keyboardWillHide", () => setKeyboardIsOpen(false));

    return () => {
      Keyboard.removeAllListeners();
    };
  }, []);

  return keyboardIsOpen;
};
