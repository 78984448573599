import { IonChip } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { ListingStatus } from "types/listing.types";

type Props = {
  status: ListingStatus;
};

const listingsStatusMap: Record<
  ListingStatus,
  { backgroundColor: string; color: string; title: I18nKey }
> = {
  [ListingStatus.Active]: {
    backgroundColor: "var(--ion-color-success-shade)",
    color: "var(--ion-color-success-tint)",
    title: "listing.status.ACTIVE",
  },
  [ListingStatus.Inactive]: {
    backgroundColor: "var(--ion-text-color-step-300)",
    color: "var(--ion-color-step-700)",
    title: "listing.status.INACTIVE",
  },
  [ListingStatus.New]: {
    backgroundColor: "#F49FF980",
    color: "#B60CC0",
    title: "listing.status.NEW",
  },
  [ListingStatus.Onboarding]: {
    backgroundColor: "#F49FF980",
    color: "#B60CC0",
    title: "listing.status.ONBOARDING",
  },
  [ListingStatus.Preparing]: {
    backgroundColor: "#F49FF980",
    color: "#B60CC0",
    title: "listing.status.PREPARING",
  },
  [ListingStatus.ReadyToPublish]: {
    backgroundColor: "#F49FF980",
    color: "#B60CC0",
    title: "listing.status.READY_TO_PUBLISH",
  },
  [ListingStatus.Suspended]: {
    backgroundColor: "#CF3C4F33",
    color: "var(--ion-color-danger-tint)",
    title: "listing.status.SUSPENDED",
  },
};

const ListingStatusChip = ({ status }: Props) => {
  const params = listingsStatusMap[status];

  if (!params) {
    return null;
  }

  const { backgroundColor, color, title } = params;

  return (
    <IonChip
      style={{
        backgroundColor,
        color,
        cursor: "default",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: 16,
        textAlign: "center",
      }}
    >
      <FormattedMessage id={title} />
    </IonChip>
  );
};

export default ListingStatusChip;
