import { App as CapacitorApp, BackButtonListenerEvent } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";

export const useBackButtonListener = () => {
  const router = useIonRouter();

  useEffect(() => {
    if (Capacitor.getPlatform() !== "android") return;
    const handleBackButton = ({ canGoBack }: BackButtonListenerEvent) => {
      if (
        !canGoBack ||
        router.routeInfo.pathname === generatePath(Routes.Listings)
      ) {
        CapacitorApp.exitApp();
      } else {
        router.goBack();
      }
    };

    CapacitorApp.addListener("backButton", handleBackButton);

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, [router]);
};
