import { IonInput } from "@ionic/react";
import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { useUpdateListing } from "queries";
import { ListingResponse } from "types/listing.types";
import { Context } from "types/upload.types";

import FileSelector from "components/@common/FileSelector";
import { MAX_FILES } from "components/@common/FileSelector/constants";
import FilePreview from "components/@common/FileSelector/FilePreview/FilePreview";
import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";
import Modal from "components/@common/Modal";
import { getListingId } from "components/@listing-detail/utils";

interface Props {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
}

const Regulations = ({ listing, isReadOnly }: Props) => {
  const initialValue = listing.attributes.regulations?.license || "";

  const listingId = getListingId();
  const [isEditingLicense, setIsEditingLicense] = useState(false);
  const [isDocumentDialogOpen, setIsDocumentDialogOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [fileUrls, setFileUrls] = useState<Array<string>>([]);
  const [license, setLicense] = useState(initialValue);

  const { updateListing, isLoading: isUpdating } = useUpdateListing();

  const handleAction = () => {
    if (isEditingLicense) {
      setLicense(initialValue);
    }

    setIsEditingLicense((isEditing) => !isEditing);
  };

  const handleSubmit = () => {
    updateListing(
      {
        id: listing.id,
        attributes: {
          regulations: {
            ...listing.attributes.regulations,
            documents: [
              ...(listing?.attributes.regulations?.documents || []),
              ...fileUrls,
            ],
            license,
          },
        },
      },
      {
        onSuccess: () => {
          setIsEditingLicense(false);
          setIsDocumentDialogOpen(false);
          setFileUrls([]);
        },
        onError: (error) => {
          captureMessage("Error updating regulations in listing detail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  return (
    <div className="listing-detail-block">
      <p className="subtitle">
        <FormattedMessage id="complete_listing_overview.regulations.title" />
      </p>
      <ItemList>
        <ItemListRow
          title="listing_detail.details.license_number"
          actionKey={isEditingLicense ? "button.cancel" : "common.edit"}
          onActionClick={isReadOnly ? undefined : handleAction}
          isLoading={isUpdating}
        >
          {isEditingLicense ? (
            <div style={{ width: "100%" }}>
              <IonInput
                value={license}
                fill="solid"
                onIonChange={(e) =>
                  setLicense(
                    String((e.target as HTMLIonInputElement).value) || "",
                  )
                }
              />
              <LoadingButton
                isLoading={isUpdating}
                shape="round"
                style={{ height: 48, marginTop: 12 }}
                onClick={handleSubmit}
              >
                <FormattedMessage id="common.save" />
              </LoadingButton>
            </div>
          ) : (
            <p className="margin-0">{license || "-"} </p>
          )}
        </ItemListRow>
        <ItemListRow
          title="listing_detail.details.documents"
          actionKey="common.add"
          onActionClick={
            isReadOnly ? undefined : () => setIsDocumentDialogOpen(true)
          }
          isLoading={isUpdating}
        >
          <FilePreview
            fileUrls={listing.attributes.regulations?.documents || []}
          />
        </ItemListRow>
      </ItemList>

      <Modal
        isOpen={isDocumentDialogOpen}
        onDidDismiss={() => setIsDocumentDialogOpen(false)}
        continueButton={
          <LoadingButton
            data-testid="continue-button"
            className="continue-button-stepper"
            shape="round"
            expand="full"
            onClick={handleSubmit}
            disabled={isUploading || !fileUrls.length || isUpdating}
            isLoading={isUpdating}
          >
            <FormattedMessage id="common.continue" />
          </LoadingButton>
        }
      >
        <div className="add-photos-modal">
          <h5 style={{ marginBottom: 40 }}>
            <FormattedMessage id="complete_listing_compliance.files.add_documents" />
          </h5>

          <FileSelector
            listingId={listingId}
            context={Context.Listing}
            type="documents"
            maxFiles={MAX_FILES}
            fileUrls={fileUrls}
            setIsUploading={setIsUploading}
            updateFileUrls={setFileUrls}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Regulations;
