import "./ChartTooltip.scss";

import { FormattedMessage } from "react-intl";

import { formatCurrency } from "components/@reservations/utils";

import { ChartKey } from "../../types";

interface Props {
  id: ChartKey;
  value: number;
  month: string;
}

const ChartToolTip = ({ id, value, month }: Props) => {
  return (
    <div className="tooltip">
      <p className="margin-0 bold uppercase">{month}</p>

      <div className="tooltip-box">
        <p className="margin-0">
          <FormattedMessage id={`chart.tooltip.${id}`} />
        </p>
        <p
          className="margin-0 bold"
          style={{
            color:
              id === ChartKey.Revenue
                ? "var(--ion-color-secondary)"
                : "var(--ion-color-secondary-shade)",
          }}
        >
          {formatCurrency(value)}
        </p>
      </div>
    </div>
  );
};

export default ChartToolTip;
