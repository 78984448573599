import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";
import { OverviewStep } from "types/listing.types";

import LaunchpadComponent from "components/@common/Launchpad";

const Launchpad = () => {
  useAndroidStatusBar({ iconsColor: "dark" });
  return (
    <IonPage>
      <LaunchpadComponent highlightedStep={OverviewStep.Review} />
    </IonPage>
  );
};

export default Launchpad;
