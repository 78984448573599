import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

import { simplifiedCalendarQueryKey } from "queries/calendar/queries";
import { apiService } from "services";
import { CalendarListing } from "types/calendar.types";
import { ReservationAttributes } from "types/reservation.types";

import { getReservationQuery } from "./queries";

export const useUpdateReservation = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: ({
      listingId,
      reservationId,
      attributes,
    }: {
      listingId: string;
      reservationId: string;
      attributes: ReservationAttributes;
    }) => apiService.updateReservation(listingId, reservationId, attributes),
    onSuccess: async (_response, { listingId, reservationId, attributes }) => {
      // "optimistic" update reservation
      const { queryKey } = getReservationQuery(listingId, reservationId);
      await queryClient.cancelQueries({ queryKey });

      const previousData = queryClient.getQueryData(queryKey);

      if (previousData) {
        queryClient.setQueryData(queryKey, {
          ...previousData,
          data: {
            ...previousData.data,
            data: {
              ...previousData.data.data,
              attributes: {
                ...previousData.data.data.attributes,
                ...attributes,
              },
            },
          },
        });
      }
      //TODO: Find a clean way to do this
      // optimistic update calendar + invalidate after 5s, will usually be enough time for guesty to sync (= race condition)
      setTimeout(() => {
        queryClient.invalidateQueries({
          queryKey: simplifiedCalendarQueryKey(listingId),
        });
      }, 5000);

      const calendarData = queryClient.getQueriesData<
        AxiosResponse<{ data: CalendarListing }>
      >({
        queryKey: simplifiedCalendarQueryKey(listingId),
      });

      calendarData.forEach((query) => {
        const [queryKey, previousData] = query;
        if (previousData) {
          queryClient.setQueryData<typeof previousData>(queryKey, {
            ...previousData,
            data: {
              ...previousData.data,
              data: {
                ...previousData.data.data,
                attributes: {
                  ...previousData.data.data.attributes,
                  ownerReservations:
                    previousData.data.data.attributes.ownerReservations.map(
                      (reservation) =>
                        reservation.guestyId === reservationId
                          ? { ...reservation, ...attributes }
                          : reservation,
                    ),
                },
              },
            },
          });
        }
      });
    },
  });

  return {
    updateReservation: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
