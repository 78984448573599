import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { ResetPasswordSuccess as ResetPasswordSuccessComponent } from "components/@authentication/ResetPasswordSuccess";
import LogoContainer from "components/@common/LogoContainer";

const ResetPasswordSuccess = () => {
  useAndroidStatusBar({ color: "#241261", iconsColor: "light" });
  return (
    <IonPage>
      <LogoContainer>
        <ResetPasswordSuccessComponent />
      </LogoContainer>
    </IonPage>
  );
};

export default ResetPasswordSuccess;
