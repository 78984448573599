/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./ForgotPassword.scss";

import { IonCard, IonCardContent, IonText, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";
import { emailRegex } from "constants/validation.constants";
import { useForgotPassword } from "queries";
import { useAuthStore } from "store/auth-store";

import ButtonUnstyled from "components/@common/ButtonUnstyled";
import ErrorCard from "components/@common/ErrorCard";
import LoadingButton from "components/@common/LoadingButton";
import { Form } from "components/@form/Form";
import { FormIonInput } from "components/@form/FormIonInput";

import { LoginFormFields } from "../types";
import { getCognitoErrorMessageKey } from "./utils";

interface FormData {
  email: string;
}

const ForgotPassword = () => {
  const { forgotPassword, error, isLoading } = useForgotPassword();
  const intl = useIntl();
  const [showSuccess, setShowSuccess] = useState(false);
  const router = useIonRouter();

  const { setValues, email } = useAuthStore((state) => ({
    setValues: state.actions.setValues,
    email: state.values?.email,
  }));

  const defaultValues = {
    [LoginFormFields.Email]: email || "",
  };

  const handleSubmit = async ({ email }: FormData) => {
    forgotPassword(
      {
        username: email!,
      },
      {
        onSuccess: () => {
          setShowSuccess(true);
          setValues({ email });
        },
        onError: () => {
          setShowSuccess(false);
        },
      },
    );
  };

  return (
    <div className="container">
      <IonText>
        <h2>
          <FormattedMessage id="onboarding.forgot_password.title" />
        </h2>
      </IonText>
      <p className="description">
        <IonText>
          <FormattedMessage id="onboarding.forgot_password.description" />
        </IonText>
      </p>

      {error && (
        <ErrorCard
          title={
            <FormattedMessage
              id={getCognitoErrorMessageKey(error.name).key}
              values={getCognitoErrorMessageKey(error.name).values}
            />
          }
        />
      )}

      {showSuccess && (
        <IonCard className="success-card">
          <IonCardContent>
            <p>
              <FormattedMessage
                id="forgot_password.success"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </p>
          </IonCardContent>
        </IonCard>
      )}

      <Form<FormData>
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        mode="onSubmit"
      >
        <FormIonInput
          className="email-input"
          data-testid="email-input"
          inputMode="email"
          name={LoginFormFields.Email}
          aria-label={LoginFormFields.Email}
          autocomplete="email"
          type="email"
          fill="solid"
          label={intl.formatMessage({ id: "email.label" })}
          labelPlacement="floating"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "login.field.email.error.required",
              }),
            },
            pattern: {
              value: emailRegex,
              message: intl.formatMessage({
                id: "login.field.email.error.invalid",
              }),
            },
          }}
          placeholder={intl.formatMessage({
            id: "sign_up_continue.email.placeholder",
          })}
        />

        <LoadingButton
          isLoading={isLoading}
          disabled={isLoading}
          data-testid="continue-button"
          className="continue-button"
          type="submit"
          shape="round"
          expand="full"
        >
          <FormattedMessage id="forgot_password.button.label" />
        </LoadingButton>
      </Form>

      <div className="back-container">
        <ButtonUnstyled
          onClick={() => router.push(Routes.SignInContinue, "back")}
          id="back-to-login"
          data-testid="back-to-login"
        >
          <FormattedMessage id="back_to_login.button" />
        </ButtonUnstyled>
      </div>
    </div>
  );
};

export default ForgotPassword;
