import { FormattedMessage, useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";
import { BedType, SleepingArrangement } from "types/listing.types";

import OverviewCard from "./OverviewCard";

interface Props {
  sleepingArrangements: Array<SleepingArrangement>;
}

const SleepingArrangementsCard = ({ sleepingArrangements }: Props) => {
  const intl = useIntl();

  const formatBedTypes = (bedTypes: Array<BedType>) => {
    if (bedTypes.length === 0) return "";
    const formattedBedTypes = bedTypes
      .map((bedType) => intl.formatMessage({ id: `bed_type.${bedType}` }))
      .join(" + ");

    return formattedBedTypes;
  };

  return (
    <OverviewCard
      title="complete_listing_overview.sleeping_arrangements.title"
      route={Routes.CompleteListingSleepingArrangements}
    >
      {sleepingArrangements.length === 0 && (
        <p className="margin-0 extra-small">
          <em>
            {intl.formatMessage({ id: "complete_listing_overview.not_set" })}
          </em>
        </p>
      )}

      {sleepingArrangements.length > 0 && (
        <>
          {sleepingArrangements.map((arrangement, index) => (
            <p className="margin-0 extra-small" key={index}>
              <FormattedMessage
                id={`complete_listing_overview.${arrangement.roomType}`}
                values={{
                  count: index + 1,
                  bedTypes: <b>{formatBedTypes(arrangement.bedTypes)}</b>,
                }}
              />
            </p>
          ))}
        </>
      )}
    </OverviewCard>
  );
};

export default SleepingArrangementsCard;
