import { IonPage } from "@ionic/react";

import SleepingArrangementsComponent from "components/@complete-listing/SleepingArrangements";

const SleepingArrangements = () => {
  return (
    <IonPage>
      <SleepingArrangementsComponent />
    </IonPage>
  );
};

export default SleepingArrangements;
