import "./MenuItem.scss";

import { IonIcon, IonSpinner } from "@ionic/react";
import { chevronForwardOutline } from "ionicons/icons";
import { FormattedMessage } from "react-intl";

interface Props {
  title: I18nKey;
  icon: string;
  isExternal?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

const MenuItem = ({
  title,
  icon,
  isExternal = false,
  onClick,
  isLoading,
}: Props) => {
  return (
    <div className="menu-item" onClick={onClick}>
      <div className="menu-link-title">
        <IonIcon src={icon} />
        <p className="bold margin-0">
          <FormattedMessage id={title} />
        </p>
      </div>

      {isLoading ? (
        <IonSpinner className="menu-item-spinner" name="crescent" />
      ) : (
        <IonIcon
          className="link-icon"
          src={
            isExternal
              ? "assets/icons/icon-link-external.svg"
              : chevronForwardOutline
          }
        />
      )}
    </div>
  );
};

export default MenuItem;
