import { useMutation } from "@tanstack/react-query";

import { apiService } from "services";
import { Context } from "types/upload.types";

interface RequestPresignedUrlParams {
  listingId: string;
  context: Context;
  filename: string;
  contentType: string;
}

export const useRequestPresignedUrl = () => {
  const { isPending, isPaused, error, mutateAsync } = useMutation({
    mutationFn: async ({
      listingId,
      context,
      filename,
      contentType,
    }: RequestPresignedUrlParams) =>
      apiService.requestPresignedUrl(listingId, context, filename, contentType),
  });

  return {
    requestPresignedUrl: mutateAsync,
    isLoading: isPending && !isPaused,
    error,
  };
};
