import { addDays, differenceInCalendarDays, isPast } from "date-fns";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import {
  CalendarListing,
  CalendarMarkingProps,
  CalendarMarkingType,
  DayProps,
  WeekRowType,
} from "types/calendar.types";

import CalendarWeekRow from "components/@calendar/CalendarWeekRow/CalendarWeekRow";
import {
  getCalendarValues,
  getMarkingParams,
  isDateInRange,
  MarkerHoverContext,
} from "components/@calendar/utils";

type Props = {
  listing: CalendarListing | undefined;
  activeDate: Date;
  onRemoveReservation?: () => void;
};

const Calendar = ({ listing, activeDate, onRemoveReservation }: Props) => {
  const intl = useIntl();

  const weekRows = useMemo<Array<WeekRowType>>(() => {
    const { firstDayOfCalendar, amountOfWeekRows } =
      getCalendarValues(activeDate);

    const weekRows = [...Array(amountOfWeekRows || 1)].map((_, weekIndex) => {
      const firstDayOfWeek = new Date(
        firstDayOfCalendar.getFullYear(),
        firstDayOfCalendar.getMonth(),
        firstDayOfCalendar.getDate() + weekIndex * 7,
      );

      const weekDays = [...Array(7)].map((_, weekDayIndex) => {
        const date = new Date(
          firstDayOfWeek.getFullYear(),
          firstDayOfWeek.getMonth(),
          firstDayOfWeek.getDate() + weekDayIndex,
        );

        const reservations =
          listing?.attributes.reservations.filter(
            ({ checkInDate, checkOutDate }) =>
              checkInDate &&
              checkOutDate &&
              isDateInRange(date, [
                new Date(checkInDate),
                new Date(checkOutDate),
              ]),
          ) || [];

        const ownerReservations =
          listing?.attributes.ownerReservations.filter(
            ({ checkInDate, checkOutDate }) =>
              checkInDate &&
              checkOutDate &&
              isDateInRange(date, [
                new Date(checkInDate),
                new Date(checkOutDate),
              ]),
          ) || [];

        const blocks =
          listing?.attributes.blocks.filter(
            ({ startDate, endDate }) =>
              startDate &&
              endDate &&
              isDateInRange(date, [new Date(startDate), new Date(endDate)]),
          ) || [];

        const isInPast = isPast(addDays(date, 1));

        const calendarMarkings: Array<CalendarMarkingProps> = [
          ...reservations.map(
            ({ checkInDate, checkOutDate, guestFullName, id }) => ({
              type: CalendarMarkingType.RESERVATION,
              isInPast,
              title: guestFullName,
              description: intl.formatMessage(
                { id: "calendar.amount_of_nights" },
                { count: differenceInCalendarDays(checkOutDate, checkInDate) },
              ),
              id,
              listingId: listing?.id,
              ...getMarkingParams({
                date,
                startDate: new Date(checkInDate),
                endDate: new Date(checkOutDate),
                weekDayIndex,
                weekIndex,
              }),
            }),
          ),
          ...ownerReservations.map(
            ({ checkInDate, checkOutDate, guestyId }) => ({
              type: CalendarMarkingType.OWNER_RESERVATION,
              isInPast,
              title: intl.formatMessage({ id: "calendar.owner_reservation" }),
              description: intl.formatMessage(
                { id: "calendar.amount_of_nights" },
                { count: differenceInCalendarDays(checkOutDate, checkInDate) },
              ),
              id: guestyId,
              listingId: listing?.id,
              ...getMarkingParams({
                date,
                startDate: new Date(checkInDate),
                endDate: new Date(checkOutDate),
                weekDayIndex,
                weekIndex,
              }),
            }),
          ),
          ...blocks.map(
            ({ startDate, endDate, note, guestyId, isOwnerBlock }) => ({
              type: CalendarMarkingType.BLOCK,
              isInPast,
              title: intl.formatMessage({
                id: isOwnerBlock
                  ? "calendar.owner_block"
                  : "calendar.system_block",
              }),
              description: `${intl.formatMessage(
                { id: "calendar.amount_of_nights" },
                { count: differenceInCalendarDays(endDate, startDate) },
              )} ${note}`,
              id: guestyId,
              listingId: listing?.id,
              ...getMarkingParams({
                date,
                startDate: new Date(startDate),
                endDate: new Date(endDate),
                weekDayIndex,
                weekIndex,
              }),
            }),
          ),
        ];

        const day: DayProps = {
          calendarMarkings,
          date,
          selectedDate: activeDate,
        };

        return day;
      });

      return weekDays;
    });

    return weekRows;
  }, [listing, activeDate, intl]);

  const [hoveredId, setHoveredId] = useState<string | null>(null);
  const contextValue = useMemo(
    () => ({ hoveredId, setHoveredId }),
    [hoveredId, setHoveredId],
  );

  return (
    <div
      role="presentation"
      className="week-rows"
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        flex: 1,
        borderLeft: "1px var(--ion-text-color-step-400) solid",
      }}
    >
      <MarkerHoverContext.Provider value={contextValue}>
        {weekRows.map((weekRow, index) => (
          <CalendarWeekRow
            weekRow={weekRow}
            selectedDate={activeDate}
            key={index}
            onRemoveReservation={onRemoveReservation}
          />
        ))}
      </MarkerHoverContext.Provider>
    </div>
  );
};

export default Calendar;
