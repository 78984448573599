import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signIn, SignInInput } from "aws-amplify/auth";

import { accountUserQuery } from "./queries";

export const useCreateLogin = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: async (input: SignInInput) => {
      const response = await signIn(input);
      if (response.isSignedIn) {
        await queryClient.ensureQueryData({
          ...accountUserQuery,
          meta: { localStorage: true },
        });
      } else if (
        response.nextStep?.signInStep ===
        "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
      ) {
        throw new Error(response.nextStep.signInStep);
      }
    },
  });
  return {
    createLogin: mutate,
    isLoading: isPending,
    error,
  };
};
