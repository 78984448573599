export enum ReviewListingBookingWindow {
  All = "ALL",
  Days30 = "DAYS_30",
  Days60 = "DAYS_60",
  Days90 = "DAYS_90",
  Days120 = "DAYS_120",
  Days150 = "DAYS_150",
  Days180 = "DAYS_180",
  Days210 = "DAYS_210",
  Days240 = "DAYS_240",
  Days270 = "DAYS_270",
  Days300 = "DAYS_300",
  Days330 = "DAYS_330",
  Days365 = "DAYS_365",
}

export enum ReviewListingAdvanceNotice {
  SameDay = "SAME_DAY",
  Days1 = "DAYS_1",
  Days2 = "DAYS_2",
  Days3 = "DAYS_3",
  Days7 = "DAYS_7",
  None = "NONE",
}
