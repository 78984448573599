import { useMutation } from "@tanstack/react-query";
import {
  confirmUserAttribute,
  ConfirmUserAttributeInput,
} from "aws-amplify/auth";

export const useConfirmEmail = () => {
  const { isPending, isPaused, mutate, error } = useMutation({
    mutationFn: (input: ConfirmUserAttributeInput) =>
      confirmUserAttribute(input),
  });

  return {
    confirmEmail: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
