import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListingQuery } from "queries/listings/queries";
import { apiService } from "services";

export const useRevokeOwnerAccess = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: ({
      ownerId,
      listingId,
    }: {
      ownerId: string;
      listingId: string;
    }) => apiService.revokeOwnerAccess(ownerId, listingId),
    onSuccess: (_, { listingId }) => {
      queryClient.invalidateQueries({
        queryKey: getListingQuery(listingId).queryKey,
      });
    },
  });

  return {
    revokeOwnerAccess: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
