import { RefObject, useCallback, useState } from "react";

import { useEnhancedEffect } from "./useEnhancedEffect";

export const useResizeObserver = (ref: RefObject<HTMLElement>) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  const handleResize = useCallback((entries: Array<ResizeObserverEntry>) => {
    if (!Array.isArray(entries)) {
      return;
    }
    const entry = entries[0];
    setWidth(entry.contentRect.width);
    setHeight(entry.contentRect.height);
    setScrollHeight(entry.target.scrollHeight);
  }, []);

  useEnhancedEffect(() => {
    if (!ref.current) {
      return;
    }
    const myObserver = new ResizeObserver(
      (entries: Array<ResizeObserverEntry>) => {
        handleResize(entries);
      },
    );
    myObserver.observe(ref.current);

    return () => {
      myObserver.disconnect();
    };
  }, [handleResize, ref]);

  // no idea why, but changing this to an object causes the test to fail
  return [width, height, scrollHeight];
};
