import { Share } from "@capacitor/share";
import { IonButton } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { captureMessage } from "utils/sentry.utils";

import useCopyToClipboard from "hooks/useCopyToClipboard";

interface Props {
  url: string;
  onShare?: () => void;
  isMobile: boolean;
}

const DynamicShareButton = ({ url, isMobile }: Props) => {
  const { copy, isCopied } = useCopyToClipboard();

  const handleNavigatorShare = async () => {
    try {
      const canShare = await Share.canShare();

      isMobile && canShare.value
        ? await Share.share({
            url,
          })
        : await copy(url);
    } catch (error) {
      if ((error as Error).name === "AbortError") {
        console.warn("Share was canceled by the user.");
      } else {
        captureMessage("An error occurred while sharing", {
          level: "error",
          extra: { error },
        });
      }
    }
  };

  return (
    <IonButton
      onClick={handleNavigatorShare}
      className="continue-button-stepper"
      shape="round"
    >
      <FormattedMessage id={isCopied ? "common.url_copied" : "common.share"} />
    </IonButton>
  );
};

export default DynamicShareButton;
