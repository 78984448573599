import "./ChartHeader.scss";

import { IonButton, IonIcon } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import { FormattedMessage } from "react-intl";

interface Props {
  year: number;
  isNextAvailable: boolean;
  isPreviousAvailable: boolean;
  onNextYear: () => void;
  onPreviousYear: () => void;
}

const ChartHeader = ({
  year,
  isNextAvailable,
  isPreviousAvailable,
  onNextYear,
  onPreviousYear,
}: Props) => {
  return (
    <>
      <div className="chart-header-desktop">
        <h5>
          <FormattedMessage id="chart.header" />
        </h5>

        <div className="chart-header-buttons">
          <h6>{year}</h6>
          <IonButton
            shape="round"
            disabled={!isPreviousAvailable}
            onClick={onPreviousYear}
          >
            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
          </IonButton>
          <IonButton
            shape="round"
            disabled={!isNextAvailable}
            onClick={onNextYear}
          >
            <IonIcon slot="icon-only" icon={chevronForwardOutline}></IonIcon>
          </IonButton>
        </div>
      </div>

      <div className="chart-header-mobile">
        <IonButton
          shape="round"
          disabled={!isPreviousAvailable}
          onClick={onPreviousYear}
        >
          <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
        </IonButton>
        <div className="chart-header-container">
          <h6>
            <FormattedMessage id="chart.header" />
          </h6>
          <p className="margin-0 bold">{year}</p>
        </div>
        <IonButton
          shape="round"
          disabled={!isNextAvailable}
          onClick={onNextYear}
        >
          <IonIcon slot="icon-only" icon={chevronForwardOutline}></IonIcon>
        </IonButton>
      </div>
    </>
  );
};

export default ChartHeader;
