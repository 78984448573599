import { isAfter, isBefore } from "date-fns";
import { useIntl } from "react-intl";

import { Availabilities } from "types/listing.types";

type AvailabilityValidation = (values: Availabilities["customSchema"]) => {
  isValid: boolean;
  error: string;
};

export const useValidateAvailabilities = (maxYearsInFuture = 2) => {
  const intl = useIntl();

  const validateAvailabilities: AvailabilityValidation = (values) => {
    if (!values) {
      return {
        isValid: false,
        error: intl.formatMessage({
          id: "listing.settings.availability.required",
        }),
      };
    }

    const now = new Date();

    const errors = values.map((availability) => {
      if (!availability.startDate || !availability.endDate) {
        return intl.formatMessage({
          id: "listing.settings.availability.required",
        });
      }

      if (
        isAfter(
          new Date(availability.startDate),
          new Date(availability.endDate),
        )
      ) {
        return intl.formatMessage({
          id: "review_listing_availability.date.error.start_later_than_end",
        });
      }

      if (
        isAfter(
          new Date(availability.startDate),
          new Date(
            new Date().setFullYear(now.getFullYear() + maxYearsInFuture),
          ),
        ) ||
        isAfter(
          new Date(availability.endDate),
          new Date(
            new Date().setFullYear(now.getFullYear() + maxYearsInFuture),
          ),
        )
      ) {
        return intl.formatMessage(
          {
            id: "review_listing_availability.date.error.too_far",
          },
          {
            years: maxYearsInFuture,
          },
        );
      }

      if (isBefore(new Date(availability.startDate), now)) {
        return intl.formatMessage({
          id: "review_listing_availability.date.error.start_in_past",
        });
      }

      return true;
    });

    if (errors.some((error) => error !== true)) {
      return {
        isValid: false,
        error: errors.find((error) => error !== true) || "",
      };
    }

    return {
      isValid: true,
      error: "",
    };
  };

  return validateAvailabilities;
};
