import { useContext, useLayoutEffect, useRef, useState } from "react";

import { useResizeObserver } from "hooks";
import { CalendarMarkingProps, DaySection } from "types/calendar.types";

import CalendarMarkingText from "components/@calendar/CalendarMarking/CalendarMarkingText";
import {
  getMarkingColors,
  MarkerHoverContext,
} from "components/@calendar/utils";
import Modal from "components/@common/Modal";
import ReservationDetail from "components/@reservations/ReservationDetail/ReservationDetail/ReservationDetail";

const MARKER_HEIGHT = "55%";

const CalendarMarking = ({
  daySection,
  type,
  description,
  title,
  id,
  blockSpan = 0,
  displayTitle,
  isInPast,
  listingId,
  onRemoveReservation,
}: CalendarMarkingProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // @TODO click to open reservation detail using id
  const { hoveredId: hoveredId, setHoveredId } = useContext(MarkerHoverContext);
  const ref = useRef<HTMLDivElement>(null);
  const [width] = useResizeObserver(ref);

  const colors = getMarkingColors(type, isInPast);

  const onClick = () => {
    setIsDialogOpen(true);
  };

  const [markingTextWidth, setMarkingTextWidth] = useState(50);
  useLayoutEffect(() => {
    // hacky way to get the width of the text
    if (!displayTitle) return;

    const markingBlocks = document.querySelectorAll(`[data-marker-id="${id}"]`);
    const firstBlockOfMarking = markingBlocks.item(0);
    const lastBlockOfMarking = markingBlocks.item(blockSpan);

    setMarkingTextWidth(
      lastBlockOfMarking?.getBoundingClientRect().left -
        firstBlockOfMarking?.getBoundingClientRect().left -
        (daySection === DaySection.MIDDLE && displayTitle
          ? 0
          : firstBlockOfMarking?.getBoundingClientRect().width / 2),
    );
  }, [displayTitle, blockSpan, id, daySection, width]);

  const isHovered = hoveredId === id;
  const boxShadow = isHovered ? `0px 4px 0px 0px ${colors.shadow}` : undefined;
  const boxShadowStart = isHovered
    ? `4px 4px 0px 0px ${colors.shadow}`
    : undefined;
  const boxShadowEnd = isHovered
    ? `4px 4px 0px 0px ${colors.shadow}`
    : undefined;
  const commonMarkerStyleProps: React.CSSProperties = {
    position: "absolute",
    height: MARKER_HEIGHT,
    backgroundColor: colors.background,
    transition: "all 0.2s",
    boxShadow,
  };

  // @TODO skewX with percentage height sometimes has a bug on the height in Chrome (+0.5px)
  // - see if we can set a fixed height instead of percentage
  // - see if we can use svg's instead for the skew effect (this would also improve the shadows maybe)

  return (
    <>
      <div
        data-marker-id={id}
        className="calendar-marking"
        onMouseEnter={() => setHoveredId(id)}
        onMouseLeave={() => setHoveredId(null)}
        style={{ cursor: "pointer" }}
        ref={ref}
        onClick={onClick}
      >
        {daySection === DaySection.START && (
          <>
            <div
              style={{
                right: "25%",
                width: "30%",
                transform: "skewX(-10deg)",
                ...commonMarkerStyleProps,
                boxShadow: boxShadowStart,
              }}
            />
            <div
              style={{
                right: 0,
                width: "45%",
                zIndex: 1,
                ...commonMarkerStyleProps,
              }}
            >
              {displayTitle && (
                <CalendarMarkingText
                  title={title}
                  description={description}
                  width={markingTextWidth}
                  colors={colors}
                />
              )}
            </div>
          </>
        )}
        {daySection === DaySection.MIDDLE && (
          <>
            <div
              style={{
                left: 0,
                right: 0,
                paddingLeft: 12,
                zIndex: displayTitle ? 1 : 0,
                ...commonMarkerStyleProps,
              }}
            >
              {displayTitle && (
                <CalendarMarkingText
                  title={title}
                  description={description}
                  width={markingTextWidth}
                  colors={colors}
                />
              )}
            </div>
          </>
        )}
        {daySection === DaySection.END && (
          <>
            <div
              style={{
                left: "5%",
                width: "30%",
                transform: "skewX(-10deg)",
                ...commonMarkerStyleProps,
                boxShadow: boxShadowEnd,
              }}
            />
            <div
              style={{
                left: 0,
                width: "25%",
                ...commonMarkerStyleProps,
              }}
            />
          </>
        )}
      </div>
      <Modal isOpen={isDialogOpen} onDidDismiss={() => setIsDialogOpen(false)}>
        <ReservationDetail
          listingId={listingId}
          reservationId={id}
          hideBack
          useDesktopButtons
          onClose={() => setIsDialogOpen(false)}
          onRemove={onRemoveReservation}
        />
      </Modal>
    </>
  );
};

export default CalendarMarking;
