import { IonIcon, IonText } from "@ionic/react";
import { warning } from "ionicons/icons";
import {
  Controller,
  FieldError,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { useIntl } from "react-intl";
import Select from "react-select";

type Props = Pick<UseControllerProps, "rules" | "name">;

import { ALPHA2_COUNTRY_CODES } from "constants/countries.constants";
import { useIntlDisplayNames } from "hooks";

const FormCountrySelect = ({ name, rules }: Props) => {
  const { control } = useFormContext();
  const intl = useIntl();
  const displayNames = useIntlDisplayNames();

  const getHelperText = (error?: FieldError) => {
    if (error?.message) return error.message;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => {
        let countryValue = value;
        try {
          countryValue = displayNames.of(value) || value;
        } catch (error) {}

        return (
          <div>
            <Select
              menuPortalTarget={document.body}
              onChange={(option) => onChange(option.code)}
              onBlur={onBlur}
              value={
                value && {
                  label: `${value} - ${countryValue}`,
                  code: value,
                }
              }
              options={ALPHA2_COUNTRY_CODES.map((code) => {
                let countryCode = code;
                try {
                  countryCode = displayNames.of(code) || code;
                } catch (error) {}

                return {
                  label: `${code} - ${countryCode}`,
                  code: code,
                };
              })}
              getOptionValue={(option) => option.code}
              getOptionLabel={(option) => option.label}
              placeholder={intl.formatMessage({ id: "country.placeholder" })}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                input: (base) => ({
                  ...base,
                  minHeight: "48px",
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,

                  boxShadow: "unset",
                  border: state.hasValue
                    ? "1px solid #c6b4fd"
                    : "1px solid #efebdc",
                  "&:hover": {
                    borderColor: "#4205fa",
                  },
                }),
                singleValue: (base) => ({
                  ...base,
                  color: "#241261",
                }),
                valueContainer: (base) => ({
                  ...base,
                  paddingLeft: "14px",
                  paddingRight: "14px",
                }),
                placeholder: (base) => ({
                  ...base,
                  color: "var(--ion-text-color-step-600)",
                }),
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  backgroundColor: isSelected
                    ? "#4205fa"
                    : isFocused
                      ? "#efebdc"
                      : undefined,
                  ":active": {
                    backgroundColor: !isSelected ? "#e7e2ca" : "#4205fa",
                  },
                }),
              }}
            />
            {getHelperText(error) && (
              <div className="helper-text-container">
                <IonIcon color="danger" icon={warning} />
                <IonText color="danger">
                  <p data-testid="helper-text" className="helper-text margin-0">
                    {getHelperText(error)}
                  </p>
                </IonText>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default FormCountrySelect;
