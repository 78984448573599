import { IonPage } from "@ionic/react";

import ConfirmPlanComponent from "components/@create-listing/ConfirmPlan";

const ConfirmPlan = () => {
  return (
    <IonPage>
      <ConfirmPlanComponent />
    </IonPage>
  );
};

export default ConfirmPlan;
