import { fetchAuthSession, JWT } from "aws-amplify/auth";
import { useEffect, useState } from "react";

import { captureMessage } from "utils/sentry.utils";

import { useCurrentUser } from "services/auth/useCurrentUser";

export const useIdToken = () => {
  const [idToken, setIdToken] = useState<JWT>();
  const { isLoggedIn } = useCurrentUser();

  useEffect(() => {
    const getIdToken = async () => {
      try {
        const currentSession = await fetchAuthSession({ forceRefresh: true });
        const token = currentSession.tokens?.idToken;
        setIdToken(token);
      } catch (error) {
        captureMessage("Error fetching idToken", {
          level: "error",
          extra: { error },
        });
      }
    };

    getIdToken();
  }, [isLoggedIn]);

  return idToken;
};

export default useIdToken;
