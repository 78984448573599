import { queryOptions } from "@tanstack/react-query";

import { apiService } from "services";

export const CALENDAR_PREFIX = "calendar";

export const getCalendarQuery = (
  listingId: string,
  startDate?: string,
  endDate?: string,
) =>
  queryOptions({
    queryKey: [CALENDAR_PREFIX, "byId", listingId, startDate, endDate],
    queryFn: () => apiService.getCalendarListing(listingId, startDate, endDate),
  });

// used to optimistically update multiple queries
export const simplifiedCalendarQueryKey = (listingId: string) => [
  CALENDAR_PREFIX,
  "byId",
  listingId,
];
