import { FormattedMessage } from "react-intl";

import ErrorCard from "components/@common/ErrorCard";

import { FILES_MAX_SIZE_MB } from "./constants";

interface Props {
  typeError: string;
  sizeError: string;
  lengthError: boolean;
  maxFiles?: number;
}

const UploadErrorCards = ({
  typeError,
  sizeError,
  lengthError,
  maxFiles,
}: Props) => {
  return (
    <>
      {typeError && (
        <ErrorCard
          title={
            <FormattedMessage
              id="complete_listing_photos.invalid_file_type"
              values={{ fileName: typeError, b: (b) => <b>{b}</b> }}
            />
          }
        />
      )}

      {sizeError && (
        <ErrorCard
          title={
            <FormattedMessage
              id="complete_listing_photos.file_size_exceeded"
              values={{
                size: FILES_MAX_SIZE_MB,
                fileName: sizeError,
                b: (b) => <b>{b}</b>,
              }}
            />
          }
        />
      )}

      {lengthError && (
        <ErrorCard
          title={
            <FormattedMessage
              id="complete_listing_photos.max_images_exceeded"
              values={{ maxImages: maxFiles }}
            />
          }
        />
      )}
    </>
  );
};

export default UploadErrorCards;
