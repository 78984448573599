export enum CreateListingAddressFormFields {
  Country = "country",
  Street = "street",
  AdditionalInformation = "additionalInformation",
  Zipcode = "zipcode",
  City = "city",
}

export enum CreateListingPropertyDetailsFormFields {
  PropertyType = "propertyType",
  PropertyKind = "propertyKind",
  Bathrooms = "bathrooms",
  Bedrooms = "bedrooms",
  Toilets = "toilets",
  MaximumOccupancy = "maximumOccupancy",
}

export enum PropertyDetailsType {
  Guests = "guests",
  Bedrooms = "bedrooms",
  Bathrooms = "bathrooms",
  Toilets = "toilets",
}

export enum CreateListingUrlFormFields {
  OriginalUrl = "originalUrl",
}

export enum CreateListingRequirementsFormFields {
  Requirements = "requirements",
}
