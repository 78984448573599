import { useQuery } from "@tanstack/react-query";

import { getAmenitiesQuery } from "./queries";

export const useAmenities = (
  options?: Partial<ReturnType<typeof getAmenitiesQuery>>,
) => {
  const { data, isFetching, error, isError } = useQuery({
    ...getAmenitiesQuery(),
    ...options,
  });
  return {
    data: data?.data,
    error,
    isLoading: isFetching,
    isError,
  };
};
