import { useMutation, useQueryClient } from "@tanstack/react-query";
import { signOut } from "aws-amplify/auth";

import { Routes } from "constants/routes.constants";

export const useSignOut = () => {
  const queryClient = useQueryClient();
  const { mutate, isPending, error } = useMutation({
    mutationFn: () => signOut(),
    onSuccess: () => {
      queryClient.clear();
      window.location.href = Routes.SignInContinue;
    },
  });
  return {
    signOut: mutate,
    isLoading: isPending,
    error,
  };
};
