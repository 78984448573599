import { createContext, ReactNode, useContext, useState } from "react";

import { FormProviders, formProviders } from "./FormSwitcher";

interface FormContextType {
  formState: FormProviders;
  setFormState: React.Dispatch<React.SetStateAction<FormProviders>>;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const useFormSwitch = (): FormContextType => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormSwitch must be used within a FormProvider");
  }
  return context;
};

const FormSwitchProvider = ({ children }: { children: ReactNode }) => {
  const [formState, setFormState] = useState<FormProviders>(formProviders[0]);

  return (
    <FormContext.Provider value={{ formState, setFormState }}>
      {children}
    </FormContext.Provider>
  );
};
export default FormSwitchProvider;
