import { IonChip } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import { ReservationStatus } from "../types";
import { getReservationStatus } from "../utils";

const reservationStatusMap: Record<
  ReservationStatus,
  { backgroundColor: string; color: string; title: I18nKey }
> = {
  [ReservationStatus.Canceled]: {
    backgroundColor: "rgba(244, 159, 249, 0.50)",
    color: "#B60CC0",
    title: "reservation.status.canceled",
  },
  [ReservationStatus.Completed]: {
    backgroundColor: "rgba(167, 251, 217, 0.50)",
    color: "var(--ion-color-success-tint)",
    title: "reservation.status.completed",
  },
  [ReservationStatus.Upcoming]: {
    backgroundColor: "#CAE2FE",
    color: "#002F64",
    title: "reservation.status.upcoming",
  },
  [ReservationStatus.HostingNow]: {
    backgroundColor: "rgba(167, 251, 217, 0.50)",
    color: "var(--ion-color-success-tint)",
    title: "reservation.status.hosting_now",
  },
};

type Props = {
  checkInDate: string;
  checkOutDate: string;
  status: string;
};

const ReservationStatusChip = ({
  checkInDate,
  checkOutDate,
  status,
}: Props) => {
  const displayStatus = getReservationStatus(checkInDate, checkOutDate, status);
  const params = reservationStatusMap[displayStatus];

  if (!params) {
    return null;
  }

  const { backgroundColor, color, title } = params;

  return (
    <IonChip
      style={{
        backgroundColor,
        color,
        cursor: "default",
        textTransform: "uppercase",
        fontWeight: 500,
        fontSize: 13,
        margin: 0,
        textAlign: "center",
        minHeight: 21,
        padding: "4px 8px",
      }}
    >
      <FormattedMessage id={title} />
    </IonChip>
  );
};

export default ReservationStatusChip;
