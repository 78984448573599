import "./Pagination.scss";

import { FormattedMessage } from "react-intl";

import { Meta } from "types/reservation.types";

import LoadingButton from "components/@common/LoadingButton";

interface Props {
  meta?: Meta;
  isLoading: boolean;
  disabled: boolean;
  onLoadMore: () => void;
}

const Pagination = ({ meta, isLoading, disabled, onLoadMore }: Props) => {
  if (!meta) {
    return null;
  }
  const { limit, pageNumber, total } = meta;

  const start = (pageNumber - 1) * limit + 1;
  const end = Math.min(pageNumber * limit, total);

  return (
    <div className="pagination">
      <FormattedMessage
        id="reservations_pagination"
        values={{ start, end, total }}
      />
      <div className="pagination-button-centered">
        <LoadingButton
          data-testid="load-more"
          className="contrast-button load-more"
          shape="round"
          onClick={onLoadMore}
          isLoading={isLoading}
          disabled={disabled}
        >
          <FormattedMessage id="common.load_more" />
        </LoadingButton>
      </div>
    </div>
  );
};

export default Pagination;
