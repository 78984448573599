import { IonPage } from "@ionic/react";

import PhotosComponent from "components/@complete-listing/Photos";

const Photos = () => {
  return (
    <IonPage>
      <PhotosComponent />
    </IonPage>
  );
};

export default Photos;
