import { useMutation } from "@tanstack/react-query";

import { apiService } from "services";

export const useRequestCancelReservation = () => {
  const { isPending, isPaused, mutate, error } = useMutation({
    mutationFn: ({
      listingId,
      reservationId,
      note,
    }: {
      listingId: string;
      reservationId: string;
      note: string;
    }) => apiService.requestCancelReservation(listingId, reservationId, note),
  });

  return {
    requestCancelReservation: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
