import "./PropertySummary.scss";

import { IonIcon } from "@ionic/react";
import { bedOutline, peopleOutline } from "ionicons/icons";
import { FormattedMessage, useIntl } from "react-intl";

import { propertyTypeKeys } from "constants/listing.constants";
import { PropertyKind, PropertyType } from "types/listing.types";

interface Props {
  propertyKind?: PropertyKind;
  propertyType?: PropertyType;
  maximumOccupancy?: number;
  bedrooms?: number;
  bathrooms?: number;
}

const PropertySummary = ({
  propertyKind,
  propertyType,
  maximumOccupancy,
  bedrooms,
  bathrooms,
}: Props) => {
  const intl = useIntl();

  const formatPropertyKindAndType = (
    propertyKind: PropertyKind,
    propertyType: PropertyType,
  ) => {
    return `${intl.formatMessage({
      id: propertyTypeKeys[propertyKind],
    })} · ${intl.formatMessage({
      id: propertyTypeKeys[propertyType],
    })}`;
  };

  return (
    <div className="summary-container">
      {propertyKind && propertyType && (
        <div className="icon-container">
          <IonIcon
            icon={
              propertyKind === PropertyKind.Place
                ? "assets/icons/icon-house.svg"
                : "assets/icons/icon-door.svg"
            }
            className="summary-icon"
          />
          <p className="margin-0 extra-small">
            {formatPropertyKindAndType(propertyKind, propertyType)}
          </p>
        </div>
      )}
      {maximumOccupancy && (
        <div className="icon-container">
          <IonIcon icon={peopleOutline} className="summary-icon" />
          <p className="margin-0 extra-small">
            <FormattedMessage
              id="create_listing.checkout.maximum_occupancy"
              values={{
                count: maximumOccupancy,
              }}
            />
          </p>
        </div>
      )}
      {bedrooms && (
        <div className="icon-container">
          <IonIcon icon={bedOutline} className="summary-icon" />
          <p className="margin-0 extra-small">
            <FormattedMessage
              id="create_listing.checkout.bedrooms"
              values={{
                count: bedrooms,
              }}
            />
          </p>
        </div>
      )}
      {bathrooms && (
        <div className="icon-container">
          <IonIcon icon="assets/icons/icon-bath.svg" className="summary-icon" />
          <p className="margin-0 extra-small">
            <FormattedMessage
              id="create_listing.checkout.bathrooms"
              values={{
                count: bathrooms,
              }}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default PropertySummary;
