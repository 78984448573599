import "./HouseRules.scss";

import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { CompleteListingProviderValues, HouseRule } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import FormIonTextArea from "components/@form/FormIonTextArea";
import FormIonToggle from "components/@form/FormIonToggle";
import FormSelect from "components/@form/FormSelect";

import { generateTimeOptions } from "../utils";

interface Props {
  error: Error | null;
  onSubmit: (values: { houseRules: Array<HouseRule> }) => void;
}

const HouseRulesForm = ({ error, onSubmit }: Props) => {
  const intl = useIntl();
  const { getValues, handleSubmit, setValue } =
    useFormContext<CompleteListingProviderValues>();

  const [showQuietTime, setShowQuietTime] = useState(
    !!(getValues("houseRules.5.value") || getValues("houseRules.6.value")),
  );

  useEffect(() => {
    if (!showQuietTime) {
      setValue(`houseRules.${5}.value`, "", { shouldValidate: true });
      setValue(`houseRules.${6}.value`, "", { shouldValidate: true });
    }
  }, [showQuietTime, setValue]);

  return (
    <>
      {error && (
        <div className="margin-24">
          <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />
        </div>
      )}

      <form id="hook-form-rules" onSubmit={handleSubmit(onSubmit)}>
        <p className="subtitle1 bold">
          <FormattedMessage id="complete_listing_house_rules.child_policy" />
        </p>

        <div className="children-policy">
          <FormIonToggle name={`houseRules.${0}.value`} labelPlacement="end">
            <FormattedMessage id="complete_listing_house_rules.children" />
          </FormIonToggle>

          <FormIonToggle name={`houseRules.${1}.value`} labelPlacement="end">
            <FormattedMessage id="complete_listing_house_rules.infants" />
          </FormIonToggle>
        </div>

        <div className="policies">
          <FormIonToggle name={`houseRules.${2}.value`} labelPlacement="end">
            <FormattedMessage id="complete_listing_house_rules.pets" />
          </FormIonToggle>

          <FormIonToggle name={`houseRules.${3}.value`} labelPlacement="end">
            <FormattedMessage id="complete_listing_house_rules.smoking" />
          </FormIonToggle>

          <FormIonToggle name={`houseRules.${4}.value`} labelPlacement="end">
            <FormattedMessage id="complete_listing_house_rules.events" />
          </FormIonToggle>

          <FormIonToggle
            name="showQuietTime"
            checked={showQuietTime}
            onIonChange={(e) => setShowQuietTime(e.detail.checked)}
            labelPlacement="end"
          >
            <FormattedMessage id="complete_listing_house_rules.quiet_time_toggle" />
          </FormIonToggle>
        </div>

        {showQuietTime && (
          <div className="quite-time-selects">
            <div className="quite-time-select">
              <p className="margin-0 extra-small">
                <FormattedMessage id="complete_listing_house_rules.quite_time_start" />
              </p>
              <FormSelect
                name={`houseRules.${5}.value`}
                options={generateTimeOptions()}
                placeholder={intl.formatMessage({
                  id: "complete_listing_house_rules.quite_time_start.placeholder",
                })}
              />
            </div>

            <div className="quite-time-select">
              <p className="margin-0 extra-small">
                <FormattedMessage id="complete_listing_house_rules.quite_time_end" />
              </p>
              <FormSelect
                name={`houseRules.${6}.value`}
                options={generateTimeOptions()}
                placeholder={intl.formatMessage({
                  id: "complete_listing_house_rules.quite_time_end.placeholder",
                })}
              />
            </div>
          </div>
        )}

        <p className="subtitle2 bold">
          <FormattedMessage id="complete_listing_house_rules.additional_rules" />
        </p>

        <p className="additional-description extra-small">
          <FormattedMessage id="complete_listing_house_rules.additional.description" />
        </p>

        <FormIonTextArea
          name={`houseRules.${7}.value`}
          labelPlacement="floating"
          fill="solid"
          placeholder={intl.formatMessage({
            id: "additional_house_rules.placeholder",
          })}
          label={intl.formatMessage({ id: "additional_house_rules.label" })}
        />
      </form>
    </>
  );
};

export default HouseRulesForm;
