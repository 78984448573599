import DOMPurify from "dompurify";

type Props = {
  html: string;
  className?: string;
};

const RenderHTML = ({ html, className }: Props) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html, {
          ADD_ATTR: ["target"],
          ADD_TAGS: ["a"],
          FORBID_TAGS: ["script"],
          FORBID_ATTR: ["style"],
        }),
      }}
    />
  );
};

export default RenderHTML;
