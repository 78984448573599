import {
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from "@ionic/react";
import { RefObject } from "react";

import { isAndroid } from "utils/capacitor.utils";

export type RefreshHandle = {
  refresh: () => void;
};

type Props = {
  dataRef: RefObject<RefreshHandle>;
};

const PullToRefresh = ({ dataRef }: Props) => {
  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    const refreshTimeout = setTimeout(() => {
      dataRef.current?.refresh();
      event.detail.complete();
    }, 500);
    return () => clearTimeout(refreshTimeout);
  };

  return (
    <IonRefresher
      slot="fixed"
      pullMin={20}
      pullMax={100}
      onIonRefresh={handleRefresh}
      pullFactor={0.9}
      style={{
        marginTop: isAndroid ? 48 : 0,
      }}
    >
      <IonRefresherContent />
    </IonRefresher>
  );
};

export default PullToRefresh;
