import "./SignUpWelcome.scss";

import {
  IonButton,
  IonImg,
  IonText,
  useIonRouter,
  useIonViewDidEnter,
} from "@ionic/react";
import { AnimatePresence, m, Variants } from "framer-motion";
import { cubicBezier } from "framer-motion";
import swirl from "images/big-swirl.png";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Routes } from "constants/routes.constants";

const variants: Variants = {
  initial: {
    opacity: 0,
    y: "100%",
  },
  animate: {
    opacity: 1,
    y: "0%",
    transition: {
      duration: 1.2,
      ease: cubicBezier(0.33, 1, 0.68, 1),
    },
  },
  exit: {
    opacity: 0,
    y: "-100%",
    transition: {
      duration: 2,
      ease: cubicBezier(0.37, 0, 0.63, 1),
    },
  },
};

const imgVariants: Variants = {
  initial: {
    opacity: 0,
    y: "100%",
  },
  animate: {
    opacity: 1,
    y: "0%",
    transition: {
      duration: 2,
      ease: cubicBezier(0.37, 0, 0.63, 1),
    },
  },
  exit: {
    opacity: 0,
    y: "-100%",
    transition: {
      duration: 1.2,
      ease: cubicBezier(0.33, 1, 0.68, 1),
    },
  },
};

const SignUpWelcome = () => {
  const router = useIonRouter();

  const [isExiting, setIsExiting] = useState(false);

  const handleExit = () => {
    setIsExiting(true);
    setTimeout(() => {
      router.push(Routes.Listings);
    }, 2000); // Delay must match the longest duration of exit animations
  };

  useIonViewDidEnter(() => {
    setIsExiting(false);
  });

  return (
    <AnimatePresence>
      <m.div className="welcome-animation">
        <m.div
          className="welcome-container"
          variants={variants}
          initial="initial"
          animate={isExiting ? "exit" : "animate"}
        >
          <IonText color="light">
            <h1 className="welcome-header">
              <FormattedMessage id="sign_up_welcome.title" />
            </h1>
          </IonText>
          <IonButton
            data-testid="welcome-button"
            onClick={handleExit}
            color="secondary"
            className="welcome-button"
          >
            <FormattedMessage id="sign_up_welcome.button.label" />
          </IonButton>
        </m.div>
        <m.div
          className="swirl-animation"
          variants={imgVariants}
          initial="initial"
          animate={isExiting ? "exit" : "animate"}
        >
          <IonImg src={swirl} className="swirl-image " />
        </m.div>
      </m.div>
    </AnimatePresence>
  );
};

export default SignUpWelcome;
