import "./ListingMarketing.scss";

import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { captureMessage } from "utils/sentry.utils";

import { useListing, useUpdateListing } from "queries";
import { OwnerType } from "types/listing.types";
import { Context } from "types/upload.types";

import FileSelector from "components/@common/FileSelector";
import { MAX_FILES } from "components/@common/FileSelector/constants";
import LinkUnstyled from "components/@common/LinkUnstyled";
import LoadingButton from "components/@common/LoadingButton";
import LoadingIndicator from "components/@common/LoadingIndicator";
import MarketingContent from "components/@common/MarketingContent.tsx/MarketingContent";
import Modal from "components/@common/Modal";
import ListingBreadcrumbs from "components/@listing-detail/ListingDetails/ListingBreadcrumbs/ListingBreadcrumbs";

import { getListingId, useOwnerType } from "../utils";

const ListingMarketing = () => {
  const listingId = getListingId();
  const {
    data: listing,
    isLoading,
    error,
    isError,
  } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });
  const { updateListing, isLoading: isLoadingUpdate } = useUpdateListing();
  const isReadOnly = useOwnerType(listing) === OwnerType.SUB;

  const [openPhotoUpload, setOpenPhotoUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [imageUrls, setImageUrls] = useState<Array<string>>([]);

  const handleSubmit = () => {
    updateListing(
      {
        id: listingId,
        attributes: {
          pictures: [...(listing?.attributes.pictures || []), ...imageUrls],
        },
      },
      {
        onSuccess: () => {
          setOpenPhotoUpload(false);
          setImageUrls([]);
        },
        onError: (updateError) => {
          captureMessage("Error adding photos in listing marketing", {
            level: "error",
            extra: { error: updateError },
          });
        },
      },
    );
  };

  return (
    <div className="listing-marketing">
      <ListingBreadcrumbs listing={listing} />
      <LoadingIndicator isOpen={isLoading} testId="ListingMarketing" />

      <h5 className="title">
        <FormattedMessage id="listing_marketing.title" />
      </h5>

      <MarketingContent
        data={listing}
        error={error}
        addPhotosButton={
          isError || isReadOnly ? null : (
            <LinkUnstyled onClick={() => setOpenPhotoUpload(true)}>
              <FormattedMessage id="complete_listing_photos.add_photos" />
            </LinkUnstyled>
          )
        }
      />

      {openPhotoUpload && (
        <Modal
          isOpen={openPhotoUpload}
          onDidDismiss={() => setOpenPhotoUpload(false)}
          continueButton={
            <LoadingButton
              data-testid="continue-button"
              className="continue-button-stepper"
              shape="round"
              expand="full"
              onClick={handleSubmit}
              disabled={isUploading || !imageUrls.length || isLoadingUpdate}
              isLoading={isLoadingUpdate}
            >
              <FormattedMessage id="common.continue" />
            </LoadingButton>
          }
        >
          <div className="add-photos-modal">
            <h5>
              <FormattedMessage id="listing_marketing.modal.title" />
            </h5>
            <p className="description">
              <FormattedMessage id="listing_marketing.modal.description" />
            </p>

            <FileSelector
              listingId={listingId}
              context={Context.Listing}
              type="photos"
              maxFiles={MAX_FILES}
              fileUrls={imageUrls}
              setIsUploading={setIsUploading}
              updateFileUrls={setImageUrls}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ListingMarketing;
