import { IonPage } from "@ionic/react";

import RequirementsComponent from "components/@create-listing/Requirements";

const Requirements = () => {
  return (
    <IonPage>
      <RequirementsComponent />
    </IonPage>
  );
};

export default Requirements;
