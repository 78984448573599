import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getListingQuery } from "queries/listings/queries";
import { apiService } from "services";

export const useInviteOwner = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: ({ id, email }: { id: string; email: string }) => {
      return apiService.inviteOwner(id, email);
    },
    onSuccess: (_, { id }) => {
      queryClient.invalidateQueries({
        queryKey: getListingQuery(id).queryKey,
      });
    },
  });

  return {
    inviteOwner: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
