import { isProd } from "utils/env.utils";

interface ENV_CONFIG {
  NAME: string;
  VERSION: string;
  API_BASE: string;
  API_REDIRECT_AMPLIFY_URL: string;
  API_SERVER_URL: string;
  AUTH_API_BASE: string;
  ANALYTICS_GOOGLE_TRACKING_ID?: string;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
  PHRASE: {
    PROJECT_ID: string;
    ACCOUNT_ID: string;
  };

  AWS_USER_POOL_CLIENT_ID: string;
  APP_AWS_USER_POOL_ID: string;
  APP_AWS_REGION: string;

  SENTRY_RELEASE: string;
  SENTRY_ENVIRONMENT: string;

  POSTHOG: {
    API_KEY: string;
    HOST: string;
  };

  S3_BUCKET: string;

  SCRAPER_X_API_KEY: string;
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || "",
  VERSION: process.env.REACT_APP_VERSION || "",

  // api
  API_BASE: process.env.REACT_APP_API_BASE || "",
  API_REDIRECT_AMPLIFY_URL:
    process.env.REACT_APP_API_REDIRECT_AMPLIFY_URL || "",

  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || "",
  AUTH_API_BASE: process.env.REACT_APP_AUTH_API_BASE || "",

  // analytics
  ANALYTICS_GOOGLE_TRACKING_ID:
    process.env.REACT_APP_ANALYTICS_GOOGLE_TRACKING_ID || "",

  // error tracking
  SENTRY: {
    dsn: "https://be0791500d28d6f5627d49eb8d0c3dd6@o190235.ingest.us.sentry.io/4507175547633664",
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // dev
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? "",
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? "EUR",
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? "nl-BE",
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? "nl",
  PHRASE: {
    PROJECT_ID: process.env.REACT_APP_PHRASE_PROJECT_ID || "",
    ACCOUNT_ID: process.env.REACT_APP_PHRASE_ACCOUNT_ID || "",
  },
  APP_AWS_REGION: process.env.REACT_APP_AWS_REGION || "",
  APP_AWS_USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID || "",
  AWS_USER_POOL_CLIENT_ID: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID || "",

  SENTRY_RELEASE: process.env.REACT_APP_SENTRY_RELEASE || "",
  SENTRY_ENVIRONMENT: process.env.REACT_APP_SENTRY_ENVIRONMENT || "",

  // posthog
  POSTHOG: {
    API_KEY: process.env.REACT_APP_POSTHOG_KEY || "",
    HOST: process.env.REACT_APP_POSTHOG_HOST || "",
  },

  S3_BUCKET: process.env.REACT_APP_S3_BUCKET || "",

  SCRAPER_X_API_KEY: process.env.REACT_APP_SCRAPER_X_API_KEY || "",
};

export default config;
