import { IonPage } from "@ionic/react";

import ComplianceComponent from "components/@complete-listing/Compliance";

const Compliance = () => {
  return (
    <IonPage>
      <ComplianceComponent />
    </IonPage>
  );
};

export default Compliance;
