import "./ErrorTable.scss";

import { IonIcon } from "@ionic/react";
import { FormattedMessage } from "react-intl";

import LinkUnstyled from "components/@common/LinkUnstyled";

interface Props {
  onRefetch: () => void;
}

const ErrorTable = ({ onRefetch }: Props) => {
  return (
    <div className="reservations-error">
      <IonIcon color="danger" src="assets/icons/icon-face-sad.svg" />

      <h6>
        <FormattedMessage id="reservations_error.title" />
      </h6>
      <p className="margin-0">
        <FormattedMessage id="reservations_error.description" />
      </p>

      <LinkUnstyled onClick={onRefetch}>
        <FormattedMessage id="reservations_error.retry" />
      </LinkUnstyled>
    </div>
  );
};

export default ErrorTable;
