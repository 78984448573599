import config from "config";

import RestApiService from "./api";
import { createManager } from "./api/axios";

export const apiService = new RestApiService({
  api: createManager({ requestConfig: { baseURL: config.API_BASE } }),
});

export const scraperApiService = new RestApiService({
  api: createManager({
    enableInterceptors: false,
    requestConfig: { baseURL: config.API_BASE },
  }),
});
