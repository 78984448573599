export const formatRating = (rating?: number) => {
  if (rating === undefined || rating === null) {
    return "-";
  }

  // Check if the rating is a whole number
  if (Number.isInteger(rating)) {
    return rating.toString();
  }

  // Otherwise, format to one decimal place and replace '.' with ','
  return rating.toFixed(1).replace(".", ",");
};
