import "./Chart.scss";

import { IonIcon } from "@ionic/react";
import { ResponsiveBar } from "@nivo/bar";
import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { useMediaQuery } from "hooks/useMediaQuery";
import { useGraphInformation } from "queries";
import { useListingInsights } from "queries/listings";
import { useIntlStore } from "store";

import Modal from "components/@common/Modal";
import { RenderHTML } from "components/@common/RenderHTML";

import { getListingId } from "../../utils";
import { ChartKey } from "../types";
import { TEST_DATA } from "./chart.test.data";
import { chartTheme } from "./chart.theme";
import {
  constructMonths,
  formatMonthWithLocale,
  formatTickValue,
} from "./chart.utils";
import ChartHeader from "./ChartHeader/ChartHeader";
import ChartLegend from "./ChartLegend/ChartLegend";
import ChartToolTip from "./ChartTooltip/ChartTooltip";
import useGridYValues from "./hooks";

const Chart = () => {
  const { locale } = useIntlStore((state) => state);
  const listingId = getListingId();
  const [year, setYear] = useState(new Date().getFullYear());

  const useShortMonth = useMediaQuery("(min-width: 1024px)");

  const { data: graphInfo } = useGraphInformation();
  const [openGraphInfo, setOpenGraphInfo] = useState(false);

  const { data, isNextAvailable, isPreviousAvailable, activeYear, isLoading } =
    useListingInsights(listingId, year);

  const months = useMemo(() => {
    const insightsData = isLoading
      ? TEST_DATA
      : data?.attributes?.monthlyInsights;

    return constructMonths(insightsData || []);
  }, [data?.attributes?.monthlyInsights, isLoading]);

  const gridYValues = useGridYValues(months);

  const getColors = (index: number, id: ChartKey) => {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    const pastColors = {
      [ChartKey.Revenue]: "var(--ion-color-secondary)",
      [ChartKey.CumulativeRevenue]: "rgba(233, 63, 243, 0.8)",
    };
    const futureColors = {
      [ChartKey.Revenue]: "rgba(179, 155, 253, 0.5)",
      [ChartKey.CumulativeRevenue]: "rgba(179, 155, 253, 0.2)",
    };

    // Determine colors based on the month being past or future
    const isFuture =
      activeYear > currentYear ||
      (activeYear === currentYear && index > currentMonth);
    return isFuture ? futureColors[id] : pastColors[id];
  };

  const handleNextYear = () => {
    if (isNextAvailable) {
      setYear((prevYear) => prevYear + 1);
    }
  };

  const handlePreviousYear = () => {
    if (isPreviousAvailable) {
      setYear((prevYear) => prevYear - 1);
    }
  };

  return (
    <>
      <div className="chart">
        <ChartHeader
          year={activeYear}
          isNextAvailable={isNextAvailable}
          isPreviousAvailable={isPreviousAvailable}
          onNextYear={handleNextYear}
          onPreviousYear={handlePreviousYear}
        />
        <div className="chart-grid">
          <div
            className={`chart-content ${isLoading ? "placeholder-blur" : ""}`}
          >
            <ResponsiveBar
              onMouseEnter={(_, event) => {
                const barElement = event.target as HTMLElement;
                barElement.style.stroke = "#fff";
                barElement.style.strokeWidth = "1";
              }}
              onMouseLeave={(_, event) => {
                const barElement = event.target as HTMLElement;
                barElement.style.stroke = "";
                barElement.style.strokeWidth = "";
              }}
              data={months}
              keys={[ChartKey.CumulativeRevenue, ChartKey.Revenue]}
              indexBy="index"
              margin={{ top: 50, right: 10, bottom: 50, left: 50 }}
              padding={0.3}
              valueScale={{ type: "linear" }}
              indexScale={{ type: "band", round: true }}
              maxValue={gridYValues ? gridYValues[gridYValues.length - 1] : 0}
              theme={chartTheme}
              colors={({ index, id }) => getColors(index, id as ChartKey)}
              enableLabel={false}
              tooltip={({ id, value, indexValue }) => (
                <ChartToolTip
                  id={id as ChartKey}
                  value={value}
                  month={formatMonthWithLocale(
                    Number(indexValue),
                    locale,
                    "long",
                  )}
                />
              )}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                truncateTickAt: 0,
                format: (value) =>
                  useShortMonth
                    ? formatMonthWithLocale(value, locale, "short")
                    : formatMonthWithLocale(value, locale, "short").charAt(0),
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                truncateTickAt: 0,
                tickValues: gridYValues,
                format: formatTickValue,
              }}
              gridYValues={gridYValues}
              role="application"
              ariaLabel="Listing insights bar chart"
              barAriaLabel={(e) =>
                e.id + ": " + e.formattedValue + "in month: " + e.indexValue
              }
            />
          </div>
          <ChartLegend
            revenueTotal={data?.attributes.revenueTotal}
            revenueUpcomingBookings={data?.attributes.revenueUpcomingBookings}
            revenueYtd={data?.attributes.revenueYtd}
            year={activeYear}
          />
        </div>
        <div className="chart-info">
          {graphInfo && (
            <>
              <IonIcon src="assets/icons/icon-alert.svg" />
              <button
                className="chart-info-button"
                type="button"
                onClick={() => setOpenGraphInfo(true)}
              >
                <p className="margin-0">
                  <FormattedMessage id="chart.info" />
                </p>
              </button>
            </>
          )}
        </div>
      </div>

      {graphInfo && openGraphInfo && (
        <Modal
          isOpen={openGraphInfo}
          onDidDismiss={() => setOpenGraphInfo(false)}
        >
          <RenderHTML html={graphInfo} className="chart-info-modal" />
        </Modal>
      )}
    </>
  );
};

export default Chart;
