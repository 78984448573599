import { IonButton, useIonRouter } from "@ionic/react";
import { forwardRef } from "react";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { ListingResponse, ListingStatus } from "types/listing.types";

type Props = {
  listing: ListingResponse["data"]["attributes"];
  listingId: string;
};

const ListingButton = forwardRef<HTMLIonButtonElement, Props>(
  ({ listing, listingId }, ref) => {
    const router = useIonRouter();

    /* debug button to go from Preparing to ReadyToPublish, should not be available after Husw integration for this is ready (maybe keep when isDev === true) */
    /* const { updateListing, isLoading } = useUpdateListing();
    if (listing.status === ListingStatus.Preparing) {
      return (
        <LoadingButton
          isLoading={isLoading}
          color="light"
          shape="round"
          onClick={() =>
            updateListing({
              id: listingId,
              attributes: { status: ListingStatus.ReadyToPublish },
            })
          }
        >
          Set to Ready to Publish (debug button)
        </LoadingButton>
      );
    } */

    if (listing.status === ListingStatus.Active) {
      return (
        <IonButton
          ref={ref}
          className="light-primary-button"
          shape="round"
          onClick={() =>
            router.push(
              generatePath(Routes.ListingOverview, { listingId: listingId }),
            )
          }
        >
          <FormattedMessage id="listing_overview.view_details" />
        </IonButton>
      );
    }

    if (listing.status === ListingStatus.ReadyToPublish) {
      return (
        <IonButton
          ref={ref}
          color="primary"
          shape="round"
          onClick={() =>
            router.push(
              generatePath(Routes.ReviewListingLaunchpad, {
                listingId: listingId,
              }),
            )
          }
        >
          <FormattedMessage id="listing_overview.set_availabilities" />
        </IonButton>
      );
    }

    if (
      [ListingStatus.New, ListingStatus.Onboarding].includes(listing.status)
    ) {
      return (
        <IonButton
          ref={ref}
          color="primary"
          shape="round"
          onClick={() =>
            router.push(
              generatePath(
                listing.status === ListingStatus.New
                  ? Routes.CreateListingLaunchpad
                  : Routes.CompleteListingLaunchpad,
                { listingId: listingId },
              ),
            )
          }
        >
          <FormattedMessage id="listing_overview.complete_listing" />
        </IonButton>
      );
    }

    return null;
  },
);

ListingButton.displayName = "ListingButton";

export default ListingButton;
