export enum LoginFormFields {
  Email = "email",
  Password = "password",
}

export enum SignUpDetailsFormFields {
  Email = "email",
  FirstName = "firstName",
  LastName = "lastName",
  Phone = "phone",
  Subscribe = "subscribe",
}

export enum ResetPasswordFormFields {
  Code = "code",
  Password = "password",
  ConfirmPassword = "confirmPassword",
}
