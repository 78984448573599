import { queryOptions } from "@tanstack/react-query";

import { apiService } from "services";

export const LISTING_PREFIX = "listing";

export const getListingQuery = (id: string) =>
  queryOptions({
    queryKey: [LISTING_PREFIX, "byId", id],
    queryFn: () => apiService.getListing(id),
  });

export const getListingInsightsQuery = (id: string, year: number) =>
  queryOptions({
    queryKey: [LISTING_PREFIX, "byYear", id, year],
    queryFn: () => apiService.getListingInsights(id, year),
  });

export const getAmenitiesQuery = () =>
  queryOptions({
    queryKey: [LISTING_PREFIX, "amenities"],
    queryFn: () => apiService.getAmenities(),
  });

export const getOtasQuery = () =>
  queryOptions({
    queryKey: [LISTING_PREFIX, "otas"],
    queryFn: () => apiService.getOtas(),
  });

export const getListingSuccessManagerQuery = (id: string) =>
  queryOptions({
    queryKey: [LISTING_PREFIX, "successManager", id],
    queryFn: () => apiService.getListingSuccessManager(id),
  });
