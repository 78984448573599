import { App as CapacitorApp } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { useEffect } from "react";

export const useLockScreenOrientation = () => {
  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      ScreenOrientation.lock({ orientation: "portrait" });
    }

    return () => {
      CapacitorApp.removeAllListeners();
    };
  }, []);
};
