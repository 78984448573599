// discriminated union type
type RNWebViewEvent = {
  event: "SignIn";
  token?: string;
};

export const postMessageToRNWebView = (event: RNWebViewEvent) => {
  if (window && window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(event));
  }
};
