import { infiniteQueryOptions, queryOptions } from "@tanstack/react-query";

import { apiService } from "services";

import {
  ReservationFilters,
  SortDirection,
} from "components/@reservations/Reservations/types";

export const RESERVATION_PREFIX = "reservation";
export const BLOCK_PREFIX = "block";

export const getReservationsQuery = (
  limit?: number,
  sortBy?: string,
  sortDirection?: SortDirection,
  filters?: ReservationFilters,
  search?: string,
) =>
  infiniteQueryOptions({
    queryKey: [
      RESERVATION_PREFIX,
      "all",
      limit,
      sortBy,
      sortDirection,
      filters,
      search,
    ],
    queryFn: ({ pageParam = 1 }) =>
      apiService.getReservations(
        pageParam,
        limit,
        sortBy,
        sortDirection,
        filters,
        search,
      ),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const currentPage = lastPage.data.meta.pageNumber;
      const totalPages = Math.ceil(
        lastPage.data.meta.total / lastPage.data.meta.limit,
      );
      return currentPage < totalPages ? currentPage + 1 : undefined;
    },
    select: (data) => {
      const allData = data.pages.flatMap((page) => page.data.data);
      const lastPageMeta = data.pages[data.pages.length - 1].data.meta;

      return {
        data: allData,
        meta: lastPageMeta,
      };
    },
  });

export const getReservationQuery = (listingId: string, reservationId: string) =>
  queryOptions({
    queryKey: [RESERVATION_PREFIX, "byId", listingId, reservationId],
    queryFn: () => apiService.getReservation(listingId, reservationId),
  });

export const getBlockQuery = (listingId: string, blockageId: string) =>
  queryOptions({
    queryKey: [BLOCK_PREFIX, "byId", listingId, blockageId],
    queryFn: () => apiService.getBlock(listingId, blockageId),
  });
