import { useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";
import { ListingResponse } from "types/listing.types";

import Breadcrumbs from "components/@routes/Breadcrumbs";

interface Props {
  listing: ListingResponse["data"] | undefined;
}

const ListingBreadcrumbs = ({ listing }: Props) => {
  const intl = useIntl();

  return (
    <Breadcrumbs
      items={[
        {
          href: Routes.Listings,
          label: intl.formatMessage({ id: "breadcrumbs.listings" }),
        },
        {
          label:
            listing?.attributes.title ||
            listing?.attributes.nickname ||
            listing?.attributes.guestyId ||
            listing?.id ||
            "",
        },
      ]}
    />
  );
};

export default ListingBreadcrumbs;
