import { init, reactRouterV5BrowserTracingIntegration } from "@sentry/react";
import { createBrowserHistory } from "history";

import config from "config";

const history = createBrowserHistory();

export const initWebSentry = () => {
  init({
    ...config.SENTRY,
    tracesSampleRate: 1,
    integrations: [
      reactRouterV5BrowserTracingIntegration({
        history,
      }),
    ],
  });
};
