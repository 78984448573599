import { IonContent, IonPage } from "@ionic/react";

import { SignUpWelcome } from "components/@authentication/SignUpWelcome";

const Welcome = () => {
  return (
    <IonPage>
      <IonContent fullscreen scrollY={false} scrollX={false}>
        <SignUpWelcome />
      </IonContent>
    </IonPage>
  );
};

export default Welcome;
