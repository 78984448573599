import { fetchAuthSession } from "aws-amplify/auth";
import { AxiosInstance } from "axios";

const updateMethods = ["post", "put", "patch"];

export const createHeaderRequestInterceptor = (axios: AxiosInstance) => {
  axios.interceptors.request.use(async (request) => {
    request.headers = request.headers ?? {};

    const currentSession = await fetchAuthSession();
    const jwtToken = currentSession.tokens?.accessToken;

    if (jwtToken) {
      request.headers.Authorization = `${jwtToken}`;
    }

    if (request.method && updateMethods.includes(request.method)) {
      request.headers["Content-Type"] = "application/vnd.api+json";
    }

    return request;
  });
};
