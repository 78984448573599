import { StatusBar, Style } from "@capacitor/status-bar";

import { isAndroid } from "utils/capacitor.utils";

type Props = {
  color?: string;
  iconsColor?: "light" | "dark";
};

export const useAndroidStatusBar = ({
  color = "#f7f5ed",
  iconsColor = "dark",
}: Props) => {
  if (!isAndroid) return;
  StatusBar.setBackgroundColor({ color: color });
  StatusBar.setStyle({
    style: iconsColor === "light" ? Style.Dark : Style.Light,
  });
};
