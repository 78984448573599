import { useMutation } from "@tanstack/react-query";

import { scraperApiService } from "services";
import { ScraperListingPayload } from "types/scraper.types";

export const useListingScraper = () => {
  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: (payload: ScraperListingPayload) =>
      scraperApiService.postListingScraper(payload),
  });

  return {
    triggerScraper: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
