import "./Navigation.scss";

import { IonRouterOutlet, IonSpinner } from "@ionic/react";
import { useEffect, useState } from "react";
import { matchPath, Redirect, useHistory, useLocation } from "react-router";
import { Route } from "react-router-dom";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";
import { useIsEmailVerified } from "hooks/useIsEmailVerified";
import { useCurrentUser } from "services/auth/useCurrentUser";

import CompleteListingAbout from "pages/complete-listing/About";
import CompleteListingAmenities from "pages/complete-listing/Amenities";
import CompleteListingCompliance from "pages/complete-listing/Compliance";
import CompleteListingHouseRules from "pages/complete-listing/HouseRules";
import CompleteListingLaunchpad from "pages/complete-listing/Launchpad";
import CompleteListingOverview from "pages/complete-listing/Overview";
import CompleteListingPhotos from "pages/complete-listing/Photos";
import CompleteListingSleepingArrangements from "pages/complete-listing/SleepingArrangements";
import CreateListingAddress from "pages/create-listing/Address";
import CreateListingCheckout from "pages/create-listing/Checkout";
import CreateListingConfirmPlan from "pages/create-listing/ConfirmPlan";
import CreateListingLaunchpad from "pages/create-listing/Launchpad";
import CreateListingPropertyDetails from "pages/create-listing/PropertyDetails";
import CreateListingRequirements from "pages/create-listing/Requirements";
import CreateListingRequirementsFail from "pages/create-listing/RequirementsFail";
import CreateListingSegmentation from "pages/create-listing/Segmentation";
import CreateListingSuccess from "pages/create-listing/Success";
import CreateListingUrl from "pages/create-listing/Url";
import CreateReservationDetails from "pages/create-reservation/Details";
import CreateReservationOverview from "pages/create-reservation/Overview";
import CreateReservationType from "pages/create-reservation/Type";
import ListingView from "pages/listings/ListingView";
import ReservationDetail from "pages/reservations/ReservationDetail";
import ReviewListingAvailability from "pages/review-listing/Availability";
import ReviewListingLaunchpad from "pages/review-listing/Launchpad";
import ReviewListingShowcase from "pages/review-listing/Showcase";
import ReviewListingSuccess from "pages/review-listing/Success";
import Callback from "pages/sign-in/Callback";
import SignInContinue from "pages/sign-in/Continue";
import ForgotPassword from "pages/sign-in/ForgotPassword";
import Login from "pages/sign-in/Login";
import ResetPasswordConfirm from "pages/sign-in/ResetPasswordConfirm";
import ResetPasswordOtp from "pages/sign-in/ResetPasswordOtp";
import ResetPasswordSuccess from "pages/sign-in/ResetPasswordSuccess";
import VerifyEmail from "pages/sign-in/verifyEmail";
import SignUpContinue from "pages/sign-up/Continue";
import CreateAccount from "pages/sign-up/CreateAccount";
import SignUpDetails from "pages/sign-up/Details";
import SignUpWelcome from "pages/sign-up/Welcome";

import {
  FormSwitcher,
  FormSwitchProvider,
} from "components/@form/FormSwitcher";
import {
  authenticatedRoutes,
  unauthenticatedRoutes,
  untabbedRoutes,
} from "components/@routes/AppRoutes/constants";
import TopNav from "components/@routes/AppRoutes/TopNav";

import TabsNavigation from "./TabsNavigation";

const AppRoutes = () => {
  const location = useLocation();
  const history = useHistory();
  const { isLoggedIn, isLoading, user } = useCurrentUser();
  const profileCompleted = !!user?.data.attributes.profileCompleted;
  const [showNavigation, setShowNavigation] = useState(false);

  const emailVerified = useIsEmailVerified();

  useEffect(() => {
    const matches = untabbedRoutes.some((untabbedRoute) =>
      matchPath(location.pathname, { path: untabbedRoute }),
    );
    setShowNavigation(!matches);
  }, [location.pathname]);

  // Handle redirection based on authentication status. This is necessary because
  // IonRouterOutlet does not unmount components which can lead to unwanted side effects when using ProtectedRoutes
  // This effect will redirect to the correct route based on the authentication status and the profile completion status
  useEffect(() => {
    const isPathAuthenticated =
      !unauthenticatedRoutes.some((unauthenticatedRoute) =>
        matchPath(location.pathname, { path: unauthenticatedRoute }),
      ) ||
      authenticatedRoutes.some((authenticatedRoute) =>
        matchPath(location.pathname, { path: authenticatedRoute }),
      );

    if (!isLoading) {
      if (isPathAuthenticated && !isLoggedIn) {
        // Redirect unauthenticated user to login page
        return history.push(Routes.SignInContinue);
      } else if (
        !profileCompleted &&
        isLoggedIn &&
        location.pathname !== Routes.SignUpDetails &&
        location.pathname !== Routes.SignUpWelcome
      ) {
        return history.push(Routes.SignUpDetails);
      } else if (!isPathAuthenticated && isLoggedIn) {
        return history.push(Routes.Listings);
      }
    }
  }, [history, isLoading, isLoggedIn, location.pathname, profileCompleted]);

  if (isLoading) {
    return (
      <div
        style={{
          height: "100svh",
          width: "100svw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IonSpinner style={{ width: 60, height: 60 }} />
      </div>
    );
  }

  return (
    <div
      className={[
        showNavigation && "show-navigation",
        emailVerified === false ? "extra-header-padding" : "header-padding",
      ]
        .filter(Boolean)
        .join(" ")}
    >
      {showNavigation && <TopNav />}

      {/* 
        Form providers which cover multiple routes should be placed outside of the IonRouterOutlet
        -> grouping the routes in the form provider will make the route itself not a direct child of IonRouterOutlet,
           which will break the navigation, animations and back gestures
        -> the providers switch depending on the current route, so the form state is maintained across the grouped routes
        -> add more form providers as needed in the FormSwitcher.tsx component
      */}
      {/* 
        @TODO: Swipe back on iOS will make page transition play again after the page is already rendered
        -> need to find a way to prevent this
      */}
      <FormSwitchProvider>
        <FormSwitcher>
          <IonRouterOutlet id="main" animated={isNative}>
            {/* 
              Place all tab-routes inside the TabNavigation component and use the render method
              - Also make sure the tab route paths start with "/tab" (adjust naming as needed but make sure it's consistent)
            */}
            <Route
              path="/tab"
              render={() => <TabsNavigation showNavigation={showNavigation} />}
            />

            {/* 
                  All non-tab routes should be placed as direct children of IonRouterOutlet
                  -> otherwise, the navigation will break and the animations won't work
                */}

            {/* Listings */}
            <Route path={Routes.ListingView} component={ListingView} />
            {/* Listing Creation */}
            <Route
              path={Routes.CreateListingLaunchpad}
              component={CreateListingLaunchpad}
            />
            <Route
              path={Routes.CreateListingAddress}
              component={CreateListingAddress}
            />
            <Route
              path={Routes.CreateListingRequirements}
              component={CreateListingRequirements}
            />
            <Route
              path={Routes.CreateListingSegmentation}
              component={CreateListingSegmentation}
            />
            <Route
              path={Routes.CreateListingRequirementsFail}
              component={CreateListingRequirementsFail}
            />
            <Route
              path={Routes.CreateListingPropertyDetails}
              component={CreateListingPropertyDetails}
            />
            <Route
              path={Routes.CreateListingUrl}
              component={CreateListingUrl}
            />
            <Route
              path={Routes.CreateListingConfirmPlan}
              component={CreateListingConfirmPlan}
            />
            <Route
              path={Routes.CreateListingCheckout}
              component={CreateListingCheckout}
            />
            <Route
              path={Routes.CreateListingSuccess}
              component={CreateListingSuccess}
            />

            {/* Listing Completion */}
            <Route
              path={Routes.CompleteListingLaunchpad}
              component={CompleteListingLaunchpad}
            />
            <Route
              path={Routes.CompleteListingAbout}
              component={CompleteListingAbout}
            />
            <Route
              path={Routes.CompleteListingAmenities}
              component={CompleteListingAmenities}
            />
            <Route
              path={Routes.CompleteListingSleepingArrangements}
              component={CompleteListingSleepingArrangements}
            />
            <Route
              path={Routes.CompleteListingPhotos}
              component={CompleteListingPhotos}
            />
            <Route
              path={Routes.CompleteListingHouseRules}
              component={CompleteListingHouseRules}
            />
            <Route
              path={Routes.CompleteListingCompliance}
              component={CompleteListingCompliance}
            />
            <Route
              path={Routes.CompleteListingOverview}
              component={CompleteListingOverview}
            />
            {/* Listing Review */}
            <Route
              path={Routes.ReviewListingLaunchpad}
              component={ReviewListingLaunchpad}
            />
            <Route
              path={Routes.ReviewListingShowcase}
              component={ReviewListingShowcase}
            />
            <Route
              path={Routes.ReviewListingAvailability}
              component={ReviewListingAvailability}
            />
            <Route
              path={Routes.ReviewListingSuccess}
              component={ReviewListingSuccess}
            />

            {/* Reservations */}
            <Route
              path={Routes.ReservationDetails}
              component={ReservationDetail}
            />
            {/* Reservation Creation */}
            <Route
              path={Routes.CreateReservationType}
              component={CreateReservationType}
            />
            <Route
              path={Routes.CreateReservationDetails}
              component={CreateReservationDetails}
            />
            <Route
              path={Routes.CreateReservationOverview}
              component={CreateReservationOverview}
            />
            <Redirect
              exact
              path={Routes.CreateReservation}
              to={Routes.CreateReservationType}
            />

            {/* Auth */}
            <Route path={Routes.Callback} component={Callback} />
            {/* Auth - Sign in */}
            <Route path={Routes.SignInContinue} component={SignInContinue} />
            <Route path={Routes.Login} component={Login} />
            <Route path={Routes.ForgotPassword} component={ForgotPassword} />
            <Route path={Routes.ResetPassword} component={ResetPasswordOtp} />
            <Route
              path={Routes.ResetPasswordConfirm}
              component={ResetPasswordConfirm}
            />
            <Route
              path={Routes.ResetPasswordSuccess}
              component={ResetPasswordSuccess}
            />
            <Route path={Routes.VerifyEmail} component={VerifyEmail} />
            {/* Auth - Sign up */}
            <Route path={Routes.SignUpContinue} component={SignUpContinue} />
            <Route path={Routes.CreateAccount} component={CreateAccount} />
            <Route path={Routes.SignUpDetails} component={SignUpDetails} />
            <Route path={Routes.SignUpWelcome} component={SignUpWelcome} />

            <Redirect exact from="/" to={Routes.Listings} />
          </IonRouterOutlet>
        </FormSwitcher>
      </FormSwitchProvider>
    </div>
  );
};

export default AppRoutes;
