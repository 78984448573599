import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";
import { Route } from "types/route.types";

const createListingStepsLookup: Partial<Record<Route, number>> = {
  [Routes.CreateListingAddress]: 1,
  [Routes.CreateListingPropertyDetails]: 2,
  [Routes.CreateListingUrl]: 3,
  [Routes.CreateListingRequirements]: 4,
  [Routes.CreateListingConfirmPlan]: 5,
  [Routes.CreateListingCheckout]: 6,
};

const completeListingStepsLookup: Partial<Record<Route, number>> = {
  [Routes.CompleteListingAbout]: 1,
  [Routes.CompleteListingAmenities]: 2,
  [Routes.CompleteListingSleepingArrangements]: 3,
  [Routes.CompleteListingPhotos]: 4,
  [Routes.CompleteListingHouseRules]: 5,
  [Routes.CompleteListingCompliance]: 6,
  [Routes.CompleteListingOverview]: 7,
};

const reviewListingStepsLookup: Partial<Record<Route, number>> = {
  [Routes.ReviewListingShowcase]: 1,
  [Routes.ReviewListingAvailability]: 2,
};

const createReservationStepsLookup: Partial<Record<Route, number>> = {
  [Routes.CreateReservationType]: 1,
  [Routes.CreateReservationDetails]: 2,
  [Routes.CreateReservationOverview]: 3,
};

const stepsLookupMap: Record<Flow, Partial<Record<Route, number>>> = {
  [Flow.CreateListing]: createListingStepsLookup,
  [Flow.CompleteListing]: completeListingStepsLookup,
  [Flow.ReviewListing]: reviewListingStepsLookup,
  [Flow.CreateReservation]: createReservationStepsLookup,
};

export const getProgressValue = (flow: Flow, route?: Route): number => {
  const lookup = stepsLookupMap[flow];

  if (!route || !lookup || !lookup[route]) return 0;

  const totalSteps = Object.keys(lookup).length;
  const stepIndex = lookup[route];
  return stepIndex / totalSteps;
};
