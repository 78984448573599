import "./Callback.scss";

import { IonPage, IonSpinner, useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import { Routes } from "constants/routes.constants";

import ErrorCard from "components/@common/ErrorCard";
import LogoContainer from "components/@common/LogoContainer";

const Callback = () => {
  const [message, setMessage] = useState(false);
  const router = useIonRouter();
  const location = useLocation();

  useEffect(() => {
    // Set a timeout to display the error message after 5 seconds
    const timer = setTimeout(() => {
      setMessage(true);
      // Set another timeout to redirect after 10 more seconds
      setTimeout(() => {
        if (location.pathname === Routes.Callback) {
          router.push(Routes.SignInContinue, "back");
        }
      }, 10000);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  }, [location.pathname, router]);

  if (location.pathname !== Routes.Callback) {
    return null;
  }

  return (
    <IonPage>
      <LogoContainer>
        <div className="spinner-container">
          {message && (
            <ErrorCard
              title={
                <FormattedMessage
                  id="callback.error_message"
                  values={{
                    b: (chunks) => <b>{chunks}</b>,
                  }}
                />
              }
            />
          )}
          <IonSpinner className="spinner" name="crescent" />
        </div>
      </LogoContainer>
    </IonPage>
  );
};

export default Callback;
