import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import SuccessComponent from "components/@create-listing/Success";

const Success = () => {
  useAndroidStatusBar({ color: "#241261", iconsColor: "light" });
  return (
    <IonPage>
      <SuccessComponent />
    </IonPage>
  );
};

export default Success;
