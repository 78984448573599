import "./FilePreview.scss";

import { IonIcon, IonImg } from "@ionic/react";
import { documentOutline } from "ionicons/icons";

import { canPreviewFile } from "components/@common/FileSelector/FilePreview/utils";

interface Props {
  fileUrls: Array<string>;
}

const handleOpenFile = (fileUrl: string) => {
  window.open(fileUrl, "_blank");
};

const getTitle = (fileUrl: string) => {
  try {
    const url = new URL(fileUrl);
    return decodeURI(url.pathname).split("/").pop();
  } catch (error) {
    return "";
  }
};

const FilePreview = ({ fileUrls }: Props) => {
  return (
    <div className="file-overview picture-container">
      {fileUrls.map((file, index) => (
        <div
          key={index}
          className="picture-border-radius"
          title={getTitle(file)}
          onClick={() => handleOpenFile(file)}
        >
          {canPreviewFile(file) ? (
            <IonImg key={index} src={file} className="picture" />
          ) : (
            <IonIcon icon={documentOutline} />
          )}
        </div>
      ))}
    </div>
  );
};

export default FilePreview;
