type Props = {
  title: string;
  description: string;
  width: number;
  colors: {
    title: string;
    description: string;
  };
};

const CalendarMarkingText = ({ title, description, width, colors }: Props) => {
  return (
    <div
      className="calendar-marking-text"
      style={{
        width,
        height: "100%",
        whiteSpace: "nowrap",
        textWrap: "nowrap",
        overflow: "hidden",
        display: "flex",
      }}
    >
      <span
        style={{
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "160%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: colors.title,
        }}
        title={title}
      >
        {title}
      </span>
      <span
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: colors.description,
        }}
        title={description}
      >
        {description}
      </span>
    </div>
  );
};

export default CalendarMarkingText;
