import { useEffect } from "react";
import {
  DeepPartial,
  DefaultValues,
  FieldValues,
  FormProvider,
  Mode,
  Resolver,
  useForm,
  UseFormReturn,
} from "react-hook-form";

export type SubmitHandler<TFieldValues extends FieldValues> = (
  data: TFieldValues,
  event: React.BaseSyntheticEvent,
  methods: UseFormReturn<TFieldValues>,
) => void;

interface Props<FormData extends FieldValues> {
  onSubmit: SubmitHandler<FormData>;
  resolver?: Resolver<FormData>;
  defaultValues?: DefaultValues<FormData>;
  values?: DeepPartial<FormData>;
  mode?: Mode;
  children:
    | React.ReactNode
    | ((methods: UseFormReturn<FormData>) => React.ReactNode);
  formId?: string;
}

const Form = <FormData extends FieldValues>({
  defaultValues,
  onSubmit,
  children,
  values,
  resolver,
  mode,
  formId,
}: Props<FormData>) => {
  const methods = useForm<FormData>({
    resolver,
    defaultValues,
    mode,
    reValidateMode: "onSubmit",
  });

  useEffect(() => {
    if (values) {
      methods.reset(values as DefaultValues<FormData>);
    }
  }, [methods, values]);

  return (
    <FormProvider {...methods}>
      <form
        id={formId}
        noValidate
        autoComplete="off"
        onSubmit={methods.handleSubmit((data, event) =>
          event ? onSubmit(data, event, methods) : undefined,
        )}
      >
        {children instanceof Function ? children(methods) : children}
      </form>
    </FormProvider>
  );
};

export default Form;
