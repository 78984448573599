import { IonContent } from "@ionic/react";
import { useRef } from "react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { IOSSafeArea } from "components/@common/IOSSafeArea";
import { PullToRefresh, RefreshHandle } from "components/@common/PullToRefresh";
import VerifyEmailBanner from "components/@common/VerifyEmailBanner";
import ListingTableComponent from "components/@listing-table/ListingTable/ListingTable";

const ListingTable = () => {
  const listingTableRef = useRef<RefreshHandle>(null);
  useAndroidStatusBar({ iconsColor: "dark" });

  return (
    <>
      <IOSSafeArea />
      <IonContent className="listing-table-ion-content">
        <PullToRefresh dataRef={listingTableRef} />
        <div className="ion-tab-page android-top-padding">
          <VerifyEmailBanner type="mobile" />
          <ListingTableComponent ref={listingTableRef} />
        </div>
      </IonContent>
    </>
  );
};

export default ListingTable;
