import "./Success.scss";

import {
  IonButton,
  IonContent,
  IonIcon,
  IonImg,
  IonText,
  useIonRouter,
} from "@ionic/react";
import Spline from "@splinetool/react-spline";
import { m, Variants } from "framer-motion";
import { cubicBezier } from "framer-motion";
import { FormattedMessage } from "react-intl";

import { isNative } from "utils/capacitor.utils";

import { Routes } from "constants/routes.constants";

const variants: Variants = {
  initial: {
    opacity: 0,
    y: "100%",
  },
  animate: {
    opacity: 1,
    y: "0%",
    transition: {
      delay: 2.5,
      duration: 1.2,
      ease: cubicBezier(0.33, 1, 0.68, 1),
    },
  },
  exit: {
    opacity: 0,
    y: "-100%",
    transition: {
      duration: 2,
      ease: cubicBezier(0.37, 0, 0.63, 1),
    },
  },
};

const Success = () => {
  const router = useIonRouter();

  /* @TODO
    - Make sure spline is downloaded before getting to this step (maybe preload on previous step or in CreateListingProvider)
    - Use correct spline scene (checked with Tom Wuyts, needs to be delivered by Dima)
    - Check how large Spline dependency & scene are, consider lazy loading
  */

  return (
    <IonContent>
      <div className="success-page">
        <Spline
          scene="https://prod.spline.design/d1vimelhwmbkdVJq/scene.splinecode"
          style={{ position: "absolute", pointerEvents: "none" }}
        />
        <m.div
          className="success-container"
          variants={variants}
          initial="initial"
          animate="animate"
        >
          <IonText>
            <h3 className="success-header">
              <FormattedMessage id="review_listing_success.title" />
            </h3>
          </IonText>

          <p className="margin-0">
            <FormattedMessage
              id="review_listing_success.appreciation"
              values={{
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </p>

          <div className="review-listing-success-card">
            <p className="bold margin-0">
              <FormattedMessage id="review_listing_success.card.title" />
            </p>
            <div className="review-card-list-item">
              <IonIcon icon="assets/icons/icon-bell.svg" />
              <p className="margin-0">
                <FormattedMessage id="review_listing_success.card.list_item_1" />
              </p>
            </div>

            <div className="review-card-list-item">
              <IonIcon icon="assets/icons/icon-chart.svg" />
              <p className="margin-0">
                <FormattedMessage id="review_listing_success.card.list_item_2" />
              </p>
            </div>

            <div className="review-card-list-item">
              <IonIcon icon="assets/icons/icon-tickets.svg" />
              <p className="margin-0">
                <FormattedMessage id="review_listing_success.card.list_item_3" />
              </p>
            </div>

            {!isNative && (
              <div className="qr-code-container">
                <div className="qr-code">
                  <IonImg className="qr-img" src="assets/images/qr-ios.png" />
                  <a
                    href="https://apps.apple.com/be/app/huswell/id6446033040"
                    data-testid="app-store-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <IonImg src="assets/images/app-store-badge.png" />
                  </a>
                </div>
                <div className="qr-code">
                  <IonImg
                    className="qr-img"
                    src="assets/images/qr-android.png"
                  />
                  <a
                    href="https://play.google.com/store/apps/details?id=be.huswell.app&hl=en_US"
                    data-testid="play-store-link"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <IonImg src="assets/images/play-store-badge.png" />
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className="margin-16">
            <IonButton
              data-testid="success-review-button"
              onClick={() => router.push(Routes.Listings)}
              shape="round"
              className="contrast-button width-100 underline"
            >
              <FormattedMessage id="review_listing_success.button.label" />
            </IonButton>
          </div>
        </m.div>
      </div>
    </IonContent>
  );
};

export default Success;
