import "./MarketingContent.scss";

import { IonIcon, IonImg } from "@ionic/react";
import { chevronDownOutline } from "ionicons/icons";
import { ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import { ListingResponse } from "types/listing.types";

import ErrorCard from "../ErrorCard";
import LinkUnstyled from "../LinkUnstyled";
import SparkleCard from "../SparkleCard";
import ShowcaseContent from "./ShowcaseContent";

const AMOUNT_OF_PICTURES_TO_SHOW = 6;

interface Props {
  data?: ListingResponse["data"];
  error: Error | null;
  addPhotosButton?: ReactNode;
}

const MarketingContent = ({ data, error, addPhotosButton }: Props) => {
  const [showAll, setShowAll] = useState(false);

  const pictures = data?.attributes.pictures || [];
  const imagesToShow = showAll
    ? pictures
    : pictures.slice(0, AMOUNT_OF_PICTURES_TO_SHOW);
  const showMoreCount = pictures.length - AMOUNT_OF_PICTURES_TO_SHOW;
  const title = data?.attributes.title || "";

  const {
    summary = "",
    neighbourhood = "",
    transit = "",
    notes = "",
    interactionWithGuests = "",
    space = "",
    access = "",
  } = data?.attributes.marketingProperties || {};

  return (
    <div className="marketing-content-container">
      {error && (
        <ErrorCard title={<FormattedMessage id="common.error_unknown" />} />
      )}

      <div className="photos-title-container">
        <h6 className="margin-0">
          <FormattedMessage id="review_listing_showcase.photos" />
        </h6>

        {addPhotosButton}
      </div>

      <SparkleCard title="review_listing_showcase.photos.sparkle.title" />

      {!!imagesToShow.length && (
        <div className="showcase-grid">
          {imagesToShow.map((picture, index) => (
            <IonImg //TODO: view full image on click
              src={picture}
              key={index}
            />
          ))}
        </div>
      )}

      {!showAll && showMoreCount > 0 && (
        <div className="show-all-link">
          <LinkUnstyled onClick={() => setShowAll(true)}>
            <div className="wrapper">
              <FormattedMessage
                id="review_listing_showcase.show_all_link"
                values={{ count: showMoreCount }}
              />
              <IonIcon
                color="primary"
                className="icon"
                icon={chevronDownOutline}
              />
            </div>
          </LinkUnstyled>
        </div>
      )}

      <h6 className="margin-top-24">
        <FormattedMessage id="review_listing_showcase.content" />
      </h6>

      <SparkleCard title="review_listing_showcase.content.sparkle.title" />

      <ShowcaseContent
        title={title}
        summary={summary}
        space={space}
        access={access}
        interactionWithGuests={interactionWithGuests}
        neighbourhood={neighbourhood}
        transit={transit}
        notes={notes}
      />
    </div>
  );
};

export default MarketingContent;
