import { useMemo } from "react";

import { MonthlyInsight } from "types/listing.types";

const useGridYValues = (months: Array<MonthlyInsight>) => {
  return useMemo(() => {
    const maxStackedValue = Math.max(
      ...months.map((month) => month.revenue + month.cumulativeRevenue),
    );

    // Determine the appropriate increment for grid lines
    let increment;
    if (maxStackedValue == 0) {
      return undefined;
    } else if (maxStackedValue < 1000) {
      increment = 100; // Set increment to 100 if max value is below 1000
    } else if (maxStackedValue < 10000) {
      increment = 1000; // Set increment to 1000 if max value is below 10,000
    } else {
      increment = 10000; // Default increment for values 10,000 and above
    }

    const gridYValues = [];
    for (let i = 0; i <= maxStackedValue + increment; i += increment) {
      gridYValues.push(i);
    }

    return gridYValues;
  }, [months]);
};

export default useGridYValues;
