export enum CompleteListingAboutFormFields {
  Title = "title",
  Neighborhood = "neighborhood",
  Unit = "unit",
  Region = "region",
  Appartment = "appartment",
  FloorNumber = "floorNumber",
  FloorAmount = "floorAmount",
}

export enum CompleteListingAmenitiesFormFields {
  Amenities = "amenities",
}

export enum CompleteListingHouseRulesFormFields {
  children = "suitable_for_children",
  infants = "suitable_for_infants",
  pets = "pets_allowed",
  smoking = "smoking_allowed",
  events = "events_allowed",
  quietTimeStart = "quite_time_start",
  quietTimeEnd = "quite_time_end",
  additionalRules = "additional_rules",
}
