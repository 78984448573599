import { useMutation, useQueryClient } from "@tanstack/react-query";

import { accountUserQuery } from "queries/authentication/queries";
import { apiService } from "services";
import { UpdateUserAttributes } from "types/user.types";

interface Variables {
  userId: string;
  attributes?: UpdateUserAttributes;
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: ({ userId, attributes }: Variables) =>
      apiService.updateUser(userId, attributes),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: accountUserQuery.queryKey });
    },
  });

  return {
    updateUser: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
