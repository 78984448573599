import { useCallback, useState } from "react";

import { SortDirection } from "./types";

type TableSortInput = {
  initialSortDirection: SortDirection;
  initialSortBy: string;
};
type TableSortOutput = {
  sortBy: string;
  sortDirection: SortDirection;
  handleTableSort: (
    property: string,
    initialSortDirection?: SortDirection,
  ) => void;
  resetTableSort: () => void;
};

export const useTableSort = ({
  initialSortDirection,
  initialSortBy,
}: TableSortInput): TableSortOutput => {
  const [sortDirection, setSortDirection] =
    useState<SortDirection>(initialSortDirection);
  const [sortByColumn, setSortByColumn] = useState<string>(initialSortBy);

  const handleTableSort = useCallback(
    (property: string, initialSortDirection?: SortDirection) => {
      if (sortByColumn !== property) {
        setSortByColumn(property);
        setSortDirection(initialSortDirection || SortDirection.Asc);
        return;
      } else {
        if (sortDirection === SortDirection.Desc) {
          setSortDirection(SortDirection.Asc);
          return;
        } else {
          setSortDirection(SortDirection.Desc);
          return;
        }
      }
    },
    [sortByColumn, sortDirection],
  );

  const resetTableSort = useCallback(() => {
    setSortDirection(initialSortDirection);
    setSortByColumn(initialSortBy);
  }, [initialSortBy, initialSortDirection]);

  return {
    sortBy: sortByColumn,
    sortDirection,
    handleTableSort,
    resetTableSort,
  };
};
