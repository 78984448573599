import { IonContent } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { IOSSafeArea } from "components/@common/IOSSafeArea";
import PreferencesComponent from "components/@preferences/Preferences";

const Preferences = () => {
  useAndroidStatusBar({ iconsColor: "dark" });
  return (
    <>
      <IOSSafeArea />
      <IonContent>
        <PreferencesComponent />
      </IonContent>
    </>
  );
};

export default Preferences;
