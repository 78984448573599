import "./PasswordCriteriaSuccess.scss";

import { IonIcon } from "@ionic/react";
import { shieldCheckmark } from "ionicons/icons";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  minHeight?: number;
}

const PasswordCriteriaSuccess = ({ children, minHeight }: Props) => {
  return (
    <div
      className="requirement-container margin-16"
      style={{ minHeight: minHeight }}
    >
      <IonIcon icon={shieldCheckmark} className="requirement-icon" />
      <p className="margin-0">{children}</p>
    </div>
  );
};

export default PasswordCriteriaSuccess;
