import "./ButtonUnstyled.scss";

import { ReactNode } from "react";

interface Props {
  onClick: () => void;
  children?: ReactNode;
  id?: string;
}

const ButtonUnstyled = ({ onClick, children, id }: Props) => {
  return (
    <button onClick={onClick} className="button-unstyled" id={id} type="button">
      {children}
    </button>
  );
};

export default ButtonUnstyled;
