import { IonIcon } from "@ionic/react";
import { addMonths, format, subMonths } from "date-fns";
import { chevronBack, chevronForward } from "ionicons/icons";
import { useRef } from "react";

interface Props {
  value: Date;
  onChange: (value: Date) => void;
}

const CalendarDatePicker = ({ value, onChange }: Props) => {
  const datePickerRef = useRef<HTMLInputElement>(null);

  const openDatePicker = () => {
    try {
      datePickerRef.current?.focus();
      datePickerRef.current?.click();
      datePickerRef.current?.showPicker();
    } catch (error) {}
  };

  const setToPreviousMonth = () => {
    onChange(subMonths(value, 1));
  };
  const setToNextMonth = () => {
    onChange(addMonths(value, 1));
  };

  return (
    <div style={{ display: "flex", height: 40 }}>
      <button
        onClick={setToPreviousMonth}
        style={{ ...arrowButtonStyles, borderRadius: "20px 0px 0px 20px" }}
      >
        <IonIcon color="white" icon={chevronBack} />
      </button>
      <button
        onClick={openDatePicker}
        style={{
          minWidth: 160,
          padding: "10px 12px",
          margin: "0 1px",
          fontWeight: 500,
          position: "relative",
          color: "var(--ion-color-white)",
          backgroundColor: "var(--ion-color-primary)",
        }}
      >
        <input
          value={format(value, "yyyy-MM-dd")}
          type="date" // @TODO use type="month" when it's supported?
          onChange={(e) =>
            onChange(e.target.value ? new Date(e.target.value) : new Date())
          }
          required
          ref={datePickerRef}
          id="calendar-datepicker"
          // hacky way to hide the input but still make it focusable so it closes on blur (visibility="hidden" doesn't work for this)
          style={{
            bottom: 0,
            opacity: 0,
            width: 0,
            height: 0,
            zIndex: -1,
            position: "absolute",
          }}
        />
        {format(value, "MMMM yyyy")}
      </button>
      <button
        onClick={setToNextMonth}
        style={{ ...arrowButtonStyles, borderRadius: "0px 20px 20px 0px" }}
      >
        <IonIcon color="white" icon={chevronForward} />
      </button>
    </div>
  );
};

const arrowButtonStyles = {
  color: "white",
  display: "flex",
  width: "40px",
  height: "40px",
  padding: "8px",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "var(--ion-color-primary)",
};

export default CalendarDatePicker;
