import { IonPage } from "@ionic/react";

import UrlComponent from "components/@create-listing/Url";

const Url = () => {
  return (
    <IonPage>
      <UrlComponent />
    </IonPage>
  );
};

export default Url;
