import "./Details.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useCallback } from "react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";
import { ReservationType } from "types/reservation.types";

import SparkleCard from "components/@common/SparkleCard";
import StepperContainer from "components/@common/StepperContainer";
import ReservationEditDetailsForm from "components/@create-reservation/Details/ReservationEditDetailsForm";

const Details = () => {
  const router = useIonRouter();

  const listingId = useWatch({ name: "listingId" });
  const reservationId = useWatch({ name: "reservationId" });
  const reservationType = useWatch({ name: "reservationType" });

  const isBlock = reservationType === ReservationType.Block;

  const backRoute =
    new URLSearchParams(window.location.search).get("backroute") ||
    Routes.Reservations;

  const handleBack = useCallback(() => {
    router.push(
      generatePath(Routes.CreateReservationType + `?backroute=${backRoute}`, {
        listingId,
        reservationId: reservationId ? reservationId : undefined,
      }),
      "none",
    );
  }, [listingId, reservationId, router, backRoute]);

  const onSubmit = () => {
    router.push(
      generatePath(
        Routes.CreateReservationOverview + `?backroute=${backRoute}`,
        {
          listingId,
          reservationId: reservationId ? reservationId : undefined,
        },
      ),
    );
  };

  return (
    <StepperContainer
      flow={Flow.CreateReservation}
      route={Routes.CreateReservationDetails}
      backRoute={backRoute}
      continueButton={
        <IonButton
          data-testid="continue-button"
          className="continue-button-stepper"
          type="submit"
          shape="round"
          expand="full"
          form="hook-form-details"
        >
          <FormattedMessage id="common.continue" />
        </IonButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <div className="create-reservation-details">
        <h2 className="details-title">
          <FormattedMessage
            id={
              isBlock
                ? "create_reservation_details._block.title"
                : "create_reservation_details.title"
            }
          />
        </h2>

        <SparkleCard
          title={
            isBlock
              ? "create_reservation_details_block.sparkle_card_title"
              : "create_reservation_details.sparkle_card_title"
          }
          icon="assets/icons/icon-info-circle.svg"
        />

        <ReservationEditDetailsForm onSubmit={onSubmit} />
      </div>
    </StepperContainer>
  );
};

export default Details;
