import { IonPage } from "@ionic/react";

import ShowcaseComponent from "components/@review-listing/Showcase";

const Showcase = () => {
  return (
    <IonPage>
      <ShowcaseComponent />
    </IonPage>
  );
};

export default Showcase;
