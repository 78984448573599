import { IonPage } from "@ionic/react";

import { SignUpDetails } from "components/@authentication/SignUpDetails";
import LogoContainer from "components/@common/LogoContainer";

const Details = () => {
  return (
    <IonPage>
      <LogoContainer>
        <SignUpDetails />
      </LogoContainer>
    </IonPage>
  );
};

export default Details;
