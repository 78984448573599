import { IonPage } from "@ionic/react";

import OverviewComponent from "components/@complete-listing/Overview";

const Checkout = () => {
  return (
    <IonPage>
      <OverviewComponent />
    </IonPage>
  );
};
export default Checkout;
