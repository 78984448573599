import "./OverviewCard.scss";

import { IonText, useIonRouter } from "@ionic/react";
import { ReactNode } from "react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Route } from "types/route.types";

import LinkUnstyled from "components/@common/LinkUnstyled";

interface Props {
  title: I18nKey;
  route?: Route;
  children: ReactNode;
}

const OverviewCard = ({ title, route, children }: Props) => {
  const router = useIonRouter();
  const listingId = useWatch({ name: "listingId" });

  const onClickChange = () => {
    if (!route) return;
    router.push(
      generatePath(route, {
        listingId,
      }),
      "back",
    );
  };

  return (
    <div className="overview-card">
      <div className="overview-content">
        <IonText color="primary">
          <p className="small margin-0 bold uppercase">
            <FormattedMessage id={title} />
          </p>
        </IonText>

        <div className="overview-content">{children}</div>
      </div>

      {route && (
        <LinkUnstyled onClick={onClickChange}>
          <FormattedMessage id="create_listing_overview.change" />
        </LinkUnstyled>
      )}
    </div>
  );
};

export default OverviewCard;
