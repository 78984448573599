import {
  differenceInCalendarDays,
  endOfDay,
  getDay,
  isSameDay,
  isSameMonth,
  isToday,
  startOfDay,
} from "date-fns";
import { createContext } from "react";

import {
  CalendarMarkingProps,
  CalendarMarkingType,
  DaySection,
} from "types/calendar.types";

export const getCalendarValues = (pickedDate: Date) => {
  const firstDayOfMonth = new Date(
    pickedDate.getFullYear(),
    pickedDate.getMonth(),
    1,
  );
  const lastDayOfMonth = new Date(
    pickedDate.getFullYear(),
    pickedDate.getMonth() + 1,
    0,
  );
  const amountOfDays = lastDayOfMonth.getDate();
  const amountOfEmptyDays = (firstDayOfMonth.getDay() + 6) % 7;

  const firstDayOfCalendar = new Date(
    pickedDate.getFullYear(),
    pickedDate.getMonth(),
    1 - amountOfEmptyDays,
  );
  const lastDayOfCalendar = new Date(
    pickedDate.getFullYear(),
    pickedDate.getMonth(),
    amountOfDays + (7 - lastDayOfMonth.getDay()),
  );

  const amountOfWeekRows = Math.ceil((amountOfDays + amountOfEmptyDays) / 7);

  return { firstDayOfCalendar, lastDayOfCalendar, amountOfWeekRows };
};

export const isDateInRange = (date: Date, range: [Date, Date]) => {
  return date >= startOfDay(range[0]) && date <= endOfDay(range[1]);
};

// we assume days will be in range to simplify typing
export const getDaySection = (date: Date, range: [Date, Date]): DaySection => {
  if (isSameDay(date, range[0])) {
    return DaySection.START;
  }
  if (isSameDay(date, range[1])) {
    return DaySection.END;
  }

  return DaySection.MIDDLE;
};

export const getDayColor = (
  date: Date,
  selectedDate: Date,
  calendarMarkings: Array<CalendarMarkingProps>,
): string => {
  if (isToday(date)) return "var(--ion-color-white)";
  if (!isSameMonth(selectedDate, date)) return "var(--ion-text-color-step-600)";
  if (calendarMarkings.length) return "var(--ion-text-color-step-900)";
  return "var(--ion-color-primary)";
};

export const getMarkingColors = (
  type: CalendarMarkingType,
  isInPast: boolean,
): {
  background: string;
  shadow: string;
  title: string;
  description: string;
} => {
  switch (type) {
    case CalendarMarkingType.RESERVATION:
      return {
        background: isInPast ? "#9ef6ce" : "var(--ion-color-success)",
        shadow: "#94ad9d",
        title: "var(--ion-color-dark)",
        description: "var(--ion-color-success-tint)",
      };
    case CalendarMarkingType.OWNER_RESERVATION:
      return {
        background: isInPast ? "#f6caf3" : "var(--ion-color-secondary-shade)",
        shadow: "#ef6ff6",
        title: "var(--ion-color-secondary-tint)",
        description: "var(--ion-color-secondary-tint)",
      };
    case CalendarMarkingType.BLOCK:
      return {
        background: isInPast ? "#eeede7" : "#DAD9D8",
        shadow: "#c1bfbd",
        title: "var(--ion-color-dark)",
        description: "var(--ion-color-dark-tint)",
      };
  }
};

export const getMarkingParams = ({
  date,
  startDate,
  endDate,
  weekIndex,
  weekDayIndex,
}: {
  date: Date;
  startDate: Date;
  endDate: Date;
  weekIndex: number;
  weekDayIndex: number;
}) => {
  const daySection = getDaySection(date, [startDate, endDate]);

  // We display the title if
  // - this is the first day of a marking
  // - this is the first day in the calendar and it's a MIDDLE block
  const displayTitle =
    daySection === DaySection.START ||
    (daySection === DaySection.MIDDLE && weekIndex === 0 && weekDayIndex === 0);

  let blockSpan = 0;

  if (displayTitle) {
    const dayOfWeek = getDay(date);
    const daysRemainingInWeek = (7 - dayOfWeek) % 7;

    blockSpan = Math.min(
      differenceInCalendarDays(endDate, date),
      daysRemainingInWeek,
    );
  }

  return {
    daySection,
    displayTitle,
    /* if this is the first day of a marking, we figure out how many more marking days will be displayed after this -in the current week-
    This is needed to calculate when we need to truncate the text. */
    blockSpan,
  };
};

export const MarkerHoverContext = createContext<{
  hoveredId: string | null;
  setHoveredId: (id: string | null) => void;
}>({ hoveredId: null, setHoveredId: () => null });
