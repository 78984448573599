import "./RequirementsFail.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { Routes } from "constants/routes.constants";
import { Flow } from "types/flow.types";

import StepperContainer from "components/@common/StepperContainer";

const RequirementsFail = () => {
  const router = useIonRouter();

  const listingId = useWatch({ name: "listingId" });

  const handleBack = () => {
    router.push(
      generatePath(Routes.CreateListingRequirements, {
        listingId,
      }),
      "back",
    );
  };

  return (
    <StepperContainer flow={Flow.CreateListing}>
      <h2 className="margin-24">
        <FormattedMessage id="create_listing_requirements_fail.title" />
      </h2>

      <p className="create-listing-description whitespace">
        <FormattedMessage id="create_listing_requirements_fail.description" />
      </p>
      <div className="requirements-button-container">
        <IonButton
          className="continue-button-stepper"
          shape="round"
          onClick={() => router.push(Routes.Listings, "back")}
        >
          <FormattedMessage id="requirements_fail.home_button" />
        </IonButton>
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="requirements_fail.mistake_button" />
        </IonButton>
      </div>
    </StepperContainer>
  );
};

export default RequirementsFail;
