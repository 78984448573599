import { useMutation, useQueryClient } from "@tanstack/react-query";

import { accountUserQuery } from "queries/authentication/queries";
import { apiService } from "services";
import { useCurrentUser } from "services/auth/useCurrentUser";

interface Variables {
  token: string;
}

export const useUpdateUserToken = () => {
  const queryClient = useQueryClient();
  const { user } = useCurrentUser();

  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: ({ token }: Variables) => {
      if (!user?.data.id) {
        throw new Error("User is not logged in");
      }
      return apiService.updateUser(user.data.id, { fcmToken: token });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: accountUserQuery.queryKey });
    },
  });

  return {
    updateUserToken: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
