import { useInfiniteQuery } from "@tanstack/react-query";

import { StaleTime } from "queries/types";

import {
  ReservationFilters,
  SortDirection,
} from "components/@reservations/Reservations/types";

import { getReservationsQuery } from "./queries";

export const LISTING_PREFIX = "reservation";

interface Params {
  limit?: number;
  sortBy?: string;
  sortDirection?: SortDirection;
  filters?: ReservationFilters;
  search?: string;
}

export const useInfiniteReservations = ({
  limit = 10,
  filters,
  sortBy,
  sortDirection,
  search,
}: Params) => {
  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
    data,
    error,
    refetch,
  } = useInfiniteQuery({
    ...getReservationsQuery(limit, sortBy, sortDirection, filters, search),
    staleTime: StaleTime.FIVE_MIN,
  });

  return {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isFetching,
    isError,
    refetch,
    error,
    data,
  };
};
