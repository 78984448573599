import { Routes } from "constants/routes.constants";
import { Route } from "types/route.types";

// all other routes are authenticated
export const unauthenticatedRoutes: Array<Route> = [
  Routes.SignIn,
  Routes.SignUp,
  Routes.Callback,
];

// routes that would match with the above routes, but shouldn't redirect to the app
export const authenticatedRoutes: Array<Route> = [
  Routes.SignUpDetails,
  Routes.SignUpWelcome,
  Routes.VerifyEmail,
];

export const untabbedRoutes: Array<Route> = [
  Routes.SignUp,
  Routes.SignIn,
  Routes.CreateListing,
  Routes.CompleteListing,
  Routes.ReviewListing,
  Routes.ReservationDetails,
  Routes.CreateReservation,
  Routes.Callback,
];
