import { useIonRouter } from "@ionic/react";
import { ReactNode, useEffect } from "react";

import CompleteListingProvider from "components/@complete-listing/CompleteListingProvider";
import CreateListingProvider from "components/@create-listing/CreateListingProvider";
import CreateReservationProvider from "components/@create-reservation/CreateReservationProvider";
import ReviewListingProvider from "components/@review-listing/ReviewListingProvider";

import { useFormSwitch } from "./FormSwitchProvider";

const FormProviderMap = {
  "/create-listing": CreateListingProvider,
  "/complete-listing": CompleteListingProvider,
  "/review-listing": ReviewListingProvider,
  "/create-reservation": CreateReservationProvider,
  // Add new form providers here, use the start of the path as the key and the form provider as the value
};

export type FormProviders = keyof typeof FormProviderMap;
export const formProviders = Object.keys(
  FormProviderMap,
) as Array<FormProviders>;

const FormSwitcher = ({ children }: { children: ReactNode }) => {
  const { formState, setFormState } = useFormSwitch();
  const router = useIonRouter();

  useEffect(() => {
    // Checks if current path is a form provider path and sets form state accordingly
    const path = router.routeInfo.pathname;
    Object.keys(FormProviderMap).forEach((key) => {
      if (path.startsWith(key)) {
        setFormState(key as FormProviders);
      }
    });
  }, [formState, router.routeInfo.pathname, setFormState]);

  const FormProvider = FormProviderMap[formState];
  return <FormProvider>{children}</FormProvider>;
};

export default FormSwitcher;
