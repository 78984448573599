import "./PasswordCriteria.scss";

import { IonIcon, IonItem, IonList, IonText } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import { FormattedMessage, useIntl } from "react-intl";

interface Criteria {
  length: boolean;
  lower: boolean;
  upper: boolean;
  number: boolean;
}

interface CriteriaText {
  length: string;
  lower: string;
  upper: string;
  number: string;
}

export const validatePassword = (password: string): Criteria => {
  return {
    length: password.length >= 12,
    lower: /[a-z]/.test(password),
    upper: /[A-Z]/.test(password),
    number: /\d/.test(password),
  };
};

interface Props {
  password: string;
}

const PasswordCriteria = ({ password }: Props) => {
  const intl = useIntl();

  const criteriaText: CriteriaText = {
    length: intl.formatMessage({ id: "password_criteria.length" }),
    lower: intl.formatMessage({ id: "password_criteria.lower" }),
    upper: intl.formatMessage({ id: "password_criteria.upper" }),
    number: intl.formatMessage({ id: "password_criteria.number" }),
  };

  const criteria = validatePassword(password);

  return (
    <IonList className="ion-list">
      <p className="criteria-title">
        <IonText>
          <FormattedMessage id="password_criteria.title" />
        </IonText>
      </p>
      {Object.keys(criteria).map((key) => {
        const typedKey = key as keyof Criteria;
        const success = criteria[typedKey];
        return (
          <IonItem className="criteria" key={key}>
            {success && (
              <IonIcon
                data-testid="checkmark-icon"
                aria-hidden="true"
                icon={checkmarkOutline}
                slot="start"
                className={
                  success ? "criteria-success criteria-icon" : "criteria-icon"
                }
              />
            )}
            {!success && <div className="criteria-dot">•</div>}
            <IonText className={success ? "criteria-success" : undefined}>
              {criteriaText[typedKey]}
            </IonText>
          </IonItem>
        );
      })}
    </IonList>
  );
};

export default PasswordCriteria;
