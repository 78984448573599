import { useQuery } from "@tanstack/react-query";

import { getListingInsightsQuery } from "./queries";

export const useListingInsights = (
  id: string,
  year?: number,
  options?: Partial<ReturnType<typeof getListingInsightsQuery>>,
) => {
  const activeYear = year || new Date().getFullYear();

  const {
    data: insightsData,
    isLoading,
    error,
    isError,
  } = useQuery({
    ...getListingInsightsQuery(id, activeYear),
    ...options,
  });

  const data = insightsData?.data?.data;

  return {
    data: data,
    isNextAvailable: !!data?.meta?.isNextAvailable,
    isPreviousAvailable: !!data?.meta?.isPreviousAvailable,
    activeYear,
    error,
    isLoading,
    isError,
  };
};
