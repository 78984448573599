import * as Sentry from "@sentry/capacitor";

import config from "config";

export const initNativeSentry = () => {
  Sentry.init({
    ...config.SENTRY,
    tracesSampleRate: 1,
    dist: config.VERSION,
  });
};
