import { IonContent, IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { IOSSafeArea } from "components/@common/IOSSafeArea";
import ReservationDetailComponent from "components/@reservations/ReservationDetail/ReservationDetail/ReservationDetail";

const ReservationDetail = () => {
  useAndroidStatusBar({ iconsColor: "dark" });
  return (
    <IonPage>
      <IOSSafeArea />
      <IonContent className="reservation-detail-page-ion-content">
        <ReservationDetailComponent />
      </IonContent>
    </IonPage>
  );
};

export default ReservationDetail;
