import { PostHogConfig } from "posthog-js";

import config from "config/env";

import { isDev, isStaging } from "utils";

export const postogOptions: Partial<PostHogConfig> = {
  api_host: config.POSTHOG.HOST,
  autocapture: true,
  disable_session_recording: true,
  capture_pageview: true,
  capture_pageleave: true,
  loaded: (posthog) => {
    posthog.debug(false);
    if (isStaging() || isDev()) {
      console.info("Posthog loaded, write posthog.debug() to see events");
      window.posthog = posthog;
    }
  },
};
