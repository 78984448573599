/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./Overview.scss";

import { IonButton, IonIcon, useIonRouter } from "@ionic/react";
import { useCallback } from "react";
import { useWatch } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { generatePath } from "react-router";

import { captureMessage } from "utils/sentry.utils";

import { Routes } from "constants/routes.constants";
import { useBlockReasons, useCreateBlock } from "queries/blocks";
import { useCreateReservation } from "queries/reservations";
import { Flow } from "types/flow.types";
import { ReservationType } from "types/reservation.types";

import ErrorCard from "components/@common/ErrorCard";
import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";
import OverviewCard from "components/@complete-listing/Overview/OverviewCard";
import ReservationDelayDisclaimer from "components/@reservations/ReservationDetail/ReservationDelayDisclaimer";

import { errorMessage, formatDateMMMyyyyddHHmm } from "./utils";

const Overview = () => {
  const router = useIonRouter();

  const { createReservation, error, isLoading } = useCreateReservation();
  const {
    createblock,
    isLoading: isLoadingCreateBlock,
    error: blockError,
  } = useCreateBlock();

  const listingId = useWatch({ name: "listingId" });
  const reservationType = useWatch({ name: "reservationType" });
  const checkInDate = useWatch({ name: "checkInDate" });
  const checkOutDate = useWatch({ name: "checkOutDate" });
  const numberOfGuests = useWatch({ name: "numberOfGuests" });
  const reasonId = useWatch({ name: "reasonId" });
  const reservationId = useWatch({ name: "reservationId" });

  const { data: blockReasons } = useBlockReasons();
  const blockReason = blockReasons?.find((reason) => reason.id === reasonId);

  const isBlock = reservationType === ReservationType.Block;

  const backRoute =
    new URLSearchParams(window.location.search).get("backroute") ||
    Routes.Reservations;

  const handleBack = useCallback(() => {
    router.push(
      generatePath(
        Routes.CreateReservationDetails + `?backroute=${backRoute}`,
        {
          listingId,
          reservationId: reservationId ? reservationId : undefined,
        },
      ),
      "none",
    );
  }, [listingId, reservationId, router, backRoute]);

  const handleSuccess = () => {
    router.push(backRoute, "back", "pop", { unmount: true });
  };

  const handleSubmit = () => {
    const reason = blockReasons?.find((reason) => reason.id === reasonId);

    if (isBlock) {
      createblock(
        {
          listingId: listingId!,
          blockAttributes: {
            startDate: checkInDate,
            endDate: checkOutDate,
            reasonId,
            note: reason?.attributes.description || "",
          },
        },
        {
          onSuccess: () => handleSuccess(),
          onError: (error) => {
            captureMessage("Error creating block in create reservation", {
              level: "error",
              extra: { error },
            });
          },
        },
      );
    } else {
      createReservation(
        {
          listingId: listingId!,
          reservationAttributes: {
            checkInDate,
            checkOutDate,
            numberOfGuests,
            cleaningRequired: false,
          },
        },
        {
          onSuccess: () => handleSuccess(),
          onError: (error) => {
            captureMessage("Error creating reservation in create reservation", {
              level: "error",
              extra: { error },
            });
          },
        },
      );
    }
  };

  return (
    <StepperContainer
      flow={Flow.CreateReservation}
      route={Routes.CreateReservationOverview}
      backRoute={backRoute}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="secondary-button"
          shape="round"
          expand="full"
          onClick={handleSubmit}
          isLoading={isLoading || isLoadingCreateBlock}
          disabled={isLoading || isLoadingCreateBlock}
        >
          <FormattedMessage
            id={
              isBlock
                ? "create_reservation.complete.block"
                : "create_reservation.complete.reservation"
            }
          />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={handleBack}
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <div className="create-reservation-overview">
        <h2 className="overview-title">
          <FormattedMessage id="create_reservation_overview.title" />
        </h2>
        <div className="overview-cards">
          <OverviewCard title="create_reservation_overview.type.title">
            <div className="type-description">
              {!isBlock && (
                <IonIcon
                  className="ellipse-icon"
                  color="secondary"
                  src="assets/icons/icon-ellipse.svg"
                />
              )}
              <p className="margin-0 bold">
                <FormattedMessage
                  id={
                    isBlock
                      ? "create_reservation.type.block"
                      : "create_reservation.type.myself"
                  }
                />
              </p>
            </div>
          </OverviewCard>
          <OverviewCard
            title={
              isBlock
                ? "create_reservation_overview.block_start.title"
                : "create_reservation_overview.check_in.title"
            }
          >
            <p className="margin-0 bold">
              {formatDateMMMyyyyddHHmm("17:00", checkInDate)}
            </p>
          </OverviewCard>
          <OverviewCard
            title={
              isBlock
                ? "create_reservation_overview.block_end.title"
                : "create_reservation_overview.check_out.title"
            }
          >
            <p className="margin-0 bold">
              {formatDateMMMyyyyddHHmm("11:00", checkOutDate)}
            </p>
          </OverviewCard>
          {isBlock ? (
            <OverviewCard title="create_reservation_overview.reason.title">
              <p className="margin-0 bold">
                {blockReason?.attributes.displayName}
              </p>
            </OverviewCard>
          ) : (
            <OverviewCard title="create_reservation_overview.guests.title">
              <p className="margin-0 bold">{numberOfGuests}</p>
            </OverviewCard>
          )}
          {(error || blockError) && (
            <div style={{ marginBottom: 12 }}>
              <ErrorCard
                title={
                  <FormattedMessage id={errorMessage(error || blockError)} />
                }
              />
            </div>
          )}
          <ReservationDelayDisclaimer />
        </div>
      </div>
    </StepperContainer>
  );
};

export default Overview;
