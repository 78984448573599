import { useMutation } from "@tanstack/react-query";

import { userExists } from "utils/cognito.utils";

export const useUserExists = () => {
  const { mutate, isPending, isPaused, error } = useMutation({
    mutationFn: (email: string) => userExists(email),
  });
  return {
    userExists: mutate,
    isLoading: isPending && !isPaused,
    error,
  };
};
