import { Capacitor } from "@capacitor/core";
import {
  captureException as captureNativeException,
  captureMessage as captureNativeMessage,
  setExtra as setNativeExtra,
  setTag as setNativeTag,
  setUser as setNativeUser,
} from "@sentry/capacitor";
import {
  captureException as captureWebException,
  captureMessage as captureWebMessage,
  setExtra as setWebExtra,
  setTag as setWebTag,
  setUser as setWebUser,
} from "@sentry/react";

const isNativePlatform = Capacitor.isNativePlatform();

export const captureException = isNativePlatform
  ? captureNativeException
  : captureWebException;
export const captureMessage = isNativePlatform
  ? captureNativeMessage
  : captureWebMessage;
export const setTag = isNativePlatform ? setNativeTag : setWebTag;
export const setExtra = isNativePlatform ? setNativeExtra : setWebExtra;
export const setUser = isNativePlatform ? setNativeUser : setWebUser;
