import { queryOptions } from "@tanstack/react-query";

import { apiService } from "services";

export const ACCOUNT_PREFIX = "account";

export const accountUserQuery = queryOptions({
  queryKey: [ACCOUNT_PREFIX, "me"],
  queryFn: () => apiService.getUser(),
});
