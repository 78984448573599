import { useMemo } from "react";

import { DEFAULT_LOCALE } from "services/i18n";
import { useIntlStore } from "store";

export const useIntlDisplayNames = (
  localeOverwrite?: string,
): Intl.DisplayNames => {
  const { locale } = useIntlStore((state) => state);
  return useMemo(() => {
    try {
      return new Intl.DisplayNames(localeOverwrite ?? locale, {
        type: "region",
      });
    } catch (error) {
      return new Intl.DisplayNames(DEFAULT_LOCALE, {
        type: "region",
      });
    }
  }, [locale, localeOverwrite]);
};
