import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { ResetPasswordOtp as ResetPasswordOtpComponent } from "components/@authentication/ResetPasswordOtp";
import LogoContainer from "components/@common/LogoContainer";

const ResetPasswordOtp = () => {
  useAndroidStatusBar({ color: "#241261", iconsColor: "light" });
  return (
    <IonPage>
      <LogoContainer>
        <ResetPasswordOtpComponent />
      </LogoContainer>
    </IonPage>
  );
};

export default ResetPasswordOtp;
