import "./ListingSettings.scss";

import { FormattedMessage } from "react-intl";

import { useListing } from "queries";
import { OwnerType } from "types/listing.types";

import LoadingIndicator from "components/@common/LoadingIndicator";
import ListingBreadcrumbs from "components/@listing-detail/ListingDetails/ListingBreadcrumbs/ListingBreadcrumbs";
import Availability from "components/@listing-detail/ListingSettings/Availability";
import Billing from "components/@listing-detail/ListingSettings/Billing";
import Owners from "components/@listing-detail/ListingSettings/Owners";
// import Owners from "components/@listing-detail/ListingSettings/Owners";
import Payout from "components/@listing-detail/ListingSettings/Payout";
import Plan from "components/@listing-detail/ListingSettings/Plan";
import { getListingId, useOwnerType } from "components/@listing-detail/utils";

const ListingSettings = () => {
  const listingId = getListingId();
  const { data: listing, isLoading } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });
  const isReadOnly = useOwnerType(listing) === OwnerType.SUB;

  return (
    <div className="listing-settings">
      <ListingBreadcrumbs listing={listing} />
      <LoadingIndicator isOpen={isLoading} testId="ListingSettings" />

      <div className="listing-details">
        <h5>
          <FormattedMessage id="listing_detail.nav.settings" />
        </h5>

        {listing && (
          <>
            <Availability listing={listing} isReadOnly={isReadOnly} />
            <Owners listing={listing} isReadOnly={isReadOnly} />
            <Billing listing={listing} isReadOnly={isReadOnly} />
            <Payout listing={listing} isReadOnly={isReadOnly} />
            <Plan listing={listing} />
          </>
        )}
      </div>
    </div>
  );
};

export default ListingSettings;
