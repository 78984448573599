import { PushNotifications } from "@capacitor/push-notifications";
import { ReactNode, useEffect } from "react";

import { useUpdateUserToken } from "queries/notifications/useUpdateUserToken";
import { useCurrentUser } from "services/auth/useCurrentUser";

import useRegisterNotifications from "./useRegisterNotifications";

interface Props {
  children: ReactNode;
}

const NotificationProvider = ({ children }: Props) => {
  const { isLoggedIn } = useCurrentUser();
  const { updateUserToken } = useUpdateUserToken();
  const { registerNotifications } = useRegisterNotifications();

  const addListeners = async () => {
    await PushNotifications.addListener("registration", (token) => {
      updateUserToken({
        token: token.value,
      });
    });

    //TODO: handle error properly
    await PushNotifications.addListener("registrationError", (err) => {
      console.error("Registration error: ", err.error);
    });
  };

  useEffect(() => {
    addListeners();

    return () => {
      PushNotifications.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      registerNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return <>{children}</>;
};

export default NotificationProvider;
