import "./PropertyDetails.scss";

import { IonButton, useIonRouter } from "@ionic/react";
import { useFormContext, useWatch } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { generatePath } from "react-router";

import { propertyTypeKeys } from "constants/listing.constants";
import { Routes } from "constants/routes.constants";
import { useUpdateListing } from "queries";
import { Flow } from "types/flow.types";
import {
  CreateListingProviderValues,
  PropertyKind,
  PropertyType,
  RoomType,
} from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import LoadingButton from "components/@common/LoadingButton";
import StepperContainer from "components/@common/StepperContainer";
import FormCounter from "components/@form/FormCounter";
import FormRadioGroupCard from "components/@form/FormRadioGroupCard";

import {
  CreateListingPropertyDetailsFormFields,
  PropertyDetailsType,
} from "../types";

const PropertyDetails = () => {
  const router = useIonRouter();
  const intl = useIntl();
  const { updateListing, error, isLoading } = useUpdateListing();

  const { handleSubmit } = useFormContext<CreateListingProviderValues>();

  const listingId = useWatch({ name: "listingId" });

  const onSubmit = (data: CreateListingProviderValues) => {
    updateListing(
      {
        id: listingId,
        attributes: {
          propertyType: data.propertyType,
          propertyKind: data.propertyKind,
          bathrooms: data.bathrooms,
          bedrooms: data.bedrooms,
          toilets: data.toilets,
          maximumOccupancy: data.maximumOccupancy,
          sleepingArrangements: [...Array(data.bedrooms)].map(() => ({
            roomType: RoomType.Bedroom,
            bedTypes: [],
          })),
        },
      },
      {
        onSuccess: () =>
          router.push(
            generatePath(Routes.CreateListingUrl, {
              listingId,
            }),
          ),
      },
    );
  };

  const propertyKindOptions = Object.values(PropertyKind).map((value) => ({
    label: propertyTypeKeys[value],
    value,
  }));

  const propertyTypeOptions = Object.values(PropertyType).map((value) => ({
    label: propertyTypeKeys[value],
    value,
  }));

  return (
    <StepperContainer
      flow={Flow.CreateListing}
      route={Routes.CreateListingPropertyDetails}
      continueButton={
        <LoadingButton
          data-testid="continue-button"
          className="continue-button-stepper"
          shape="round"
          expand="full"
          type="submit"
          form="details-form"
          disabled={isLoading}
          isLoading={isLoading}
        >
          <FormattedMessage id="common.continue" />
        </LoadingButton>
      }
      backButton={
        <IonButton
          className="contrast-button"
          shape="round"
          onClick={() =>
            router.push(
              generatePath(Routes.CreateListingAddress, {
                listingId,
              }),
              "back",
            )
          }
        >
          <FormattedMessage id="back.button" />
        </IonButton>
      }
    >
      <h2 className="margin-24">
        <FormattedMessage id="create_listing_property_details.title" />
      </h2>

      <p className="margin-16 bold">
        <FormattedMessage id="create_listing_property_kind.description" />
      </p>

      <form id="details-form" onSubmit={handleSubmit(onSubmit)}>
        <FormRadioGroupCard
          name={CreateListingPropertyDetailsFormFields.PropertyKind}
          options={propertyKindOptions}
          type="PropertyKind"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "create_listing_property_kind.error.required",
              }),
            },
          }}
        />

        <p className="margin-16 bold">
          <FormattedMessage id="create_listing_property_details.description" />
        </p>

        <FormRadioGroupCard
          name={CreateListingPropertyDetailsFormFields.PropertyType}
          options={propertyTypeOptions}
          type="PropertyType"
          rules={{
            required: {
              value: true,
              message: intl.formatMessage({
                id: "create_listing_property_kind.error.required",
              }),
            },
          }}
        />

        <p className="margin-24 bold">
          <FormattedMessage id="create_listing_property_details.question_1" />
        </p>

        <FormCounter
          name={CreateListingPropertyDetailsFormFields.MaximumOccupancy}
          label={
            <FormattedMessage
              id={`create_listing_property_details.${PropertyDetailsType.Guests}`}
            />
          }
        />

        <p className="margin-24 bold">
          <FormattedMessage id="create_listing_property_details.question_2" />
        </p>

        <FormCounter
          name={CreateListingPropertyDetailsFormFields.Bedrooms}
          label={
            <FormattedMessage
              id={`create_listing_property_details.${PropertyDetailsType.Bedrooms}`}
            />
          }
        />

        <hr className="counter-divider margin-8" />

        <FormCounter
          name={CreateListingPropertyDetailsFormFields.Toilets}
          label={
            <FormattedMessage
              id={`create_listing_property_details.${PropertyDetailsType.Toilets}`}
            />
          }
        />

        <hr className="counter-divider margin-8" />

        <FormCounter
          name={CreateListingPropertyDetailsFormFields.Bathrooms}
          label={
            <FormattedMessage
              id={`create_listing_property_details.${PropertyDetailsType.Bathrooms}`}
            />
          }
        />
      </form>

      {error && (
        <div className="margin-top-16">
          <ErrorCard title={<FormattedMessage id={"common.error_unknown"} />} />
        </div>
      )}
    </StepperContainer>
  );
};

export default PropertyDetails;
