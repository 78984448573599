import { IonPage } from "@ionic/react";

import { useAndroidStatusBar } from "hooks/useAndroidStatusBar";

import { VerifyEmail as VerifyEmailComponent } from "components/@authentication/VerifyEmail";
import LogoContainer from "components/@common/LogoContainer";

const VerifyEmail = () => {
  useAndroidStatusBar({ color: "#241261", iconsColor: "light" });
  return (
    <IonPage>
      <LogoContainer>
        <VerifyEmailComponent />
      </LogoContainer>
    </IonPage>
  );
};

export default VerifyEmail;
