import "./ListingCalendar.scss";

import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { useListing } from "queries";

import CalendarContent from "components/@calendar/CalendarContent/CalendarContent";
import LoadingIndicator from "components/@common/LoadingIndicator";
import ListingBreadcrumbs from "components/@listing-detail/ListingDetails/ListingBreadcrumbs/ListingBreadcrumbs";
import { getListingId } from "components/@listing-detail/utils";

const ListingCalendar = () => {
  const listingId = getListingId();
  const { data: listing, isLoading } = useListing(listingId ?? "", {
    enabled: !!listingId,
  });

  useEffect(() => {
    document.body.classList.add("calendar-page");
    return () => document.body.classList.remove("calendar-page");
  }, []);

  return (
    <div className="calendar-container">
      <div className="calendar-header">
        <ListingBreadcrumbs listing={listing} />
        <LoadingIndicator isOpen={isLoading} testId="ListingCalendar" />

        <h5 style={{ marginTop: 16 }}>
          <FormattedMessage id="listing_detail.nav.calendar" />
        </h5>
      </div>

      <div
        style={{
          marginTop: 18,
          display: "flex",
          flex: 1,
          boxShadow: "inset 0 0 0 1px var(--ion-text-color-step-400)",
        }}
      >
        <CalendarContent selectedListingId={listingId} />
      </div>
    </div>
  );
};

export default ListingCalendar;
