import "./ItemList.scss";

import { FormattedMessage } from "react-intl";

import LinkUnstyled from "components/@common/LinkUnstyled";

interface Props {
  children: React.ReactNode;
  title?: I18nKey;
  actionKey?: I18nKey;
  onClick?: () => void;
}

const ItemList = ({ children, title, actionKey, onClick }: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
      {title && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h6>
            <FormattedMessage id={title} />
          </h6>
          {onClick && actionKey && (
            <LinkUnstyled onClick={onClick}>
              <FormattedMessage id={actionKey} />
            </LinkUnstyled>
          )}
        </div>
      )}

      <div className="item-list">{children}</div>
    </div>
  );
};

export default ItemList;
