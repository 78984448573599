export type UploadResponse = {
  type: string;
  url: string;
  path: string;
  fields: {
    key: string;
    AWSAccessKeyId: string;
    "x-amz-security-token": string;
    policy: string;
    signature: string;
  };
};

export enum Context {
  Onboarding = "onboarding",
  Listing = "listing",
  Ticket = "ticket",
}

export enum ContentType {
  ImageJpeg = "image/jpeg",
  ImageJpg = "image/jpg",
  ImagePng = "image/png",
  ImageWebp = "image/webp",
  ImageHeif = "image/heif",
  ImageHeic = "image/heic",
  ApplicationPdf = "application/pdf",
  ApplicationXPdf = "application/x-pdf",
}

type PresignedUrl = {
  url: string;
  fields: {
    "x-amz-security-token": string;
    key: string;
    AWSAccessKeyId: string;
    policy: string;
    signature: string;
  };
};

export type UploadFileParams = PresignedUrl & {
  file: File;
};
