import { useQuery } from "@tanstack/react-query";

import { getOtasQuery } from "./queries";

export const useOtas = (options?: Partial<ReturnType<typeof getOtasQuery>>) => {
  const { data, isFetching, error, isError } = useQuery({
    ...getOtasQuery(),
    ...options,
  });
  return {
    data: data?.data.data,
    error,
    isLoading: isFetching,
    isError,
  };
};
