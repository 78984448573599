import { captureMessage } from "@sentry/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

import { useUpdateListing } from "queries";
import { ListingResponse, PayoutValues } from "types/listing.types";

import ErrorCard from "components/@common/ErrorCard";
import ItemList from "components/@common/ItemList/ItemList";
import ItemListRow from "components/@common/ItemList/ItemListRow";
import LoadingButton from "components/@common/LoadingButton";
import { FormIonInput } from "components/@form/FormIonInput";
import { getListingId } from "components/@listing-detail/utils";

type Props = {
  listing: ListingResponse["data"];
  isReadOnly: boolean;
};

const Payout = ({ listing, isReadOnly }: Props) => {
  const intl = useIntl();
  const [isEditing, setIsEditing] = useState(false);
  const { updateListing, error, isLoading } = useUpdateListing();

  const methods = useForm<PayoutValues>({
    defaultValues: listing.attributes.payout || {
      bankAccountNumber: "",
      bankAccountOwnerName: "",
      bankName: "",
      bankCountry: "",
      bankCode: "",
    },

    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const handleAction = () => {
    if (isEditing) {
      methods.reset(listing.attributes.payout);
    }

    setIsEditing((isEditing) => !isEditing);
  };

  const onSubmit = (payout: PayoutValues) => {
    const listingId = getListingId();

    updateListing(
      { id: listingId, attributes: { payout } },
      {
        onSuccess: () => {
          methods.reset(payout);
          setIsEditing(false);
        },
        onError: (error) => {
          captureMessage("Error updating payout values in listing detail", {
            level: "error",
            extra: { error },
          });
        },
      },
    );
  };

  const values = methods.getValues();
  const isEmpty = Object.values(values).every((value) => !value);

  return (
    <ItemList
      title="listing.settings.payout"
      actionKey={isEditing ? "button.cancel" : "common.edit"}
      onClick={isReadOnly ? undefined : handleAction}
    >
      {isEditing ? (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            style={{
              marginTop: 12,
              display: "flex",
              gap: 24,
              flexDirection: "column",
            }}
          >
            {error && (
              <ErrorCard
                title={<FormattedMessage id="common.error_unknown" />}
              />
            )}

            <FormIonInput
              name="bankAccountOwnerName"
              data-testid="payout-bankAccountOwnerName"
              aria-label="bankAccountOwnerName"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.bank_account_owner_name",
              })}
              labelPlacement="floating"
              required
            />
            <FormIonInput
              name="bankAccountNumber"
              data-testid="payout-bankAccountNumber"
              aria-label="bankAccountNumber"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.bank_account_number",
              })}
              hint={intl.formatMessage({
                id: "listing.settings.billing.bank_account_number.hint",
              })}
              labelPlacement="floating"
              required
            />
            <FormIonInput
              name="bankCode"
              data-testid="payout-bankCode"
              aria-label="Billing bankCode"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.bank_code",
              })}
              labelPlacement="floating"
            />
            <FormIonInput
              name="bankName"
              data-testid="payout-bankName"
              aria-label="VAT bankName"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.bank_name",
              })}
              labelPlacement="floating"
            />
            <FormIonInput
              name="bankCountry"
              data-testid="payout-bankCountry"
              aria-label="Billing bankCountry"
              fill="solid"
              label={intl.formatMessage({
                id: "listing.settings.billing.bank_country",
              })}
              labelPlacement="floating"
            />

            <LoadingButton
              isLoading={isLoading}
              shape="round"
              style={{ height: 48, width: "fit-content" }}
              type="submit"
            >
              <FormattedMessage id="common.save" />
            </LoadingButton>
          </form>
        </FormProvider>
      ) : (
        <ItemListRow>
          {isEmpty ? (
            <FormattedMessage id="complete_listing_overview.not_set" />
          ) : (
            <div>
              <p className="margin-0" style={{ fontWeight: 600 }}>
                {[
                  values.bankAccountOwnerName,
                  `IBAN **** ${values.bankAccountNumber.replaceAll(" ", "").slice(-4)}`,
                ]
                  .filter(Boolean)
                  .join(", ")}
              </p>
              <p className="margin-0">
                {[values.bankName, values.bankCode, values.bankCountry]
                  .filter(Boolean)
                  .join(", ")}
              </p>
            </div>
          )}
        </ItemListRow>
      )}
    </ItemList>
  );
};

export default Payout;
