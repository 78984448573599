import { useQuery } from "@tanstack/react-query";

import { useIntlStore } from "store";

import { getBlockReasonsQuery } from "./queries";

export const useBlockReasons = (
  options?: Partial<ReturnType<typeof getBlockReasonsQuery>>,
) => {
  const locale = useIntlStore((state) => state.locale);
  const { data, isFetching, error, isError } = useQuery({
    ...getBlockReasonsQuery(locale),
    ...options,
  });
  return {
    data: data?.data.data,
    error,
    isLoading: isFetching,
    isError,
  };
};
