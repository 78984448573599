import { FormattedMessage, useIntl } from "react-intl";

import { Routes } from "constants/routes.constants";
import { HouseRule } from "types/listing.types";

import OverviewCard from "./OverviewCard";

interface Props {
  houseRules: Array<HouseRule>;
}

const formatHouseRules = (rules: Array<HouseRule>) => {
  const quietTimeStart = rules.find(
    (r) => r.name === "quite_time_start",
  )?.value;
  const quietTimeEnd = rules.find((r) => r.name === "quite_time_end")?.value;

  const formattedRules = rules.reduce<Array<JSX.Element>>((acc, rule) => {
    switch (rule.name) {
      case "quite_time_start":
      case "quite_time_end":
        if (
          quietTimeStart &&
          quietTimeEnd &&
          !acc.some((r) => r.key === "quite_time")
        ) {
          acc.push(
            <p className="margin-0 extra-small" key="quite_time">
              <FormattedMessage
                id="house_rule.quiet_time"
                values={{ start: quietTimeStart, end: quietTimeEnd }}
              />
            </p>,
          );
        }
        break;

      case "additional_rules":
        if (!rule.value) break;
        acc.push(
          <p className="margin-0 extra-small" key={rule.name}>
            <FormattedMessage
              id={`house_rule.${rule.name}`}
              values={{ additionalRules: rule.value }}
            />
          </p>,
        );
        break;

      default:
        if (!rule.value) break;
        acc.push(
          <p className="margin-0 extra-small" key={rule.name}>
            <FormattedMessage
              id={`house_rule.${rule.name}` as I18nKey}
              values={{ value: rule.value }}
            />
          </p>,
        );
        break;
    }
    return acc;
  }, []);

  return formattedRules;
};

const HouseRulesCard = ({ houseRules }: Props) => {
  const intl = useIntl();

  return (
    <OverviewCard
      title="complete_listing_overview.house_rules.title"
      route={Routes.CompleteListingHouseRules}
    >
      {houseRules.length === 0 ? (
        <p className="margin-0 extra-small">
          <em>
            {intl.formatMessage({ id: "complete_listing_overview.not_set" })}
          </em>
        </p>
      ) : (
        formatHouseRules(houseRules)
      )}
    </OverviewCard>
  );
};

export default HouseRulesCard;
